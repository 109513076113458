<template>
    <div class="content">
        <div class="box">
            <banner :types="2"></banner>
            <heads></heads>
            <div class="title">Organization</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    Organization
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Milestone
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'Organization'"></leftMenu>
                <div class="right_box">
                    <div class="right_box1">
                        <div class="box1_item" v-for="(item, key) in datas"
                            :style="key >= datas.length - 1 ? 'border:none' : '',!item.photo||item.photo.length==0?{height:'120px'}:null">
                            <div class="item">
                                <img class="img1 imgnone" src="@/assets/organization/jiangbei.png" alt="">
                                <img class="img2 imgnone" src="@/assets/organization/jindu.png" alt="">
                            </div>
                            <div style="padding-bottom: 20px;border-bottom: 1px solid #dedede;flex: 1;">
                                <div class="time" :style="key == 0 ? 'margin-top:0' : ''">{{ item.create_time }}</div>
                                <el-popover placement="top" :title="item.title" width="500" trigger="hover"
                                    :content="item.kkeyword">
                                    <a slot="reference" :href="item.httpurl ? item.httpurl : null" target="_blank"
                                        rel="noopener noreferrer">
                                        <div class="title content_title">
                                            {{ item.title}}
                                        </div>
                                    </a>
                                </el-popover>
                                <div class="lunbo" v-if="item.photo&&item.photo.length!=0">
                                    <swiper :datas="item.photo" style="flex: 1;" :active="item.activeKey"></swiper>
                                    <div class="lunbo" style="margin:0 10px;" v-if="item.photo.length > 3">
                                        <div class="icon" @click="toNext1(key)">
                                            <i class="el-icon-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="phonelunbo" style="display: none;">
                                    <div class="img_box">
                                        <img v-for="(item1, key1) in item.photo" :key="key1"
                                            @click="dialogVisible = true; dialogImageUrl = item1" :src="item1" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
        <el-dialog :visible.sync="dialogVisible" width="100%">
            <div style="display: flex;align-items: center;justify-content: center;" class="phone_tanceng">
                <img width="100%" class="imgnone" :src="dialogImageUrl" alt="">
            </div>
        </el-dialog>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import leftMenu from '@/components/leftMenu.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request';
import banner from '@/components/banner.vue';
import swiper from '@/components/swiper.vue'

export default {
    components: {
        heads,
        foots,
        leftMenu,
        banner,
        swiper
    },
    data() {
        return {
            leftMenuData: [{
                name: 'Constitution',
                path: '/Constitution'
            }, {
                name: 'Structure',
                path: '/Introduction'
            },
            {
                name: 'AERSS Leadership',
                path: '/Leadership'
            },
            {
                name: 'Council',
                path: '/Council'
            },
            {
                name: 'Scientific Steering Committee',
                path: '/Scientific'
            },

            {
                name: 'Working Groups',
                path: '/WorkingGroups'
            },
            {
                name: 'ECPC',
                path: '/Ecpc'
            },
            {
                name: 'Secretariat',
                path: '/Secretariat'
            },
            {
                name: 'Milestone',
                path: '/Milestone'
            },
            {
                name: 'Contact Us',
                path: '/Contact'
            }
            ],
            datas: [],
            dialogImageUrl: '',
            dialogVisible: false
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/Introduction'
            })
        },
        toShangyiye1(index) {
            if (this.datas[index].activeKey == 0) {
                return;
            }
            this.datas[index].activeKey--
            this.$forceUpdate()
        },
        toNext1(index) {
            const totalPages = Math.ceil(this.datas[index].photo.length / 3);
            if (this.datas[index].activeKey === totalPages - 1) {
                this.datas[index].activeKey = 0;
                this.$forceUpdate()
                return;
            }
            this.datas[index].activeKey++
            this.$forceUpdate()
        },
        getData() {
            request({
                url: 'apilicheng',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.datas = res.data
                this.datas.forEach(item => {
                    item.activeKey = 0
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content_title {
    // 超出两行省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 1025px) {
    .phone_tanceng {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100% !important;
        }
    }

    .phone_itemhead {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .body_box {
        flex-direction: column;
        margin: 0 !important;

        .left_tiao,
        .item {
            display: none !important;
        }

        .right_box1 {
            margin: 0 15px !important;

            .box1_item {
                padding: 0 !important;
                padding-top: 10px !important;

                .time {
                    font-size: 16px !important;
                }

                .title {
                    font-size: 14px !important;
                    margin-top: 10px !important;
                }

                .lunbo {
                    display: none !important;
                }

                .phonelunbo {
                    display: flex !important;
                    margin: 0 !important;

                    .img_box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 10px 0 !important;

                        img {
                            width: 30% !important;
                            margin-right: 1%;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

.content {
    width: 100%;
    background: #F8F8F8;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1300px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                margin-left: 110px;
                display: flex;

                .right_box1 {
                    margin-top: 0px;
                    width: 100%;

                    .box1_item {
                        padding: 0px 24px;
                        display: flex;
                        overflow: hidden;
                        .item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-right: 50px;
                            .img1 {
                                width: 33px;
                                height: 33px;
                                cursor: pointer;

                                &:hover {
                                    animation: shake .5s ease;
                                }
                            }

                            .img2 {
                                width: 23px;
                                margin: 15px 0;
                                max-height: 240px;
                            }
                        }


                        .time {
                            font-size: 18px;
                            font-weight: 400;
                            color: #222222;
                            margin-top: 20px;

                        }

                        .title {
                            font-size: 24px;
                            font-weight: 400;
                            color: #222222;
                            margin-top: 15px;

                            &:hover {
                                color: #005083;
                            }
                        }

                        .lunbo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 20px;

                            .icon {
                                width: 31px;
                                height: 31px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                background: #fff;
                                color: #005083;
                                font-size: 12px;

                                &:hover {
                                    background: #005083;
                                    color: #fff;
                                }
                            }

                            .img_box {
                                flex: 1;
                                margin: 0 20px;
                                display: flex;

                                div {
                                    flex-shrink: 0;
                                    width: 31%;
                                    border-radius: 10px;
                                    margin-right: 20px;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>