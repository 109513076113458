<template>
    <div>
        <div class="left_box" v-if="datas && datas.length != 0">
            <div class="left_head"> {{ datas[0].futitle ? datas[0].futitle : title }}</div>
            <div class="left_item_box">
                <div v-for="(item, key) in datas" @click="toPath(item)">
                    <div class="item" :class="key == activeIndex ? 'active' : ''" v-if="!item.isChildren">
                        <div style="margin-left: 20px;">
                            {{ item.name }}
                        </div>
                        <img src="@/assets/news/to_right.png" alt="">
                    </div>
                    <div class="item" style="flex-direction: column;" :class="key == activeIndex ? 'active' : ''"
                        v-else>
                        <div class="zi_class" @click="tozhankai">
                            <div style="margin-left: 20px;">
                                {{ item.name }}
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div v-if="zhankai">
                            <div class="zi_class" style="height: 40px;color: #000;font-size: 14px;"
                                v-for="items in item.children" @click="toPaths(items.id)">
                                <div style="margin-left: 20px;display: inline-block;"
                                    :style="items.active ? 'color:#005083;border-bottom:2px solid #005083' : ''">
                                    {{ items.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datas1: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            activeIndex: 0,
            zhankai: true,
            datas: []
        }
    },
    methods: {
        tozhankai() {
            this.zhankai = !this.zhankai
        },
        toPath(item) {
            if (item.typestatus == 1) {
                this.$router.push({
                    name: 'newList',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 2) {
                this.$router.push({
                    name: 'newMembermList',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 3) {
                this.$router.push({
                    name: 'type3Detail',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 4) {
                this.$router.push({
                    name: 'based_observation',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 5) {
                this.$router.push({
                    name: 'Observation_network',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 6) {
                this.$router.push({
                    name: 'Satellites',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (item.typestatus == 7) {
                this.$router.push({
                    name: 'Downloads',
                    query: {
                        type: item.id,
                        futitle: item.futitle,
                        title: item.name,
                    }
                });
                return
            }
            if (!item.url) {
                return;
            }
            // 如果是当前页面 不跳转
            if (this.$route.path === item.url) {
                if (item.md) {
                    this.$emit('toPath', item.md);
                    return;
                }
                return;
            }

            this.$router.push(item.url);
        },
        toPaths(id) {
            if (!id) {
                return;
            }
            // 给当前点击的id加上active
            this.datas.forEach(item => {
                if (item.isChildren) {
                    item.children.forEach(items => {
                        items.active = false
                        if (items.id == id) {
                            items.active = true
                        }
                    })
                }
            })
            this.$forceUpdate()
            // 如果是当前页面 不跳转
            if (this.$route.query.id == id) {
                return;
            }
            this.$router.push({ path: this.$route.path, query: { id: id } });
        }
    },

    mounted() {
        let datas = []
        // 获取本地缓存的MenuItem
        let MenuItem = JSON.parse(localStorage.getItem('MenuItem'))
        console.log(MenuItem)
        MenuItem.forEach(item => {
            if (this.$route.query.path) {
                if (item.url == this.$route.query.path) {
                    if (item.children && item.children.length > 0) {
                        item.children.forEach(items => {
                            datas.push({
                                ...items,
                                path: items.url,
                                futitle: item.name || ''
                            })
                        })
                    } else {
                        datas.push({
                            ...item,
                            path: item.url,
                            futitle: item.name || ''
                        })
                    }
                } else {
                    console.log(item)

                    if (item.children && item.children.length > 0) {
                        item.children.forEach(items => {
                            if (items.url == this.$route.query.path) {
                                item.children.forEach(items => {
                                    datas.push({
                                        ...items,
                                        path: items.url,
                                        futitle: item.name || ''
                                    })
                                })
                            }
                        })
                    }
                }
            } else {
                if (item.url == this.$route.path || item.name == this.$route.query.futitle) {
                    if (item.children && item.children.length > 0) {
                        item.children.forEach(items => {
                            datas.push({
                                ...items,
                                path: items.url,
                                futitle: item.name || ''
                            })
                        })
                    } else {
                        datas.push({
                            ...item,
                            path: item.url,
                            futitle: item.name || ''
                        })
                    }
                } else {
                    if (item.children && item.children.length > 0) {
                        item.children.forEach(items => {
                            if (items.url == this.$route.path) {
                                item.children.forEach(items => {
                                    datas.push({
                                        ...items,
                                        path: items.url,
                                        futitle: item.name || ''
                                    })
                                })
                            }
                        })
                    }
                }
            }
        })
        this.datas = datas
        if (this.datas1.length > 0) {
            this.datas = this.datas1
        }
        // 获取路由找到对应的index
        if (this.$route.query.path) {
            let index = this.datas.findIndex(item => item.path === this.$route.query.path)
            this.activeIndex = index
        } else {
            let index = this.datas.findIndex(item => item.path === this.$route.path)
            this.activeIndex = index
        }
        if (this.activeIndex == -1) {
            this.datas.forEach((item, index) => {
                console.log(item)
                if (item.name == this.$route.query.title) {
                    this.activeIndex = index
                }
            })
        }
        this.toPaths(this.$route.query.id)
    },
}
</script>

<style lang="scss" scoped>
.icon_box {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    margin-bottom: 20px;

    img {
        margin-right: 10px;
        cursor: pointer;
    }

    .home {
        width: 24px;
    }

    .right {
        height: 15px;
    }
}

.content {
    width: 100%;

    .left_box {
        box-shadow: 0px 0px 10px #E5E5E5;
        border-radius: 20px;
        overflow: hidden;
        flex-shrink: 0 !important;

        .left_head {
            width: 287px;
            height: 115px;
            background: #005083;
            border-radius: 20px 20px 0px 0px;
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .left_item_box {
            width: 287px;

            .item {
                width: 100%;
                min-height: 60px;
                display: flex;
                background-size: 100% 100%;
                align-items: center;
                justify-content: space-between;
                color: #333;
                cursor: pointer;
                border-top: 1px solid #eee;

                img {
                    width: 16px;
                    margin-right: 20px;
                }

                .zi_class {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    height: 60px;
                }
            }

            .active {
                background-image: url("@/assets/news/left_bg.png");
                color: #005083;
            }
        }

    }
}

@media screen and (max-width: 1025px) {
    .content {
        .left_box {
            display: none !important;
        }
    }
}
</style>