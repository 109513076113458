<template>
    <div class="content">
        <div class="box">
            <banner :types="7"></banner>
            <heads></heads>
            <div class="title">Resources</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    Resources
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Videos
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'Resources'"></leftMenu>
                <div class="right_box">
                    <div class="shaixuan">
                        <div>
                            <el-select @change="getDatas" v-model="downvalue" placeholder="Download" clearable>
                                <el-option v-for="item in DownloadArr" :key="item.id" :label="item.value" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <el-select @change="getDatas" v-model="filevalue" placeholder="Filter by time" clearable>
                                <el-option v-for="item in FilterArr" :key="item.id" :label="item.value" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="sousuo">
                            <el-input v-model="searchvalue" placeholder="Search for resources..." clearable></el-input>
                            <img src="@/assets/Resources/search.png" alt="" @click="getDatas">
                        </div>
                    </div>
                    <div class="phone_shaixuan shaixuan" style="display: none;">
                        <div class="sousuo">
                            <el-input v-model="searchvalue" placeholder="Search for resources..." clearable></el-input>
                            <img src="@/assets/Resources/search.png" alt="" @click="getDatas">
                        </div>
                        <div>
                            <el-select @change="getDatas" v-model="downvalue" placeholder="Download" clearable>
                                <el-option v-for="item in DownloadArr" :key="item.id" :label="item.value" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <el-select @change="getDatas" v-model="filevalue" placeholder="Filter by time" clearable>
                                <el-option v-for="item in FilterArr" :key="item.id" :label="item.value" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>

                    </div>
                    <div class="item_box">
                        <div class="item" v-for="item in datas">
                            <a :href="item.videourl" target="_blank">
                                <img :src="item.picurl" alt="">
                            </a>
                            <div>{{ item.title }}</div>
                        </div>
                    </div>
                    <pagination style="margin: auto;" :pageData="pageData" @currentChange="currentChange"></pagination>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import leftMenu from '@/components/leftMenu.vue'
import foots from '@/components/foots.vue'
import pagination from '@/components/pagination.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        pagination,
        banner
    },
    data() {
        return {
            leftMenuData: [{
                name: 'Publications',
                path: '/Publications'
            },
            {
                name: 'Presentations',
            },
            {
                name: 'Videos',
                path: '/Videos'
            },
            {
                name: 'Data',
                path: '/Data',
            },
            {
                name: 'Others',
            },
            ],
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            datas: null,
            DownloadArr: [],
            FilterArr: [],
            downvalue: null,
            filevalue: null,
            searchvalue: null
        }
    },
    mounted() {
        this.getDatas()
    },
    methods: {
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/Publications'
            })
        },
        getDatas() {
            request({
                url: 'tuan_detail_one',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    type: 3,
                    page: this.pageData.current_page,
                    keyword: this.searchvalue,
                    download: this.downvalue,
                    order: this.filevalue
                },
            }).then(res => {
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
                this.datas = res.data.data
                this.DownloadArr = res.other.download
                this.FilterArr = res.other.paixu
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
    },
}
</script> 

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .box1 {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 30px;

        .body_box {
            margin-top: 10px !important;
        }

        .icon_box {
            display: none !important;
        }

        .body_box {
            margin: 0 20px !important;
            display: flex;

            .right_box {
                flex: 1;
                margin-left: 0;

                .shaixuan {
                    display: none !important;
                }

                .phone_shaixuan>div {
                    margin: 0 !important;
                    width: 45% !important;
                }

                .phone_shaixuan {
                    margin-bottom: 10px !important;
                    display: flex !important;
                    justify-content: space-between !important;
                    flex-wrap: wrap !important;
                    align-items: flex-end !important;

                    .sousuo {
                        margin: 10px 0 !important;
                        width: 100% !important;
                    }
                }

                .item_box {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between !important;
                    margin-bottom: 10px !important;
                    .item {
                        width: 48% !important;
                        margin: 0 !important;
                        padding: 0px !important;
                        background-color: #FFFFFF;
                        margin-bottom: 10px !important;
                        border-radius: 12px;

                        img {
                            width: 100% !important;
                            object-fit: cover !important;
                            border-bottom-left-radius: 0px !important;
                            border-bottom-right-radius: 0px !important;
                        }

                        div {
                            font-size: 16px !important;
                            font-weight: 400 !important;
                            color: #333333;
                            margin-top: 0px !important;
                            padding: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

.content {
    width: 100%;
    background: #F8F8F8;

    .shaixuan {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 50px;

        ::v-deep .el-input__inner {
            border-radius: 20px;
            background: #FFFFFF;
            border: none;
            color: #005083;
            font-size: 16px;
        }

        .sousuo {
            display: flex;
            align-items: center;
            background-color: #FFFFFF;
            padding-right: 10px;
            border-radius: 20px;
            border: 1px solid #005083;

            img {
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    }

    .shaixuan>div {
        margin-left: 30px;
    }

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                margin-left: 110px;

                .item_box {
                    display: flex;
                    flex-wrap: wrap;

                    // 每行第三个item右边距为0
                    .item:nth-child(3n) {
                        margin-right: 0;
                    }

                    .item {
                        width: 29%;
                        padding: 10px;
                        padding-bottom: 25px;
                        background-color: #FFFFFF;
                        margin-bottom: 40px;
                        border-radius: 12px;
                        margin-right: 2.5%;

                        img {
                            width: 100%;
                            height: 160px;
                            overflow: hidden;
                            border-radius: 12px;
                        }

                        div {
                            font-size: 20px;
                            font-weight: bold;
                            color: #333333;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
  