<template>
    <div class="content">
        <div class="box">
            <banner :types="3"></banner>
            <heads @toPath="toPaths"></heads>
            <div class="title">Membership</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Membership
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head"> Membership</div>
                    <div class="left_item_box">
                        <div v-for="(item, key) in leftMenuData" @click="scrollToAnchor(item.md)">
                            <div class="item" :class="key == activeIndex ? 'active' : ''" v-if="!item.isChildren">
                                <div style="margin-left: 20px;">
                                    {{ item.name }}
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                            <div class="item" style="flex-direction: column;"
                                :class="key == activeIndex ? 'active' : ''" v-else>
                                <div class="zi_class">
                                    <div style="margin-left: 20px;">
                                        {{ item.name }}
                                    </div>
                                    <img src="@/assets/news/to_right.png" alt="">
                                </div>
                                <div class="zi_class" style="height: 40px;color: #000;font-size: 14px;"
                                    v-for="items in item.children">
                                    <div style="margin-left: 20px;display: inline-block;"
                                        :style="items.active ? 'color:#005083;border-bottom:2px solid #005083' : ''">
                                        {{ items.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box" v-if="datas">
                    <div class="title" id="Membership">{{ datas.membership.title }}</div>
                    <div class="text">
                        {{ datas.membership.remark }}
                    </div>
                    <div style="display: flex;align-items: center;" class="anniuzu">
                        <div class="btn_box" @click="toZhuce()">
                            {{ datas.membership.leftbutton }}
                        </div>
                        <div class="btn_box" style="margin-left: 20px;" @click="ziShow = !ziShow">
                            {{ datas.membership.rightbutton }}
                        </div>
                    </div>
                    <div class="shaixuan" v-if="ziShow">
                        <div class="dian_box">
                            <div class="down_box" style="padding: 0;">
                                <input type="text" class="inp" v-model="keyword" placeholder="Name/Affiliation">
                                <div class="icon_boxa" @click="getSherch">
                                    <img src="@/assets/memberm/search.png" alt="">
                                </div>
                            </div>
                            <div class="zimu_box">
                                <span v-for="item in zimuArr" @click="clickZimu(item)"
                                    :style="item == zimuActive ? 'color:#005083;font-size:25px' : ''">{{ item }}</span>
                            </div>
                        </div>
                        <div class="item_box" style="margin-top: 30px;">
                            <div v-for="item in ziList"
                                style="display: flex;align-items: center;padding: 10px;font-size: 18px;font-weight: 400;color: #333333;">
                                <div style="flex: 1;">{{ item.name }}</div>
                                <div style="flex: 1;">{{ item.institution }}</div>
                                <div style="flex: 1;">{{ item.Region }}</div>
                            </div>
                        </div>
                        <pagination style="margin-top: 20px; transform: scale(.9);" :pageData="pageData"
                            @currentChange="currentChange">
                        </pagination>
                    </div>
                    <div class="inst_item_box">
                        <div class="inst_title">Distribution of member research institutes</div>
                        <div class="inst_content">
                            <div class="right">
                                <div v-for="(item, key) in distribution" style="display: flex;">
                                    <div class="left">
                                        <div>
                                            {{ item.value }}
                                        </div>
                                    </div>
                                    <div class="right_item_box">
                                        <div class="right_item" v-for="items in item.list">{{ items.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="type_title" id="MemberType">Member Type</div>
                    <div class="type_box">
                        <div class="center_box">
                            <div class="center_box_item fangda" v-if="key < 3" v-for="(item, key) in datas.membertype">
                                <div class="img" :style="item.keyword > 999 ? 'font-size:40px' : ''">{{ item.keyword }}
                                </div>
                                <div class="text">{{ item.title }}</div>
                            </div>
                        </div>
                        <div class="text_box">
                            <div v-for="(item, key) in datas.membertype">
                                <div class="texts">{{ item.title }}</div>
                                <div>
                                    {{ item.remark }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="type_title" id="Benefits">Benefits</div>
                    <div style="margin-top: 28px;display: flex;align-items: center;justify-content: space-between;">
                        <div class="downs" @click="BenePageIndex == 0 ? BenePageIndex = 0 : BenePageIndex--">
                            <i class="el-icon-arrow-left"></i>
                        </div>
                        <div style="flex: 1;margin: 0 20px;display: flex;align-items: center;justify-content: space-between;"
                            v-for="(item, key) in datas.benefits" v-if="key == BenePageIndex">
                            <div style="border: 1px solid #DDDDDD;border-radius: 20px;background: #FFFFFF;min-height: 427px;width: 32%;display: flex;flex-direction: column;align-items: center;"
                                v-for="items in item" class="crad_boxs">
                                <div
                                    style="text-align: center;width: 80%;font-size: 18px;font-weight: bold;color: #005083;margin-top: 36px;">
                                    {{
                items.title }}</div>
                                <div
                                    style="width: 80%;font-size: 16px;font-weight: 400;color: #333333;margin-top: 37px;">
                                    {{
                items.remark }}</div>
                            </div>
                        </div>
                        <div class="downs"
                            @click="BenePageIndex < datas.benefits.length - 1 ? BenePageIndex++ : BenePageIndex = datas.benefits.length - 1">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <!-- <div class="Moments_title type_title" id="Achievements">Moments</div>
                    <div class="Moments_box">
                        <div class="boxs fangda" v-for="(item, key) in datas.moments">
                            <img class="imgnone" style="object-fit: cover;" :src="item.photo" alt="">
                            <div>{{ item.title }}</div>
                        </div>
                    </div> -->
                    <div class="active_box" id="Election">
                        <div class="titles">Active Members</div>
                        <div class="items_box">
                            <div style="display: flex;align-items: center;width: 100%;"
                                v-for="(item, key) in datas.activemembers" v-if="key == activemembersIndex">
                                <div class="item" v-for="items in item" @click="toDetailsinfo(items)">
                                    <img class="huangguan" src="@/assets/membership/huangguan.png" alt="">
                                    <img class="touxiang" style="object-fit: cover;" :src="items.photo" alt="">
                                    <div class="neirong">
                                        <div class="name">
                                            {{ items.title }}
                                        </div>
                                        <el-popover placement="top-start" title="" width="200" trigger="hover"
                                            :content="items.remark">
                                            <div class="jianjie" slot="reference">
                                                {{ items.remark }}
                                            </div>
                                        </el-popover>

                                    </div>
                                </div>
                            </div>
                            <div class="foot_content">
                                <div style="cursor: pointer;" class="downs"
                                    @click="activemembersIndex == 0 ? activemembersIndex = 0 : activemembersIndex--">
                                    <i class="el-icon-arrow-left"></i>
                                </div>
                                <div style="cursor: pointer;" class="downs"
                                    @click="activemembersIndex != datas.activemembers.length - 1 ? activemembersIndex++ : activemembersIndex = datas.activemembers.length - 1">
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="phone_box1" style="display: none;" v-if="datas">
            <div class="phone_title" id="Membership">{{ datas.membership.title }}</div>
            <div class="phone_text">
                {{ datas.membership.remark }}
            </div>
            <div class="phone_btnbox">
                <div @click="toZhuce()">
                    {{ datas.membership.leftbutton }}
                </div>
                <div style="margin-left: 20px;" @click="ziShow = !ziShow">
                    {{ datas.membership.rightbutton }}
                </div>
            </div>
            <div class="phone_shaixuan" v-if="ziShow">
                <div class="phone_dian_box">
                    <div class="phone_down_box" style="padding: 0;">
                        <input type="text" class="inp" v-model="keyword" placeholder="Name/Affiliation">
                        <div class="icon_boxa" @click="getSherch">
                            <img src="@/assets/memberm/search.png" alt="">
                        </div>
                    </div>
                    <div class="phone_zimu_box">
                        <span v-for="item in zimuArr" @click="clickZimu(item)"
                            :style="item == zimuActive ? 'color:#005083' : ''">{{ item }}</span>
                    </div>
                </div>
                <div class="phone_item_box">
                    <div v-for="item in ziList" class="phone_item">
                        <div>{{ item.name }}</div>
                        <div style="flex: 1;">{{ item.institution }}</div>
                        <div style="flex: 1;">{{ item.Region }}</div>
                    </div>
                </div>
                <pagination style="margin-top: 30px;" :pageData="pageData" @currentChange="currentChange"></pagination>
            </div>
            <div class="phone_inst_item_box">
                <div class="inst_title">Distribution of member research institutes</div>
                <div class="inst_content">
                    <div v-for="item in distribution" class="phone_inst_item">
                        <div class="phone_item_titles">
                            {{ item.value }}
                        </div>
                        <div class="phone_item_content">
                            <div v-for="items in item.list">{{ items.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="phone_title" style="text-align: center;margin-top: 20px;" id="MemberType">Member Type</div>
            <div class="phone_type_box">
                <div class="center_box">
                    <div class="center_box_item" v-for="(item, key) in datas.membertype">
                        <div class="img" :style="key == 1 ? 'width: 90px;height: 90px' : ''">{{ item.keyword }}
                        </div>
                        <div class="text">{{ item.title }}</div>
                    </div>
                </div>
                <div class="text_box">
                    <div v-for="item in datas.membertype" class="items">
                        <div class="texts">{{ item.title }}</div>
                        <div>
                            {{ item.remark }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="phone_title" style="text-align: center;margin-top: 20px;" id="Benefits">Benefits</div>
            <div v-for="(item, key) in datas.benefits" class="phone_benefits">
                <div v-for="items in item" class="crad_boxs">
                    <div class="titles">{{ items.title }}</div>
                    <div class="texts">{{ items.remark }}</div>
                </div>
            </div>
            <!-- <div class="phone_title" style="text-align: center;margin-top: 20px;" id="Achievements">Moments</div>
            <div class="phone_Moments_box">
                <div class="boxs" v-for="(item, key) in datas.moments">
                    <img style="object-fit: cover;" :src="item.photo" alt="">
                    <div>{{ item.title }}</div>
                </div>
            </div> -->
            <div class="phone_title" id="Election" style="margin-top: 20px;text-align: center;">Active Members</div>
            <div class="phone_active_box">
                <div class="items_box">
                    <div v-for="(item, key) in datas.activemembers">
                        <div class="item" v-for="items in item" @click="toDetailsinfo(items)">
                            <div class="phone_left_dingwei">
                                <img class="huangguan" src="@/assets/membership/huangguan.png" alt="">
                                <img class="touxiang" style="object-fit: cover;" :src="items.photo" alt="">
                            </div>
                            <div class="neirong">
                                <div class="name">
                                    {{ items.title }}
                                </div>
                                <div class="jianjie">
                                    {{ items.remark }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <foots @toPath="toPaths"></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import pagination from '@/components/pagination.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue'

export default {
    components: {
        heads,
        foots,
        leftMenu,
        banner,
        pagination,
    },
    data() {
        return {
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            leftMenuData: [
                {
                    name: 'Membership',
                    md: 'Membership',
                },
                {
                    name: 'Member Type',
                    md: 'MemberType',
                },
                {
                    name: 'Benefits',
                    md: 'Benefits'
                },
                // {
                //     name: 'Achievements',
                //     md: 'Achievements'
                // },
                {
                    name: 'Active Members',
                    md: 'Election'
                }
            ],
            datas: null,
            distribution: [],
            benefits: [],
            BenePageIndex: 0,
            activemembersIndex: 0,
            zimuArr: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            zimuActive: null,
            keyword: '',
            ziList: [],
            ziShow: false,
            activeIndex: 0
        }
    },
    computed: {
    },
    methods: {
        toDetailsinfo(item) {
            console.log(item.uid)
            //从本地缓存判断是否已经登录
            if (localStorage.getItem('userinfo')) {
                this.$router.push({
                    path: '/memberm4',
                    query: {
                        id: item.uid
                    }
                })
            } else {
                this.$router.push('/login')
            }




            // this.$router.push({
            //     path: '/detailsinfo',
            //     query: {
            //         id: item.id
            //     }
            // })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getSherch()
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        getDatas() {
            request({
                url: 'membershipdata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.datas = res.data
                res.other.world.forEach(item => {
                    this.distribution.push({
                        type: item.id,
                        value: item.value,
                        list: []
                    })
                })
                this.distribution.forEach(item => {
                    this.datas.world.forEach(items => {
                        if (item.type == items.type) {
                            item.list.push(items)
                        }
                    })
                })
                let arr = []
                let newArr = []
                this.datas.benefits.forEach((item, index) => {
                    arr.push(item)
                    if ((index + 1) % 3 == 0) {
                        newArr.push(arr)
                        arr = []
                    }
                })
                if (arr.length > 0) {
                    newArr.push(arr)
                }
                this.datas.benefits = newArr
                let arrs = []
                let newArrs = []
                this.datas.activemembers.forEach((item, index) => {
                    arrs.push(item)
                    if ((index + 1) % 2 == 0) {
                        newArrs.push(arrs)
                        arrs = []
                    }
                })
                if (arrs.length > 0) {
                    newArrs.push(arrs)
                }
                this.datas.activemembers = newArrs
            })
        },
        getSherch() {
            request({
                url: 'serachdata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    keyword: this.keyword,
                    zimu: this.zimuActive,
                    page: this.pageData.current_page
                }
            }).then(res => {
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
                this.ziList = res.data.data
                this.keyword = ''
            })
        },
        clickZimu(item) {
            if (this.zimuActive == item) {
                this.zimuActive = null
                this.getSherch()
                return
            }
            this.zimuActive = item
            this.getSherch()
        },
        toZhuce() {
            this.$router.push('/login')
        },
        toPaths(md) {
            this.scrollToAnchor(md)
        },
        scrollToAnchor(anchorId) {
            const anchorElement = document.getElementById(anchorId);
            if (anchorElement) {
                const rect = anchorElement.getBoundingClientRect();
                window.scrollTo({
                    top: rect.top + window.pageYOffset - 100, // 向上偏移100像素
                    behavior: 'smooth'
                });
            }
            this.leftMenuData.forEach((item, index) => {
                if (item.md == anchorId) {
                    this.activeIndex = index
                }
            })
        }
    },
    mounted() {
        this.getDatas()
        this.getSherch()

    },
    watch: {
        ziShow(val) {
            if (val) {
                this.zimuActive = null
                this.keyword = ''
                this.getSherch()
            }
        }

    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .box1 {
        display: none !important;
    }

    .phone_box1 {
        display: flex !important;
        flex-direction: column;
        margin: 0 15px;
        margin-bottom: 100px;

        .phone_title {
            font-size: 20px;
            font-weight: bold;
            color: #005083;
            margin-top: 10px;
        }

        .phone_text {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            margin-top: 10px;
        }

        .phone_btnbox {
            display: flex;
            margin-top: 20px;

            div {
                padding: 5px 10px;
                background: #005083;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
            }

        }

        .phone_shaixuan {
            margin-top: 20px;
            padding: 20px;
            background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
            border-radius: 20px;
            display: flex;
            flex-direction: column;

            .phone_xuan_title {
                font-size: 16px;
                font-weight: bold;
                text-align: center;
            }

            .phone_dian_box {
                display: flex;
                flex-direction: column;

                .phone_down_box {
                    margin: auto;
                    cursor: pointer;
                    background: rgba(0, 80, 131, 0.1);
                    border: 1px solid #00518319;
                    border-radius: 28px;
                    min-width: 220px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 20px;
                    width: 100%;
                    height: 40px;

                    img {
                        width: 12px;
                    }

                    .inp {
                        border: none;
                        background: none;
                        height: 100%;
                        padding: 0 20px;
                        // 清除默认样式
                        outline: none;
                        box-shadow: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        font-size: 16px;
                    }

                    .icon_boxa {
                        cursor: pointer;
                        width: 60px;
                        height: 100%;
                        background: #005083;
                        border-radius: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 18px;
                        }
                    }

                }

                .phone_zimu_box {
                    margin-top: 12px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    span {
                        width: 10%;
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        cursor: pointer;
                        margin-bottom: 6px;
                    }
                }
            }

            .phone_item_box {
                margin-top: 6px;

                .phone_item {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;

                    div {
                        display: flex;
                        flex-wrap: wrap;
                        width: 30% !important;
                        margin: 0 1%;
                    }
                }
            }
        }

        .phone_inst_item_box {
            margin-top: 20px;
            padding: 20px;
            background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
            border-radius: 20px;
            display: flex;
            flex-direction: column;

            .inst_title {
                font-size: 20px;
                font-weight: bold;
                color: #005083;
            }

            .phone_inst_item {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .phone_item_titles {
                    font-size: 16px;
                    color: #005083;
                    font-weight: bold;
                }

                .phone_item_content {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;

                    div {
                        font-size: 14px;
                        color: #333333;
                        border-radius: 20px;
                        padding: 5px 10px;
                        margin-bottom: 5px;
                        text-align: center;
                        border: 1px solid #005083;
                        margin-right: 10px;
                    }
                }
            }
        }

        .phone_type_box {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .center_box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .center_box_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex: 1;

                    .img {
                        width: 60px;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        font-weight: bold;
                        color: #FFFFFF;
                        cursor: pointer;
                        background-image: url("@/assets/membership/type_bg.png");
                        background-size: 100% 100%;
                    }

                    .text {
                        font-size: 12px;
                        font-weight: bold;
                        color: #005083;
                        margin-top: 10px;
                    }
                }

            }

            .text_box {
                margin-top: 20px;
                padding: 20px;
                background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
                border-radius: 20px;

                .items {
                    margin-bottom: 10px;
                }

                div {
                    font-size: 14px;
                    color: #333333;
                    text-align: center;

                    .texts {
                        font-weight: bold;
                        color: #005083;
                        margin-bottom: 5px;
                    }
                }

            }
        }

        .phone_benefits {
            display: flex;
            flex-direction: column;

            .crad_boxs {
                margin-top: 10px;
                padding: 20px;
                border-radius: 20px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px #E5E5E5;
                text-align: center;

                .titles {
                    font-size: 16px;
                    font-weight: bold;
                    color: #005083;
                }

                .texts {
                    font-size: 14px;
                    color: #333333;
                    margin-top: 10px;
                }
            }
        }

        .phone_Moments_box {
            display: flex;
            flex-direction: column;

            .boxs {
                margin-top: 10px;
                border-radius: 10px;
                overflow: hidden;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px #E5E5E5;
                text-align: center;

                img {
                    width: 100%;
                    max-height: 300px;
                    object-fit: cover;
                }

                div {
                    font-size: 16px;
                    font-weight: bold;
                    color: #005083;
                    margin: 10px 0;
                    text-align: left;
                    padding-left: 10px;
                }

            }
        }

        .phone_active_box {
            margin-top: 20px;
            padding: 20px;
            background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
            border-radius: 20px;
            display: flex;
            flex-direction: column;

            .items_box {

                .item {
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                    border-bottom: 1px solid rgba($color: #005083, $alpha: .5);
                    padding-top: 30px;

                    .phone_left_dingwei {
                        position: relative;

                        .huangguan {
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: -20px;
                            left: -10px;
                        }

                        .touxiang {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            border: 1px solid #005083;
                        }
                    }


                    .neirong {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;
                        background-color: #fff;
                        padding: 8px;
                        border-radius: 10px;

                        .name {
                            font-size: 16px;
                            font-weight: bold;
                            color: #005083;
                        }

                        .jianjie {
                            font-size: 14px;
                            color: #333333;
                        }
                    }
                }

            }
        }
    }
}

.content {
    width: 100%;
    background: #F5F5F5;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .left_box {
                border-radius: 0px 0px 20px 20px;
                box-shadow: 0px 0px 10px #E5E5E5;

                .left_head {
                    width: 317px;
                    height: 115px;
                    background: #005083;
                    border-radius: 20px 20px 0px 0px;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .left_item_box {
                    .item {
                        width: 100%;
                        min-height: 60px;
                        display: flex;
                        background-size: 100% 100%;
                        align-items: center;
                        justify-content: space-between;
                        color: #333;
                        cursor: pointer;
                        border-top: 1px solid #eee;

                        img {
                            width: 16px;
                            margin-right: 20px;
                        }

                        .zi_class {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                            height: 60px;
                        }
                    }

                    .active {
                        background-image: url("@/assets/news/left_bg.png");
                        color: #005083;
                    }
                }

            }

            .right_box {
                flex: 1;
                margin-left: 70px;

                .title {
                    font-size: 36px;
                    font-weight: bold;
                    color: #005083;
                }

                .text {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 30px;
                }

                .btn_box {
                    width: 333px;
                    height: 51px;
                    background: #005083;
                    border-radius: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 50px;
                    color: #fff;
                    cursor: pointer;
                }

                .shaixuan {
                    margin-right: 30px;
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    padding: 40px;
                    background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
                    border-radius: 20px;

                    div {
                        font-family: 'CustomFont1' !important;
                    }

                    .xuan_title {
                        font-size: 34px;
                        font-weight: bold;
                        color: #333333;
                    }

                    .dian_box {
                        display: flex;
                        justify-content: space-between;

                        .down_box {
                            cursor: pointer;
                            background: rgba(0, 80, 131, 0.1);
                            border: 1px solid #00518319;
                            border-radius: 28px;
                            min-width: 220px;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 22px 0 30px;
                            width: 240px;

                            img {
                                width: 12px;
                            }

                            .inp {
                                border: none;
                                background: none;
                                height: 100%;
                                padding: 0 20px;
                                // 清除默认样式
                                outline: none;
                                box-shadow: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                font-size: 16px;
                                width: 110px;
                            }

                            .icon_boxa {
                                cursor: pointer;
                                width: 60px;
                                height: 100%;
                                background: #005083;
                                border-radius: 28px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img {
                                    width: 18px;
                                }
                            }
                        }

                        .zimu_box {
                            margin-left: 20px;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            span {
                                font-size: 18px;
                                color: #333;
                                cursor: pointer;
                            }

                            .actives {
                                color: #005083;
                            }
                        }
                    }

                }

                .inst_item_box {
                    height: 800px;
                    background-image: url("@/assets/membership/bgbox.png");
                    background-size: 100% 100%;
                    padding: 80px 60px;
                    display: flex;
                    flex-direction: column;
                    margin-left: -30px;

                    .inst_title {
                        font-size: 30px;
                        font-weight: bold;
                        color: #005083;
                    }

                    .inst_content {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 50px;
                        height: 100%;

                        .left {
                            width: 92px;
                            height: 100%;
                            padding-right: 27px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            font-size: 20px;
                            font-weight: 400;
                            color: #005083;
                        }

                        .right {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-left: 30px;

                            .right_item_box {
                                display: flex;
                                width: 100%;
                                flex: 1;
                                flex-wrap: wrap;

                                .right_item {
                                    width: 18%;
                                    height: 40px;
                                    padding: 0 10px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid #005083;
                                    border-radius: 28px;
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: #005083;
                                    margin-right: 10px;
                                    text-align: center;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }

                .type_title {
                    font-size: 35px;
                    font-weight: bold;
                    color: #005083;
                    margin-top: 28px;
                    text-align: center;
                }

                .type_box {
                    margin-top: 70px;
                    width: 100%;

                    .center_box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .center_box_item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            // 第二个.img宽266px 其他的宽度都是200px
                            .img {
                                width: 200px;
                                height: 200px;
                                background-image: url("@/assets/membership/type_bg.png");
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-size: 100% 100%;
                                font-size: 72px;
                                font-family: Montserrat;
                                font-weight: bold;
                                color: #FFFFFF;
                                cursor: pointer;
                            }



                            .text {
                                font-size: 24px;
                                font-weight: bold;
                                color: #005083;
                                margin-top: 31px;
                            }

                        }
                    }

                    .text_box {
                        background-image: url("@/assets/membership/type_textbg.png");
                        background-size: 100% 100%;
                        margin-top: 30px;
                        padding: 31px 24px;
                        padding-bottom: 10px;

                        div {
                            font-size: 18px;
                            color: #333333;

                            .texts {
                                font-weight: bold;
                                color: #005083;
                                margin-bottom: 10px;
                                text-align: center;
                            }
                        }
                    }

                    .text_box>div {
                        margin-bottom: 30px;
                    }
                }

                .crad_boxs {
                    cursor: pointer;

                    div {
                        font-family: 'CustomFont1' !important;
                    }

                    &:hover {
                        animation: shake 0.5s ease;
                        background-color: #005083 !important;

                        div {
                            color: #fff !important;
                        }
                    }
                }

                @keyframes shake {
                    0% {
                        transform: translate(0, 0);
                    }

                    25% {
                        transform: translate(-2px, 0);
                    }

                    50% {
                        transform: translate(2px, 0);
                    }

                    75% {
                        transform: translate(-2px, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                .downs {
                    width: 41px;
                    height: 41px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    cursor: pointer;
                    background-color: #fff;
                    color: #005083;
                }

                .downs:hover {
                    background-color: #005083;
                    color: #fff;
                }

                .Moments_box {
                    margin-top: 28px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .boxs {
                        width: 32%;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 170px;
                            border-radius: 20px;

                        }

                        div {
                            font-size: 18px;
                            font-weight: bold;
                            color: #222222;
                        }
                    }
                }

                .active_box {
                    width: 100%;
                    margin-top: 20px;
                    padding-right: 60px;
                    margin-left: -30px;
                    height: 432px;
                    display: flex;
                    flex-direction: column;
                    background-image: url("@/assets/membership/moments_bg.png");
                    background-size: 100% 100%;

                    .titles {
                        font-size: 35px;
                        font-weight: bold;
                        color: #005083;
                        margin-top: 60px;
                        text-align: center;
                    }

                    .items_box {
                        display: flex;
                        align-items: center;
                        margin-top: 50px;
                        position: relative;

                        .item {
                            width: 40%;
                            height: 138px;
                            background: #fff;
                            border-radius: 10px;
                            position: relative;
                            margin-left: 110px;
                            cursor: pointer;

                            .huangguan {
                                width: 68px;
                                position: absolute;
                                left: -68px;
                                top: -30px;
                                z-index: 9;
                            }

                            .touxiang {
                                z-index: 1;
                                width: 138px;
                                height: 138px;
                                border-radius: 50%;
                                position: absolute;
                                left: -57px;
                                border: 1px solid #005083;
                            }

                            &:hover {
                                background: #005083;

                                .touxiang,
                                .huangguan {
                                    animation: shake 0.5s ease;
                                }

                                .name,
                                .jianjie {
                                    color: #fff !important;
                                }
                            }

                            .neirong {
                                margin-left: 103px;

                                .name {
                                    font-size: 20px;
                                    font-weight: bold;
                                    color: #005083;
                                    margin-top: 10px;
                                }

                                .jianjie {
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: #333333;
                                    margin-top: 10px;
                                    margin-right: 25px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;

                                }
                            }
                        }

                        .foot_content {
                            height: 41px;
                            width: 100%;
                            position: absolute;
                            bottom: -80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 60px;

                            div {
                                margin-right: 32px;

                            }
                        }
                    }
                }
            }
        }
    }
}
</style>