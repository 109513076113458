<template>
    <div class="content">
        <div class="box">
            <heads :isUpload="true"></heads>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    Personal homepage
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Upload
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'Personal homepage'" style="flex-shrink: 0;"></leftMenu>
                <div class="right_box">
                    <div class="item_boxs">
                        <div class="left_titie">Title</div>
                        <div class="item_right_box">
                            <el-input v-model="datas.title" style="flex: 1;"
                                placeholder="Please enter the title of this resource"></el-input>
                        </div>
                    </div>
                    <div class="item_boxs">
                        <div class="left_titie">Resource type</div>
                        <div style="display: flex;flex-wrap: wrap;flex: 1">
                            <div class="shaixuan2" v-for="item in shaixuan2arr"
                                v-if="item.status == 1 && item.type != 12" @click="typeClick(item)"
                                :class="datas.filetype == item.type ? 'active' : ''">
                                {{ item.value }}
                            </div>
                        </div>
                    </div>
                    <!-- 选中type是2，4，11展示内容 -->
                    <div v-if="datas.filetype == 2 || datas.filetype == 4 || datas.filetype == 11">
                        <div class="item_boxs">
                            <div class="left_titie">Authors</div>
                            <div class="item_right_box">
                                <el-select style="width: 100%;" v-model="datas.partner" filterable multiple remote
                                    default-first-option reserve-keyword placeholder="Please enter the names of authors"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in partnerOptions" :key="item.id" :label="item.fullname"
                                        :value="item.fullname">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Keywords</div>
                            <div style="display: flex;flex: 1;align-items: center;">
                                <div class="addbiaoqian">
                                    <input type="text" v-model="addTagValue">
                                    <i class="el-icon-plus" @click="getAddTag"></i>
                                </div>
                                <div class="biaoqian_box" v-if="biaoqianzu && biaoqianzu.length != 0 && biaoqianShow">
                                    <div v-for="(item, key) in biaoqianzu" :class="item.active ? 'actives' : ''"
                                        @click="onbiaoqian(key)">{{ item.title }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">DOI or URL</div>
                            <div class="item_right_box" style="display: flex;align-items: center;">
                                <el-input v-model="datas.cooperate" placeholder="Please enter DOI or URL"
                                    style="flex: 1;"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Publication date</div>
                            <div class="item_right_box">
                                <el-date-picker style="width: 100%;" v-model="datas.release_time" type="date"
                                    placeholder="Click to select the Publication date">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Availability</div>
                            <div>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="1">Public</el-radio>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="2">Private</el-radio>
                                <div style="display: flex;align-items: center;margin-top: 20px;">
                                    <el-radio @input="radioChages" v-model="datas.siyou" label="3">Only available
                                        to:</el-radio>
                                    <el-checkbox-group v-model="datas.only">
                                        <el-checkbox :disabled="datas.siyou != 3" label="1">Founding
                                            Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="2">Senior Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="3">Member</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="item_boxs">
                            <div class="left_titie">Abstract</div>
                            <div class="item_right_box" style="border-radius: 20px;">
                                <el-input v-model="datas.abstract" placeholder="Please enter the abstract"></el-input>
                            </div>
                        </div> -->
                        <div class="item_boxs">
                            <div class="left_titie">Abstract</div>
                            <div class="item_right_box" style="border-radius: 0%;">
                                <div style="border: 1px solid #ccc;margin-bottom: 40px;" v-if="!isPhone">
                                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
                                        :defaultConfig="toolbarConfig" :mode="mode" />
                                    <Editor style="height: 500px; overflow-y: hidden;font-size: 14px;"
                                        v-model="datas.content" :defaultConfig="editorConfig" :mode="mode"
                                        @onCreated="onCreated" />
                                </div>
                            </div>
                        </div>
                        <div v-if="datas.filetype == 4 || datas.filetype == 11">
                            <div class="item_boxs">
                                <div class="left_titie">Do you want to share data online or offline?</div>
                                <div class="item_right_box">
                                    <el-radio v-model="datas.is_on" label="1">Online</el-radio>
                                    <el-radio v-model="datas.is_on" label="2">Offline</el-radio>
                                </div>
                            </div>
                            <div class="item_boxs" v-if="datas.is_on == 1">
                                <div class="left_titie">File directory</div>
                                <div class="item_right_box">
                                    <el-select style="width: 40%;" clearable v-model="datas.first"
                                        @change="isEdit = false" placeholder="Please select a level 1 directory">
                                        <el-option v-for="item in yijiOption" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-select :disabled="!datas.first" style="width: 40%;margin-left: 20px;" clearable
                                        v-model="datas.second" @change="sceondChange"
                                        placeholder="Please select a subdirectory">
                                        <el-option v-for="item in erjiOption" :key="item.id" :label="item.secondname"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="item_boxs" v-if="datas.is_on == 1" :key="uploadImgKey">
                                <div class="left_titie">Attachment</div>
                                <label for="fileInput" style="cursor: pointer;">
                                    <div class="addupdate">
                                        <img src="@/assets/memberm/addUpdate.png" alt="">
                                    </div>
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                            <div class="item_boxs" v-else-if="datas.is_on == 2">
                                <div class="left_titie">Data volume</div>
                                <div class="item_right_box" style="display: flex">
                                    <el-input v-model="datas.other" style="flex: 4;"
                                        placeholder="Data volume"></el-input>
                                    <el-select style="width: 100%;flex: 1;" clearable v-model="datas.rongliang"
                                        placeholder="Please choose...">
                                        <el-option v-for="item in optionsrongliang" :key="item.id" :label="item.value"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="item_boxs" v-if="false">
                            <div class="left_titie">Submission location</div>
                            <div class="item_right_box">
                                <el-select style="width: 100%;" clearable v-model="datas.submissionlocation"
                                    placeholder="Choose the submission location">
                                    <el-option v-for="item in options" :key="item.id" :label="item.value"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div> -->
                        <div v-else>
                            <div class="item_boxs" :key="uploadImgKey">
                                <div class="left_titie">Attachment</div>
                                <label for="fileInput" style="cursor: pointer;">
                                    <div class="addupdate">
                                        <img src="@/assets/memberm/addUpdate.png" alt="">
                                    </div>
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                        </div>
                    </div>
                    <!-- 选中type是7展示内容 -->
                    <div v-else-if="datas.filetype == 7">
                        <div class="item_boxs">
                            <div class="left_titie">Authors</div>
                            <div class="item_right_box">
                                <el-select style="width: 100%;" v-model="datas.partner" filterable multiple remote
                                    default-first-option reserve-keyword placeholder="Please enter the names of authors"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in partnerOptions" :key="item.id" :label="item.fullname"
                                        :value="item.fullname">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Keywords</div>
                            <div style="display: flex;flex: 1;align-items: center;">
                                <div class="addbiaoqian">
                                    <input type="text" v-model="addTagValue">
                                    <i class="el-icon-plus" @click="getAddTag"></i>
                                </div>
                                <div class="biaoqian_box" v-if="biaoqianzu && biaoqianzu.length != 0 && biaoqianShow">
                                    <div v-for="(item, key) in biaoqianzu" :class="item.active ? 'actives' : ''"
                                        @click="onbiaoqian(key)">{{ item.title }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Conference name</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.journal" style="flex: 1;"
                                    placeholder="Please enter the Conference name of this resource"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Volume</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.volume" style="flex: 1;"
                                    placeholder="Please enter the Volume"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Issue</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.lssue" style="flex: 1;"
                                    placeholder="Please enter the Issue"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Pages</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.pages" style="flex: 1;"
                                    placeholder="Please enter the pages, e.g1-30"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">DOI or URL</div>
                            <div class="item_right_box" style="display: flex;align-items: center;">
                                <el-input v-model="datas.cooperate" placeholder="Please enter DOI or URL"
                                    style="flex: 1;"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Publication date</div>
                            <div class="item_right_box">
                                <el-date-picker style="width: 100%;" v-model="datas.release_time" type="date"
                                    placeholder="Click to select the Publication date">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Availability</div>
                            <div>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="1">Public</el-radio>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="2">Private</el-radio>
                                <div style="display: flex;align-items: center;margin-top: 20px;">
                                    <el-radio @input="radioChages" v-model="datas.siyou" label="3">Only available
                                        to:</el-radio>
                                    <el-checkbox-group v-model="datas.only">
                                        <el-checkbox :disabled="datas.siyou != 3" label="1">Founding
                                            Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="2">Senior Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="3">Member</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Abstract</div>
                            <div class="item_right_box" style="border-radius: 0%;">
                                <div style="border: 1px solid #ccc;margin-bottom: 40px;" v-if="!isPhone">
                                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
                                        :defaultConfig="toolbarConfig" :mode="mode" />
                                    <Editor style="height: 200px; overflow-y: hidden;font-size: 14px;"
                                        v-model="datas.content" :defaultConfig="editorConfig" :mode="mode"
                                        @onCreated="onCreated" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="item_boxs" :key="uploadImgKey">
                                <div class="left_titie">Attachment</div>
                                <label for="fileInput" style="cursor: pointer;">
                                    <div class="addupdate">
                                        <img src="@/assets/memberm/addUpdate.png" alt="">
                                    </div>
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                        </div>
                    </div>
                    <!-- 选中type是10展示内容 -->
                    <div v-else-if="datas.filetype == 10">
                        <div class="item_boxs">
                            <div class="left_titie">Authors</div>
                            <div class="item_right_box">
                                <el-select style="width: 100%;" v-model="datas.partner" filterable multiple remote
                                    default-first-option reserve-keyword placeholder="Please enter the names of authors"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in partnerOptions" :key="item.id" :label="item.fullname"
                                        :value="item.fullname">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Keywords</div>
                            <div style="display: flex;flex: 1;align-items: center;">
                                <div class="addbiaoqian">
                                    <input type="text" v-model="addTagValue">
                                    <i class="el-icon-plus" @click="getAddTag"></i>
                                </div>
                                <div class="biaoqian_box" v-if="biaoqianzu && biaoqianzu.length != 0 && biaoqianShow">
                                    <div v-for="(item, key) in biaoqianzu" :class="item.active ? 'actives' : ''"
                                        @click="onbiaoqian(key)">{{ item.title }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">University</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.journal" style="flex: 1;"
                                    placeholder="Please enter the University"></el-input>
                            </div>
                        </div>
                        <!-- <div class="item_boxs">
                            <div class="left_titie">Volume</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.volume" style="flex: 1;"
                                    placeholder="Please enter the Volume"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Issue</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.lssue" style="flex: 1;"
                                    placeholder="Please enter the Issue"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Pages</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.pages" style="flex: 1;"
                                    placeholder="Please enter the pages, e.g1-30"></el-input>
                            </div>
                        </div> -->
                        <div class="item_boxs">
                            <div class="left_titie">DOI or URL</div>
                            <div class="item_right_box" style="display: flex;align-items: center;">
                                <el-input v-model="datas.cooperate" placeholder="Please enter DOI or URL"
                                    style="flex: 1;"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Publication date</div>
                            <div class="item_right_box">
                                <el-date-picker style="width: 100%;" v-model="datas.release_time" type="date"
                                    placeholder="Click to select the Publication date">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Availability</div>
                            <div>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="1">Public</el-radio>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="2">Private</el-radio>
                                <div style="display: flex;align-items: center;margin-top: 20px;">
                                    <el-radio @input="radioChages" v-model="datas.siyou" label="3">Only available
                                        to:</el-radio>
                                    <el-checkbox-group v-model="datas.only">
                                        <el-checkbox :disabled="datas.siyou != 3" label="1">Founding
                                            Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="2">Senior Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="3">Member</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Abstract</div>
                            <div class="item_right_box" style="border-radius: 0%;">
                                <div style="border: 1px solid #ccc;margin-bottom: 40px;" v-if="!isPhone">
                                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
                                        :defaultConfig="toolbarConfig" :mode="mode" />
                                    <Editor style="height: 200px; overflow-y: hidden;font-size: 14px;"
                                        v-model="datas.content" :defaultConfig="editorConfig" :mode="mode"
                                        @onCreated="onCreated" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="item_boxs" :key="uploadImgKey">
                                <div class="left_titie">Attachment</div>
                                <label for="fileInput" style="cursor: pointer;">
                                    <div class="addupdate">
                                        <img src="@/assets/memberm/addUpdate.png" alt="">
                                    </div>
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                        </div>
                    </div>
                    <!-- 选中type是8，6展示内容 -->
                    <div v-else-if="datas.filetype == 8 || datas.filetype == 6">
                        <div class="item_boxs">
                            <div class="left_titie">Authors</div>
                            <div class="item_right_box">
                                <el-select style="width: 100%;" v-model="datas.partner" filterable multiple remote
                                    default-first-option reserve-keyword placeholder="Please enter the names of authors"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in partnerOptions" :key="item.id" :label="item.fullname"
                                        :value="item.fullname">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Keywords</div>
                            <div style="display: flex;flex: 1;align-items: center;">
                                <div class="addbiaoqian">
                                    <input type="text" v-model="addTagValue">
                                    <i class="el-icon-plus" @click="getAddTag"></i>
                                </div>
                                <div class="biaoqian_box" v-if="biaoqianzu && biaoqianzu.length != 0 && biaoqianShow">
                                    <div v-for="(item, key) in biaoqianzu" :class="item.active ? 'actives' : ''"
                                        @click="onbiaoqian(key)">{{ item.title }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Journal</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.journal" style="flex: 1;"
                                    placeholder="Please enter the Journal"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Volume</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.volume" style="flex: 1;"
                                    placeholder="Please enter the Volume"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Issue</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.lssue" style="flex: 1;"
                                    placeholder="Please enter the Issue"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Pages</div>
                            <div class="item_right_box">
                                <el-input v-model="datas.pages" style="flex: 1;"
                                    placeholder="Please enter the pages, e.g1-30"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">DOI or URL</div>
                            <div class="item_right_box" style="display: flex;align-items: center;">
                                <el-input v-model="datas.cooperate" placeholder="Please enter DOI or URL"
                                    style="flex: 1;"></el-input>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Publication date</div>
                            <div class="item_right_box">
                                <el-date-picker style="width: 100%;" v-model="datas.release_time" type="date"
                                    placeholder="Click to select the Publication date">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Availability</div>
                            <div>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="1">Public</el-radio>
                                <el-radio @input="radioChages" v-model="datas.siyou" label="2">Private</el-radio>
                                <div style="display: flex;align-items: center;margin-top: 20px;">
                                    <el-radio @input="radioChages" v-model="datas.siyou" label="3">Only available
                                        to:</el-radio>
                                    <el-checkbox-group v-model="datas.only">
                                        <el-checkbox :disabled="datas.siyou != 3" label="1">Founding
                                            Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="2">Senior Member</el-checkbox>
                                        <el-checkbox :disabled="datas.siyou != 3" label="3">Member</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                        <div class="item_boxs">
                            <div class="left_titie">Abstract</div>
                            <div class="item_right_box" style="border-radius: 0%;">
                                <div style="border: 1px solid #ccc;margin-bottom: 40px;" v-if="!isPhone">
                                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
                                        :defaultConfig="toolbarConfig" :mode="mode" />
                                    <Editor style="height: 200px; overflow-y: hidden;font-size: 14px;"
                                        v-model="datas.content" :defaultConfig="editorConfig" :mode="mode"
                                        @onCreated="onCreated" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="item_boxs" :key="uploadImgKey">
                                <div class="left_titie">Attachment</div>
                                <label for="fileInput" style="cursor: pointer;">
                                    <div class="addupdate">
                                        <img src="@/assets/memberm/addUpdate.png" alt="">
                                    </div>
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                        </div>
                    </div>

                    <!-- <div class="item_boxs" v-if="datas.filetype != 3">
                        <div class="left_titie">Add watermark</div>
                        <div class="item_right_box">
                            <el-radio v-model="datas.is_shui" label="1">Yes</el-radio>
                            <el-radio v-model="datas.is_shui" label="2">No</el-radio>
                        </div>
                    </div> -->
                    <div class="foot_list" v-if="imgFile && imgFile.length != 0">
                        <div class="list_item" v-for="item in imgFile">
                            <div class="times">{{ item.create_time }}</div>
                            <div class="neirong">
                                <div class="title">{{ item.picname }}</div>
                                <div style="display: flex;align-items: center;justify-content: center;"
                                    v-if="datas.filetype == 4 || datas.filetype == 11">
                                    <img :src="item.wenimg" alt="">
                                    <div style="margin: 0 20px;">{{ item.firstname }}</div>
                                    <div>{{ item.secondname }}</div>
                                </div>
                                <div style="display: flex;align-items: center;">
                                    {{ item.rongliang }}
                                    <a :href="item.picurl" target="_blank" @click="$download(item.id)">
                                        <img src="@/assets/news/down.png" alt="">
                                    </a>
                                    <i class="el-icon-delete" style="margin: 0 10px;font-size: 20px;cursor: pointer;"
                                        @click="toDelete(item)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="datas.filetype != null && datas.filetype != ''"
                        style="display: flex;justify-content: center;">
                        <!-- <div class="submit" @click="getUpdate">
                            <div>Submit</div>
                            <img src="@/assets/news/to_right_but.png" alt="">
                        </div> -->
                        <div class="submit seeMore" @click="getUpdate">
                            <span>Submit</span>
                            <img src="@/assets/index/right_icon.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import request from '@/utils/request'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        Editor,
        Toolbar
    },
    data() {
        return {
            uploadImgKey: 1,
            yijiOption: [],
            erjiOption: [],
            radio: null,
            checkList: [],
            isPhone: false,
            biaoqianShow: false,
            loading: false,
            leftMenuData: [{
                name: 'Profile',
                path: '/memberm'
            },
            {
                name: 'Upload',
                path: '/memberm3'
            },
            {
                name: 'Material Library',
                path: '/memberm2'
            },
            {
                name: 'File directory',
                path: '/contentsPage'
            },
            ],
            optionsrongliang: [
                {
                    value: 'MB',
                    id: 'MB'
                },
                {
                    value: 'GB',
                    id: 'GB'
                },
                {
                    value: 'TB',
                    id: 'TB'
                },
                {
                    value: 'PB',
                    id: 'PB'
                }
            ],
            editor: null,
            toolbarConfig: { excludeKeys: ['fullScreen'] },
            editorConfig: { placeholder: 'Please enter the context ', },
            mode: 'default', // or 'simple' default
            seleval: '',
            options: [],
            biaoqianzu: [],
            file: '',
            imgFile: [],
            datas: {
                title: null,
                filetype: null,
                content: null,
                label: [],
                submissionlocation: null,
                cooperate: null,
                partner: [],
                abstract: null,
                photo: null,
                user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                release_time: null,
                is_shui: null,
                rongliang: null,
                only: [],
                siyou: null,
                volume: null,
                lssue: null,
                pages: null,
                journal: null,
                second: null,
                first: null,
            },
            addTagValue: null,
            submitShow: true,
            partnerOptions: [],
            shaixuan2arr: [],
            isEdit: false,
        }
    },
    watch: {
        'datas.is_on': function () {
            if (this.datas.is_on == 1) {
                request({
                    url: 'check_you',
                    method: 'post',
                    data: {
                        user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                    }
                }).then(res => {
                    if (res.code == '405') {
                        this.$message.warning(res.msg)
                        setTimeout(() => {
                            this.$router.push({
                                path: '/contentsPage',
                            })
                        }, 1000);
                    } else {
                        request({
                            url: 'get_memberinfo_fisrt',
                            method: 'post',
                            data: {
                                user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                            }
                        }).then(res => {
                            console.log(res)
                            this.yijiOption = res.data
                        })
                    }
                })
            }
        },
        'datas.first': function (val) {
            if (!this.isEdit) {
                this.datas.second = null
            }
            request({
                url: 'get_memberinfo_second',
                method: 'post',
                data: {
                    user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                    cid: val
                }
            }).then(res => {
                this.erjiOption = res.data
            })
        },
        'datas.second': function (val) {
            console.log(val)
        }
    },
    methods: {
        sceondChange(event) {
            this.$forceUpdate()
        },
        typeClick(item) {
            if (this.datas.title == null || this.datas.title == '') {
                this.$message.error('Please enter the title of this resource')
                return
            }
            this.datas.filetype = item.type
            // 清除数据
            this.imgFile = []
            this.datas = {
                title: this.datas.title,
                filetype: item.type,
                content: null,
                label: [],
                submissionlocation: null,
                cooperate: null,
                partner: [],
                abstract: null,
                photo: null,
                user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                release_time: null,
                is_shui: null,
                rongliang: null,
                only: [],
                siyou: null,
                volume: null,
                lssue: null,
                pages: null,
                journal: null,
            }
            if (item.type == 4 || item.type == 11) {
                request({
                    url: 'check_you',
                    method: 'post',
                    data: {
                        user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                    }
                }).then(res => {
                    console.log(res)
                    if (res.code == '405') {
                        this.$confirm(res.msg, 'hint', {
                            confirmButtonText: 'confirm',
                            cancelButtonText: 'cancel',
                            type: 'warning'
                        }).then(() => {
                            this.$router.push({
                                path: '/contentsPage',
                            })
                        })
                    }
                })
            }
        },
        radioChages() {
            this.datas.only = []
        },
        toDelete(item) {
            this.$confirm('Are you sure to delete this data??', 'hint', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                request({
                    url: 'del_picname',
                    method: 'post',
                    data: {
                        id: item.id
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.imgFile = this.imgFile.filter(items => {
                            return items.picurl != item.picurl
                        })
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            })
        },
        remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                request({
                    url: 'api_name_find',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        keyword: query
                    }
                }).then(res => {
                    this.loading = false;
                    this.partnerOptions = res.data
                }).catch(() => {
                    this.loading = false;
                })
            } else {
                this.partnerOptions = [];
            }
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/memberm'
            })
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        onbiaoqian(key) {
            this.biaoqianzu[key].active = !this.biaoqianzu[key].active
            this.$forceUpdate()
        },
        // 获取arr标签数组
        getArr(arr) {
            request({
                url: 'apimemberbiaoqian',
                method: 'post',
                data: {
                    user_id: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null
                }
            }).then(res => {
                this.biaoqianzu = res.data
                this.biaoqianzu.forEach(item => {
                    item.active = false
                })
                if (this.$route.query.id) {
                    this.getupdate_memberinfodata()
                }
            })
        },
        getAddTag() {
            this.biaoqianShow = true
            request({
                url: 'apimemberbiaoqian_add',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    title: this.addTagValue,
                    user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                }
            }).then(res => {
                if (res.code == 200) {
                    this.biaoqianzu.push({ title: this.addTagValue, id: res.data.last_id, active: true })
                    this.addTagValue = null
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getWeizhi() {
            request({
                url: 'apiweizhi',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.options = res.data
            })
        },
        handleFileUpload(event) {
            if (this.datas.filetype == 4 || this.datas.filetype == 11) {
                if (!this.datas.first || !this.datas.second) {
                    this.$message.error('Please select primary and secondary folders')
                    this.uploadImgKey++
                    return
                }
            }

            this.file = event.target.files[0]; // 获取用户选择的文件
            if (!this.file) {
                this.uploadImgKey++
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            request({
                url: 'upload',
                method: 'post',
                data: {
                    file: this.file,
                    first: this.datas.first,
                    second: this.datas.second,
                    user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.imgFile.push(res.data)
                // this.datas.first = null
                // this.datas.second = null  
                loading.close();
                this.uploadImgKey++
                // 上传成功
                this.$message.success('Upload successfully')
            }).catch(() => {
                loading.close();
                this.uploadImgKey++
                // this.datas.first = null
                // this.datas.second = null
                this.$message.error('Upload failed')
            })
        },
        // 传入时间返回年月日时分秒
        getTimes(time) {
            let date = new Date(time)
            let Y = date.getFullYear() + '-'
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
            return Y + M + D + h + m + s
        },
        getUpdate() {
            if (!this.submitShow) {
                return
            }
            this.submitShow = false
            let datas = {
                title: this.datas.title,
                filetype: this.datas.filetype,
                content: this.datas.content,
                label: [],
                submissionlocation: this.datas.submissionlocation,
                cooperate: this.datas.cooperate,
                abstract: this.datas.abstract,
                user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                photo: [],
                partner: this.datas.partner,
                release_time: this.datas.release_time,
                rongliang: this.datas.rongliang,
                only: this.datas.only,
                siyou: this.datas.siyou,
                volume: this.datas.volume,
                journal: this.datas.journal,
                lssue: this.datas.lssue,
                pages: this.datas.pages,
                other: this.datas.other,
                is_on: this.datas.is_on,
            }

            if (this.$route.query.id) {
                datas.id = this.$route.query.id
            }
            this.biaoqianzu.forEach(item => {
                if (item.active) {
                    datas.label.push(item.id)
                }
            })
            this.imgFile.forEach(item => {
                if (item.nohttp) {
                    datas.photo.push(item.nohttp)
                } else {
                    datas.photo.push(item.photo)
                }
            })
            datas.release_time = datas.release_time ? this.getTimes(datas.release_time) : null
            datas.label = datas.label.join(',')
            datas.photo = datas.photo.join(',')
            datas.partner = datas.partner.join(',')
            datas.only = datas.only.join(',')

            request({
                url: 'api_user_dataadd',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: datas
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    setTimeout(() => {
                        this.$router.push({
                            path: '/memberm2'
                        })
                    }, 2000)
                } else {
                    this.$message.error(res.msg)
                }
                setTimeout(() => {
                    this.submitShow = true
                }, 3000)
            })
        },
        getupdate_memberinfodata() {
            request({
                url: 'update_memberinfodata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id
                }
            }).then(res => {
                if (res.code == 200) {
                    this.isEdit = true
                    this.datas = res.data
                    this.datas.release_time = this.datas.release_time ? new Date(this.datas.release_time) : null
                    this.imgFile = this.datas.filename ? this.datas.filename : []
                    this.biaoqianzu.forEach(item => {
                        if (this.datas.label.split(',').includes(item.id.toString())) {
                            item.active = true
                        }
                    })
                    this.datas.partner = this.datas.partner ? this.datas.partner.split(',') : []
                    this.datas.only = this.datas.only ? this.datas.only.split(',') : []
                    this.datas.first = null
                    this.datas.second = null

                } else {
                    this.$message.error(res.msg)
                }
            })
        }
    },
    mounted() {
        this.getArr()
        this.getWeizhi()
        if (document.body.clientWidth < 1025) {
            this.isPhone = true
        }
        request({
            url: 'api_type',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.shaixuan2arr = res.data
            console.log(this.shaixuan2arr)
        })

    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.content {
    width: 100%;
    background: #F7FAFB;

    ::v-deep .el-input__inner {
        border: none !important;
        border-radius: 30px;
    }

    ::v-deep .el-textarea__inner {
        border: none !important;
        min-height: 150px !important;
    }

    ::v-deep .el-input__prefix {
        display: none;
    }

    ::v-deep .el-input__suffix {
        display: none;
    }

    .box {
        position: relative;
        overflow: hidden;
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-top: 110px;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;


            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .icons {
            cursor: pointer;
        }

        .body_box {
            margin-top: 50px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .right_box {
                flex: 1;
                margin-left: 60px;

                // 缩小尺寸
                .item_boxs {
                    display: flex;
                    margin-bottom: 40px;
                    align-items: flex-start;

                    .left_titie {
                        width: 140px;
                        margin-right: 23px;
                    }

                    .item_right_box {
                        flex: 1;
                        border-radius: 30px;
                        overflow: hidden;

                        .right_addiod {
                            font-size: 14px;
                            font-weight: 400;
                            color: #FFFFFF;
                            padding: 0 10px;
                            height: 40px;
                            background: #005083;
                            box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                            border-radius: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin-left: 20px;
                        }
                    }

                    .addupdate {
                        width: 425px;
                        height: 160px;
                        background: #FFFFFF;
                        box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                        border-radius: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 57px;
                            height: 57px;
                        }
                    }

                    .shaixuan2 {
                        text-align: center;
                        margin-right: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 24px;
                        height: 40px;
                        margin-bottom: 10px;
                        background: #FFFFFF;
                        box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                        border-radius: 20px;
                        // 清除默认样式
                        font-size: 16px;
                        color: rgba($color: #333333, $alpha: .5);
                        cursor: pointer;
                    }

                    .active {
                        background: #005083;
                        color: #FFFFFF;
                    }

                    .addbiaoqian {
                        width: 110px;
                        height: 40px;
                        background: #FFFFFF;
                        box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                        border-radius: 20px;
                        padding: 0 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        font-size: 16px;

                        input {
                            border: none;
                            outline: none;
                            flex: 1;
                            height: 100%;
                            width: 60%;
                            border-right: 1px solid #E3E3E3;
                            font-size: 16px;
                            margin-right: 10px;
                        }

                        i {
                            cursor: pointer;
                            color: #005083;
                        }
                    }

                    .biaoqian_box {
                        flex: 1;
                        background-image: url('@/assets/memberm/zhileft.png');
                        background-size: 100% 100%;
                        margin-left: 36px;
                        padding: 6px 36px 6px 45px;

                        div {
                            display: inline-block;
                            margin-right: 20px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #999999;
                            cursor: pointer;
                            line-height: 30px;
                        }

                        .actives {
                            color: #005083;
                        }
                    }
                }

                .foot_list {
                    margin-top: 50px;

                    .list_item {
                        padding: 30px 0;
                        border-bottom: 1px solid #E3E3E3;

                        .times {
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .neirong {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .title {
                                font-size: 16px;
                                font-weight: 400;
                                color: #666666;
                                width: 200px;
                                word-wrap: break-word;
                            }

                            a {
                                margin-left: 10px;
                            }

                            img {
                                width: 49px;
                                margin-left: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .submit {
                    padding: 0 19px;
                    margin-top: 30px;
                    height: 38px;
                    border: 2px solid #005083;
                    border-radius: 30px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    div {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }

                    img {
                        width: 23px;
                        height: 23px;
                        margin-left: 30px;
                    }
                }
            }
        }
    }

}
</style>