<template>
    <div class="content">
        <div class="box">
            <banner :types="6"></banner>
            <heads></heads>
            <div class="title">Projects</div>
        </div>
        <div class="box1">
            <div class="icon_box" v-if="objs">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ objs.futitle }}
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ objs.title }}
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'Personal homepage'"></leftMenu>
                <div class="right_box">
                    <!-- <div class="dabiaoti">{{ objs.title }}</div> -->
                    <div class="item_box">
                        <div class="item" v-for="item in list" @click="toDetail(item.id)">
                            <img class="img" :src="item.photo" alt="">
                            <div class="texts">{{ item.title }}</div>
                            <div class="btn_box seeMore">
                                <span>
                                    See More
                                </span>
                                <img src="@/assets/news/to_right_but.png" alt="">
                            </div>
                        </div>
                    </div>
                    <pagination style="margin: 20px 0;" :pageData="pageData" @currentChange="currentChange">
                    </pagination>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import pagination from '@/components/pagination.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue'
import leftMenu from '@/components/leftMenu.vue'
export default {
    components: {
        heads,
        foots,
        pagination,
        banner,
        leftMenu
    },
    data() {
        return {
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            list: [],
            objs: null,
        }
    },
    methods: {
        toHome() {
            this.$router.push('/')
        },
        getDatas() {
            request({
                url: 'api_teamlist',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    page: this.pageData.current_page
                }
            }).then(res => {
                this.list = res.data.data
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
        toDetail(id) {
            this.$router.push({
                path: '/AtmosphericEnvironment',
                query: {
                    id: id
                }
            })
        }
    },
    mounted() {
        this.getDatas()
        this.objs = this.$route.query
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .dabiaoti {
        font-size: 20px !important;
        margin: 10px !important;
    }

    .body_box {
        margin: 10px !important;
        display: flex;

        .item_box {
            justify-content: space-between !important;
        }

        .item {
            margin: 0 !important;
            padding: 0 !important;
            display: flex;
            flex-direction: column !important;
            width: 45% !important;
            margin-bottom: 10px !important;

            .img {
                height: 150px !important;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            .texts {
                font-size: 15px !important;
                margin: 0 !important;
                padding: 10px !important;
            }

            .btn_box {
                display: none !important;
            }
        }
    }
}

.content {
    width: 100%;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;

        .dabiaoti {
            font-size: 45px;
            font-weight: bold;
            color: #005083;
            text-align: center;
            margin-top: 20px;
        }

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                margin-left: 30px;

                .item_box {
                    display: flex;
                    flex-wrap: wrap;

                    .item:nth-child(3n) {
                        margin-right: 0;
                    }

                    .item {
                        overflow: hidden;
                        width: 28%;
                        padding-bottom: 25px;
                        margin-bottom: 40px;
                        background: #F9F9F9;
                        border: 1px solid #D3D3D3;
                        border-radius: 12px;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-right: 7%;

                        .img {
                            object-fit: cover;
                            width: 100%;
                            height: 292px;
                            overflow: hidden;
                            border-radius: 12px;
                        }

                        .texts {
                            font-size: 20px;
                            font-family: Montserrat;
                            font-weight: bold;
                            color: #005083;
                            margin: 20px 15px;
                        }

                        .btn_box {
                            width: 153px;
                            height: 38px;
                            border: 2px solid #005083;
                            border-radius: 19px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            font-size: 18px;
                            font-weight: bold;
                            color: #333333;
                            margin-left: 15px;

                            img {
                                width: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>