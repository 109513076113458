<template>
    <div class="content">
        <div class="head_box" style="z-index: 999;" :style="isUpload ? 'background:#ffffff' : ''"
            :class="headShow ? 'hand_box_active' : ''">
            <img class="left" v-if="!isUpload && !headShow" src="@/assets/index/logo.png" @click="toHome">
            <img class="left" v-else-if="!isUpload && headShow" src="@/assets/index/logo1.png" @click="toHome">
            <img class="left" v-else src="@/assets/index/logo.png" @click="toHome">
            <div class="center">
                <div v-for="(item, key) in headArr">
                    <el-dropdown placement="bottom" v-if="item.children">
                        <div @click="toPath(item)" :class="headShow ? 'actives' : ''"
                            :style="isUpload ? 'color:#000' : ''">
                            {{ item.name }}
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item2, keys) in item.children" :key="keys" v-if="item.children">
                                <div @click="toPath(item2)">
                                    {{ item2.name }}
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div @click="toPath(item)" v-else :class="headShow ? 'actives' : ''"
                        :style="isUpload ? 'color:#000' : ''">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="right">
                <el-popover trigger="click">
                    <img v-if="!headShow" src="@/assets/index/head_search.png" alt="" slot="reference">
                    <img v-else src="@/assets/index/hand_search_active.png" alt="" slot="reference">
                    <div>
                        <el-input v-model="input" placeholder="Search for news,resouraes,pepple,etc."
                            style="width: 200px;" @keyup.enter.native="toSearch"></el-input>
                        <el-select style="width: 120px;margin-left: 10px;"  v-model="selectValue"
                            placeholder="Choose the submission location" >
                            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-button type="primary" @click="toSearch" style="margin-left: 10px;">Search</el-button>
                    </div>
                </el-popover>
                <div v-if="!userinfo" class="right_btn_box" :style="isUpload ? 'color:#000' : ''">
                    <span @click="toLogin">Login</span>
                    <span style="margin: 10px;opacity: .3;">|</span>
                    <span @click="toRegister">Register</span>
                </div>
                <el-popover v-else trigger="hover" width="150px">
                    <div style="display: flex;align-items: center;cursor: pointer;" @click="toMemberm()"
                        slot="reference">
                        <span class="namesBoxs" style="margin: 0 10px;color: #fff;opacity: .5;flex-shrink: 0;"
                            :style="isUpload ? 'color:#000' : ''">{{
            userinfo.data.fullname }}</span>
                        <img style="width: 39px;height: 39px;border-radius: 50%;object-fit: cover;"
                            :src="userinfo.data.photo" alt="">
                    </div>
                    <div @click="toDeleteUserinfo" class="tuichu">
                        Log out
                    </div>
                </el-popover>
            </div>
        </div>
        <div class="head_box1 hand_box_active" style="display: none;">
            <img class="left" src="@/assets/index/logos2.png" @click="toHome">
            <div style="display: flex;align-items: center;">
                <img v-if="!userinfo" @click="toPath({ url: '/Login' }), dialogVisible = false"
                    style="width: 30px;height:30px;margin-right: 40px;" src="@/assets/index/huiyuan.jpg">
                <el-popover v-else trigger="click" width="30px">
                    <img slot="reference"
                        style="width: 30px;height:30px;border-radius: 50%;background-color: #fff;margin-right: 40px;"
                        :src="userinfo.data.photo">
                    <div @click="toMemberm1(1), dialogVisible = false"
                        style="text-align: center;padding: 10px 0;border-bottom: 1px solid #ddd;">
                        Your profile
                    </div>
                    <div @click="toMemberm1(2), dialogVisible = false"
                        style="text-align: center;padding: 10px 0;border-bottom: 1px solid #ddd;">
                        Resource Upload
                    </div>
                    <div @click="toMemberm1(3), dialogVisible = false"
                        style="text-align: center;padding: 10px 0;border-bottom: 1px solid #ddd;">
                        Material Library
                    </div>
                    <div @click="toLoginout(), dialogVisible = false" style="text-align: center;padding: 10px 0;">
                        Log out
                    </div>
                </el-popover>
                <i class="icon" :class="dialogVisible ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                    @click="dialogVisible = !dialogVisible"></i>
            </div>
        </div>
        <div :style="dialogVisible ? 'right:0' : 'right:-100%'" class="mock_box" style="display: none;">
            <el-collapse accordion>
                <div v-for="(item, key) in headArr" :key="key">
                    <el-collapse-item :title="item.name" v-if="item.children">
                        <div v-for="(item2, keys) in item.children"
                            :style="keys == item.children.length - 1 ? 'border:none' : ''" :key="keys">
                            <div v-if="item2.url != '/WorkingGroups'" @click="toPath(item2), dialogVisible = false"
                                class="items">
                                {{ item2.name }}
                            </div>
                            <el-collapse accordion v-else class="sanji">
                                <el-collapse-item :title="item2.name">
                                    <div v-for="(item3, keys) in item2.children"
                                        :style="keys == item.children.length - 1 ? 'border:none' : ''" :key="keys">
                                        <div @click="toPath(item3), dialogVisible = false" class="items">
                                            {{ item3.name }}
                                        </div>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </el-collapse-item>
                    <div v-else class="el-collapse-item__header" style="border-bottom: 1px solid #666 !important;"
                        @click="toPath(item), dialogVisible = false">
                        {{ item.name }}
                    </div>
                </div>
            </el-collapse>
        </div>
    </div>
</template>
<script>
import request from '@/utils/request';
import pathArr from '@/utils/utils.js'
export default {
    props: {
        isUpload: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            headShow: false,
            dialogVisible: false,
            headArr: pathArr,
            userinfo: null,
            input: null,
            selectValue: null,
            options: null,

        }
    },
    mounted() {
        const dropdownMenuItems = document.querySelectorAll('.el-dropdown-menu__item');
        dropdownMenuItems.forEach((dropdownMenuItem) => {
            const parentElement = dropdownMenuItem.parentNode;
            parentElement.style.backgroundColor = '#005083';
            parentElement.style.border = 'none';
            parentElement.style.marginTop = '40px';
        });
        //  判断是否是首页
        if (this.$route.path === '/') {
            const head_box = document.querySelectorAll('.head_box');
            head_box.forEach((item) => {
                item.style.backgroundColor = 'rgba(0, 0, 0, 0)';
            });
            // head_box鼠标经过事件
            head_box.forEach((item) => {
                item.onmouseover = () => {
                    item.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
                }
                item.onmouseout = () => {
                    item.style.backgroundColor = 'rgba(0, 0, 0, 0)';
                }
            });
        }
        // 判断浏览器滚动条是否为0
        if (!this.isUpload) {
            window.onscroll = () => {
                if (document.documentElement.scrollTop === 0) {
                    this.headShow = false;
                } else {
                    this.headShow = true;
                }
            }
        }
        this.userinfo = JSON.parse(localStorage.getItem('userinfo')) || null;
        // if (!this.userinfo) {
        //     this.headArr = pathArr.filter(item => item.name !== 'Resources');
        // } else {
        //     this.headArr = pathArr;
        // }
        this.getWorkgroup();
        request({
            url: 'api_type',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.headArr.forEach(item => {
                if (item.name === 'Resources') {
                    item.url = '/Publications?type=' + res.data[0].type
                    item.children = res.data.map(item2 => {
                        return {
                            name: item2.value,
                            url: '/Publications?type=' + item2.type
                        }
                    })
                }
            })
            // res.data.forEach(item => {
            //     let json = {
            //         name: item.value,
            //         id: item.type,
            //         active: false,
            //     }
            //     if (item.value == "Data") {
            //         json.path = '/Publications?s=data&type=' + item.type
            //     } else {
            //         json.path = '/Publications?type=' + item.type
            //     }
            //     this.leftMenuData.push(json)
            // })
            // this.leftMenuData.forEach(item => {
            //     if (item.id == this.$route.query.type) {
            //         item.active = true
            //     } else {
            //         item.active = false
            //     }
            // })
        })
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.options = res.data.membertype
            this.selectValue = res.data.membertype[0].id
        })
    },
    methods: {
        getWorkgroup() {
            request({
                url: 'api_workgroup',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.headArr.forEach(item => {
                    if (item.name === 'Organization') {
                        item.children.forEach(item2 => {
                            if (item2.name == 'Working Groups') {
                                item2.children = res.data.map(item3 => {
                                    return {
                                        name: item3.title,
                                        url: '/WorkingGroups?id=' + item3.id
                                    }
                                })
                            }
                        })
                        console.log(this.headArr[1].children[5])
                    }
                })
                console.log(this.headArr[1].children[5])
            })
        },
        toSearch() {
            if (!this.input||!this.selectValue) {
                return;
            }
            this.$router.push({
                path: '/searchList',
                query: {
                    search: this.input,
                    findtype: this.selectValue
                }
            });
        },
        toHome() {
            if (this.$route.path === '/') {
                return;
            }
            this.$router.push('/');
        },
        toMemberm() {
            if (this.$route.path === '/memberm') {
                return;
            }
            this.$router.push('/memberm');
        },
        toLogin() {
            this.$router.push('/login');
        },
        toRegister() {
            this.$router.push('/register');
        },
        toPath(item) {
            console.log(item)
            if (!item.url) {
                return;
            }
            // 如果是当前页面 不跳转
            if (this.$route.path === item.url) {
                if (item.md) {
                    this.$emit('toPath', item.md);
                    return;
                }
                return;
            }
            this.$router.push(item.url);
        },
        toDeleteUserinfo() {
            localStorage.removeItem('userinfo');
            this.$router.push('/login');
        },
        toLoginout() {
            localStorage.removeItem('userinfo');
            this.$router.push('/');
        },
        toMemberm1(type) {
            if (type === 1) {
                this.$router.push('/memberm');
            } else if (type === 2) {
                this.$router.push('/memberm3');
            } else if (type === 3) {
                this.$router.push('/memberm2');
            }
        }
    },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .head_box {
        display: none !important;
    }

    .head_box1 {
        display: flex !important;
        position: fixed;
        top: 0;
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        box-shadow: 0 0 10px rgba(0, 0, 0, .8);

        .left {
            height: 40px;
            margin-left: 20px !important;
        }

        .icon {
            font-size: 25px;
            margin-right: 20px !important;
            color: #fff;
        }

    }

    .mock_box {
        width: 100%;
        position: fixed;
        top: 60px;
        right: -100%;
        z-index: 999;
        transition: all .5s;
        display: block !important;
        overflow: scroll;

        ::v-deep .el-collapse {
            border: none !important;
            padding-bottom: 10px !important;
        }

        ::v-deep .el-collapse-item__header {
            padding: 5px 20px !important;
            font-size: 20px !important;
            background-color: rgba(0, 0, 0, .6);
            color: #fff;
        }

        ::v-deep .el-collapse-item__content {
            padding: 5px 20px !important;
            background-color: rgba(0, 0, 0, .6);
            color: #fff;
            font-size: 18px !important;

            .items {
                border-bottom: 1px solid #fff;
                padding: 5px !important;
            }
        }

    }

}

.tuichu {
    padding: 5px 20px !important;
    font-size: 14px !important;
    color: #000;
    cursor: pointer;
    text-align: center;
}

.namesBoxs {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

::v-deep .popper__arrow {
    border-bottom-color: #005083 !important;
}

::v-deep .popper__arrow::after {
    border-bottom-color: #005083 !important;
}

::v-deep .el-dropdown-menu__item {
    background-color: #005083;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    margin-top: 15px;

    // 第一个不用margin-top
    &:first-child {
        margin-top: 0;
    }

    div {
        border-bottom: 1px solid #005083;
        font-size: 16px !important;
    }
}

::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #005083 !important;
    color: #fff;

    div {
        border-bottom: 1px solid #fff;
    }
}

.head_box {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #ffffff64;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    background-color: rgba($color: #000, $alpha: .1);
    justify-content: space-between;
    z-index: 9999;

    .left {
        height: 53px;
        margin: 0 0px 0 60px;
        cursor: pointer;
    }

    .center {
        width: 52%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;


        div {
            cursor: pointer;
            position: relative;
            color: #fff;
            font-size: 16px !important;

            &:hover {
                color: #005083;
            }
        }

        .actives:hover {
            color: #ccc;
        }
    }

    .right {
        display: flex;
        margin-right: 60px;

        img {
            height: 44px;
            cursor: pointer;
        }

        .right_btn_box {
            display: flex;
            border-radius: 21px;
            border: 2px solid rgba($color: #ffffff, $alpha: .3);
            width: 159px;
            height: 41px;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #fff;
            flex-shrink: 0;
            cursor: pointer;
        }
    }

}


.hand_box_active {
    background-color: #005083 !important;
    border: none !important;
    z-index: 99;
}

.sanji {
    padding-bottom: 0 !important;

    ::v-deep .el-collapse-item__header {
        background: none !important;
        padding: 5px !important;
        font-size: 18px !important;
    }
}
</style>