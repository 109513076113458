<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="5" :zidong="true"></banner>
            <heads @toPath="toPaths"></heads>
            <div class="title">Events</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Events
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ leftMenuData[activeIndex].name }}
                </div>
            </div>
            <div class="body_box" v-if="datas">
                <div class="left_box">
                    <div class="left_head left_head_active">Events</div>
                    <div class="left_item_box">
                        <div class="item" style="border: none;">
                            <div style="margin-left: 20px;">
                                Annual Meeting
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div v-for="(item, key) in leftMenuData">
                            <div @click="tocomm2(item)" class="item" style="border: none;"
                                :class="key == activeIndex ? 'active' : ''" v-if="!item.isChildren">
                                <div style="margin-left: 30px;">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="item" style="flex-direction: column;border: none;"
                                :class="key == activeIndex ? 'active' : ''" v-else>
                                <div class="zi_class" @click="tozhankai">
                                    <div style="margin-left: 30px;">
                                        {{ item.name }}
                                    </div>
                                    <img src="@/assets/news/to_right.png" alt="">
                                </div>
                                <div v-if="zhankai">
                                    <div class="zi_class" style="height: 40px;color: #000;font-size: 14px;"
                                        v-for="(items, keys) in item.children">
                                        <div style="margin-left: 20px;display: inline-block;"
                                            @click="scrollToAnchor(items.md)"
                                            :style="items.active ? 'color:#005083;border-bottom:2px solid #005083' : ''">
                                            {{ items.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="item" @click="toComm3">
                                <div style="margin-left: 20px;">
                                    大气环境遥感与协同分析会议
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                            <div class="item" @click="toComm5">
                                <div style="margin-left: 20px;">
                                    AERSS优博奖
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box">
                    <div class="text1" id="Topic"
                        style="margin-bottom: 10px;font-family: 'CustomFont2' !important;font-weight: bold !important;">
                        {{ datas.topic.topictitle }}</div>
                    <div class="text2">Background</div>
                    <div class="text3 text1 noneFont" style="padding-bottom: 15px;margin-bottom: 20px;"
                        v-html="datas.topic.remark"></div>
                    <div class="text2" id="Organizers">Institution</div>
                    <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.org.content">
                    </div>
                    <div class="text2" id="generalagenda">General Agenda</div>
                    <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.generalagenda.content">
                    </div>
                    <div class="text4" id="ImportantDates">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Important Dates</span>
                        <span class="box">
                            <div>
                                <div style="text-align: left;margin-bottom: 10px;cursor: pointer;"
                                    @click="toDays(item.id)" v-for="item in datas.important">{{
                item.title }}</div>
                            </div>
                            <span
                                style="height:auto;width: 1px;background-color: #0B1315;opacity: .1;display: block;margin: 0 10px;"></span>
                            <div>
                                <div @click="toDays(item.id)"
                                    style="text-align: center;margin-bottom: 10px;cursor: pointer;"
                                    v-for="item in datas.important">{{
                item.two }}</div>
                            </div>
                        </span>
                    </div>
                    <div class="text4" id="TopicSessions">
                        <div class="text2" style="margin-top: 15px;">Topic Sessions</div>
                        <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.sessions.content">
                        </div>
                    </div>
                    <div class="text4" id="PlenaryTalks">
                        <div class="text2" style="margin-top: 15px;">Plenary Talks</div>
                        <div style="margin-top: 15px;" class="text13 noneFont" v-html="datas.plen.content">
                        </div>
                    </div>
                    <!-- <div class="text4 time_box" id="time_box">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                dayTitle }}</span> -->
                    <!-- <div class="titlesa">
                            <div>Main Meeting room</div>
                            <div>Poste area</div>
                        </div> -->
                    <!-- <div style="margin-top: 15px;">
                            <div class="left_time" v-for="(item, key) in shijianData">
                                <div class="icon_bos">
                                    <img src="@/assets/committees/yuan.png" alt="">
                                    <div class="xiahuaxian" v-if="key < shijianData.length - 1"></div>
                                </div>
                                <div class="times">{{ item }}</div>
                                <div style="display: flex;">
                                    <div>
                                        <el-tooltip class="item" effect="dark" :content="items.title"
                                            v-for="items in datas.day" placement="top" style="display: flex;">
                                            <div class="itemsa" v-if="key == 0 && items.is_shangwu == 1"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.title }}
                                                </span>
                                            </div>
                                            <div class="itemsa" v-if="key == 2 && items.is_shangwu == 2"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.title }}
                                                </span>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin-left: 30px;">
                                        <el-tooltip class="item" effect="dark" :content="items.yingye_time"
                                            v-for="items in datas.day" placement="top" style="display: flex;">
                                            <div class="itemsa" v-if="key == 0 && items.is_shangwu == 1"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.yingye_time }}
                                                </span>
                                            </div>
                                            <div class="itemsa" v-if="key == 2 && items.is_shangwu == 2"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.yingye_time }}
                                                </span>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div id="AbstractCommittee" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Conference
                            Committees</span>
                        <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.advisory.content"></div>
                    </div>
                    <div class="text4" id="AbstractSubmission">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Abstract Submission
                        </span>
                        <div style="margin-top: 15px;font-size: 20px;margin-bottom: -20px;color: #333333;font-weight: 400 !important;"
                            class="noneFont" v-html="datas.absteactsubmissiom.remark">
                        </div>
                        <div @click="toUpload" class="noneFont"
                            style="font-weight: 400 !important;display: inline-flex;align-items: center;padding: 5px 10px;border: 2px solid #005083;border-radius: 19px;cursor: pointer;margin-top: 15px;">
                            Submit abstract
                            <div style="background-color: #005083;color: #fff;margin-left: 10px;" class="btnss">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <a :href="datas.absteactsubmissiom.wai" target="_blank" class="noneFont"
                            style="font-weight: 400 !important;margin-left: 20px;display: inline-flex;align-items: center;padding: 5px 10px;border: 2px solid #005083;border-radius: 19px;cursor: pointer;margin-top: 15px;">
                            Download Template
                            <div style="background-color: #005083;color: #fff;margin-left: 10px;" class="btnss">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </a>

                        <div style="margin-top: 15px;color: #005083;" class="noneFont" v-html="datas.absteactsubmissiom.keyword">
                        </div>
                    </div>
                    <div id="AcceptedAbstracts" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Accepted Abstracts</span>
                        <div style="margin-top: 15px;" class="text12 noneFont" v-if="datas.acceptedAbstracts" v-html="datas.acceptedAbstracts.content"></div>
                    </div>
                    <div id="Foots" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Registration Fee
                        </span>
                        <a :href="datas.sponsorshiplast.title" target="_blank">
                            <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.sponsorshiplast.content"></div>
                        </a>
                    </div>
                    <!-- <div id="OCommittees" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[7].name }}
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.organizingcommittee.content"></div>
                    </div> -->
                    <div id="Bestpresentationaward" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Best Presentation Award
                        </span>
                        <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.best.content"></div>
                    </div>
                    <!-- <div id="StudentYoungScholarsTravelGrants" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{ datas.lefttitle7 }}
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.grant.content"></div>
                    </div> -->


                    <div class="text4" id="children10">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">
                            Become a Sponsor
                        </span>
                        <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.become.content"></div>
                    </div>
                    <div id="ContactsandQuickLinks" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">
                            Contacts
                        </span>
                        <div style="margin-top: 15px;" class="text12 noneFont" v-html="datas.quick.content"></div>
                        <video :src="shipinurl" style="width: 100%;border-radius: 10px;" controls></video>
                    </div>
                    <div class="text6" id="VenueInformation">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Conference Venue and
                            Accommodation
                        </span>
                        <div style="margin-top: 15px;" class="noneFont" v-html="datas.venueinformation.content"></div>
                        <div class="content_boxs">
                            <div class="china">
                                <div class="titles">{{ datas.chinauniversity.title }}</div>
                                <div class="noneFont" v-html="datas.chinauniversity.content"></div>
                            </div>
                            <div class="traffic">
                                <div class="titles">{{ datas.trafficroutes.title1 }}</div>
                                <div class="noneFont" v-html="datas.trafficroutes.content1"></div>
                            </div>
                        </div>
                        <!-- <div class="blanks">{{ datas.str }} <a :href="datas.http" target="_blank">{{ datas.http }}</a></div> -->
                    </div>
                    <!-- <div class="text2" id="SponsorsandExhibitors">
                        {{ leftMenuData[0].children[11].name }}
                    </div>
                    <div style="margin-top: 15px;">
                        <div style="font-size: 20px;">Sponsors</div>
                        <div class="footlunbotu" v-if="sponsorsKey == index"
                            v-for="(item, index) in getJsonToTherr(datas.sponsors)">
                            <img v-for="items in item" :src="items.photo" alt="" @click="clickImgToPath(items)">
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <div style="font-size: 20px;">Exhibitors</div>
                        <div class="footlunbotu" v-if="exhibitorsKey == index"
                            v-for="(item, index) in getJsonToTherr(datas.exhibitors)">
                            <img v-for="items in item" :src="items.photo" alt="" @click="clickImgToPath(items)">
                        </div>
                    </div> -->

                    <!-- <div class="text5" id="PreliminaryProgramme">
                        <div class="left_boxs">
                            <a :href="datas.programme.photo[0]" target="_blank" rel="noopener noreferrer">
                                <img :src="datas.programme.picurl" alt="">
                            </a>
                        </div>
                        <div class="right_boxs">
                            <div class="t1">Preliminary Programme</div>
                            <div class="t2">{{ datas.programme.title }}</div>
                            <div class="t3">{{ datas.programme.remark }}</div>
                        </div>
                    </div> -->
                    <!-- <div class="text4" id="SCommittees">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Scientific
                            Committee</span>
                        <span class="box">
                            <div>
                                <div style="text-align: center;margin-bottom: 10px;"
                                    v-for="item in datas.committees.one">{{ item }}</div>
                            </div>
                            <span
                                style="height:auto;width: 1px;background-color: #0B1315;opacity: .1;display: block;"></span>
                            <div>
                                <div style="text-align: center;margin-bottom: 10px;"
                                    v-for="item in datas.committees.three">{{ item }}</div>
                            </div>
                            <span
                                style="height:auto;width: 1px;background-color: #0B1315;opacity: .1;display: block;"></span>
                            <div>
                                <div style="text-align: center;margin-bottom: 10px;"
                                    v-for="item in datas.committees.two">{{ item }}</div>
                            </div>
                        </span>
                    </div> -->
                    <!-- <div class="text4" id="OCommittees"> -->
                    <!-- <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Organising
                            Committee</span>
                        <span class="box">
                            <div>
                                <div style="text-align: center;margin-bottom: 10px;"
                                    v-for="item in datas.committees2.one">{{ item }}</div>
                            </div>
                            <span
                                style="height:auto;width: 1px;background-color: #0B1315;opacity: .1;display: block;"></span>
                            <div>
                                <div style="text-align: center;margin-bottom: 10px;"
                                    v-for="item in datas.committees2.two">{{ item }}</div>
                            </div>
                        </span> -->
                    <!-- </div> -->
                    <!-- <div class="text10" id="Vote" style="color: #7bb3da;">
                        <div style="color: #7bb3da;font-size: 30px;font-weight: 400 !important;margin-bottom: 10px;">{{
                datas.chineseexcellent.futitle }}</div>
                        {{ datas.chineseexcellent.msg }}
                    </div>
                    <div class="text11">
                        <div class="items11" v-for="item in datas.votelist">
                            <div class="title11_box">
                                <div style="font-weight: bold;">{{ item.remark }} {{ item.writer }}</div>
                                <div style="display: flex;align-items: center;">
                                    <a :href="item.photo" target="_blank"
                                        @click="$download(item.id), item.active = true">
                                        <img src="@/assets/committees/downs.png"
                                            style="width: 26px;margin-right: 10px;cursor: pointer;" alt="">
                                    </a>
                                    <div class="bq" @click="toVito(item)" style="cursor: pointer;">
                                        {{ item.vito }}
                                    </div>
                                </div>
                            </div>
                            <div class="contens11">
                                {{ item.title }}
                            </div>
                            <div style="display: flex;align-items: center;">
                               
                                <div class="shuxian"></div>
                                <img class="touxiang" :src="item.layimg" alt="">
                                <div class="name">{{ item.name }}</div>
                            </div> -->
                    <!-- </div>
                    </div>  -->
                    <!-- <div class="text4 text7" id="SponsorshipOpportunity">
                        <span class="titles7">{{ datas.sponsorship.title }}</span>
                        <div style="margin-top: 15px;font-size: 18px;margin-bottom: 30px;">{{ datas.sponsorship.remark
                            }}
                        </div>
                        <div v-html="datas.sponsorship.content1" style="margin-bottom: 30px;"></div>
                        <span class="titles7">{{ datas.sponsorship.title1 }}</span>
                        <div v-html="datas.sponsorship.content" style="margin: 30px 0;"></div>
                    </div> -->
                    <!-- <div style="margin-top: 15px;" class="text8">
                        <span v-for="item in datas.selection" v-if="item.title && item.title != ''"
                            style="font-size: 20px;color: #005083;width: 100%;display: block;">{{
                item.title }}</span>
                        <div v-for="item in datas.selection" v-if="item.remark && item.remark != ''"
                            style="margin-top: 15px;font-size: 18px;">
                            {{ item.remark }}
                        </div>
                        <div class="anniu_box">
                            <div :class="anniuActive == 1 ? 'active' : ''" @click="anniuActive = 1">Reward scope</div>
                            <div :class="anniuActive == 2 ? 'active' : ''" @click="anniuActive = 2">Evaluation Procedure
                            </div>
                            <div :class="anniuActive == 3 ? 'active' : ''" @click="anniuActive = 3">Submission of
                                materials
                            </div>
                        </div>
                        <div v-for="item in datas.selection" v-if="item.type == anniuActive">
                            <div v-html="item.content"></div>
                        </div>
                    </div>
                    <div class="text9">
                        <div>
                            <img src="@/assets/committees/icon_pdf.png" style="width: 42px;" alt="">
                            <div class="titles9">{{ datas.chineseexcellent.title }}</div>
                        </div>
                        <a @click="tostatus(datas.chineseexcellent)" target="_blank">
                            <img src="@/assets/committees/downs.png" style="width: 49px;cursor: pointer;" alt="">
                        </a>
                    </div> -->
                </div>
                <div class="phone_right_box right_box" style="display: none;" v-if="false">
                    <div class="text2">{{ leftMenuData[0].children[0].name }}</div>
                    <div class="text3 text1" v-html="datas.topic.remark"></div>
                    <div class="text2" id="Organizers">{{ leftMenuData[0].children[1].name }}</div>
                    <div style="margin-top: 15px;" class="text12" v-html="datas.org.content">
                    </div>
                    <!-- <div class="text4" id="ImportantDates">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[2].name }}</span>
                        <span class="box">
                            <div>
                                <div style="text-align: left;margin-bottom: 10px;cursor: pointer;"
                                    @click="toDays(item.id)" v-for="item in datas.important">{{
                item.title }}</div>
                            </div>
                            <span
                                style="height:auto;width: 1px;background-color: #0B1315;opacity: .1;display: block;"></span>
                            <div>
                                <div @click="toDays(item.id)"
                                    style="text-align: center;margin-bottom: 10px;cursor: pointer;"
                                    v-for="item in datas.important">{{
                item.two }}</div>
                            </div>
                        </span>
                    </div> -->
                    <div id="Foots" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[6].name }}
                        </span>
                        <a :href="datas.sponsorshiplast.title" target="_blank">
                            <div style="margin-top: 15px;" class="text12" v-html="datas.sponsorshiplast.content"></div>
                        </a>
                    </div>
                    <div class="text4" id="AbstractSubmission">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[5].name }}</span>
                        <div style="margin-top: 15px;font-size: 20px;color: #333333;font-weight: 400 !important;"
                            class="noneFont" v-html="datas.absteactsubmissiom.remark">
                        </div>
                        <div @click="toUpload" class="noneFont"
                            style="font-weight: 400 !important;display: inline-flex;align-items: center;padding: 5px 10px;border: 2px solid #005083;border-radius: 19px;cursor: pointer;margin-top: 15px;">
                            Submit abstract
                            <div style="background-color: #005083;color: #fff;margin-left: 10px;" class="btnss">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <div style="margin-top: 15px;color: #005083;" v-html="datas.absteactsubmissiom.keyword">
                        </div>
                    </div>
                    <div id="AbstractCommittee" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[4].name }}</span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.advisory.content"></div>
                    </div>
                    <div id="OCommittees" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[8].name }}
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.organizingcommittee.content"></div>
                    </div>
                    <div id="Bestpresentationaward" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                leftMenuData[0].children[8].name }}
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.best.content"></div>
                    </div>
                    <div id="StudentYoungScholarsTravelGrants" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{}}
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.grant.content"></div>
                    </div>
                    <div id="ContactsandQuickLinks" class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">Contacts and Quick
                            Links
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.quick.content"></div>
                        <video :src="shipinurl" style="width: 100%;border-radius: 10px;" controls></video>
                    </div>
                    <div class="text2" id="SponsorsandExhibitors">
                        {{}}
                    </div>
                    <div style="margin-top: 15px;">
                        <div style="font-size: 20px;">Sponsors</div>
                        <div v-if="sponsorsKey == index" v-for="(item, index) in getJsonToTherr(datas.sponsors)">
                            <img v-for="items in item" style="width: 100% !important;margin: 10px 0;" :src="items.photo"
                                alt="" @click="clickImgToPath(items)">
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <div style="font-size: 20px;">Exhibitors</div>
                        <div v-if="exhibitorsKey == index" v-for="(item, index) in getJsonToTherr(datas.exhibitors)">
                            <img v-for="items in item" style="width: 100% !important;margin: 10px 0;" :src="items.photo"
                                alt="" @click="clickImgToPath(items)">
                        </div>
                    </div>
                    <div class="text4">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">
                            {{ leftMenuData[0].children[7].name }}
                        </span>
                        <div style="margin-top: 15px;" class="text12" v-html="datas.become.content"></div>
                    </div>
                    <div class="text4 time_box" v-if="false">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{
                dayTitle }}</span>
                        <!-- <div class="titlesa">
                            <div>Main Meeting room</div>
                            <div>Poste area</div>
                        </div> -->
                        <div style="margin-top: 15px;">
                            <div class="left_time" v-for="(item, key) in shijianData">
                                <div class="icon_bos">
                                    <img src="@/assets/committees/yuan.png" alt="">
                                    <div class="xiahuaxian" v-if="key < shijianData.length - 1"></div>
                                </div>
                                <div class="times">{{ item }}</div>
                                <div style="display: flex;">
                                    <div>
                                        <el-tooltip class="item" effect="dark" :content="items.title"
                                            v-for="items in datas.day" placement="top" style="display: flex;">
                                            <div class="itemsa" v-if="key == 0 && items.is_shangwu == 1"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.title }}
                                                </span>
                                            </div>
                                            <div class="itemsa" v-if="key == 2 && items.is_shangwu == 2"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.title }}
                                                </span>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div style="margin-left: 30px;">
                                        <el-tooltip class="item" effect="dark" :content="items.yingye_time"
                                            v-for="items in datas.day" placement="top" style="display: flex;">
                                            <div class="itemsa" v-if="key == 0 && items.is_shangwu == 1"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.yingye_time }}
                                                </span>
                                            </div>
                                            <div class="itemsa" v-if="key == 2 && items.is_shangwu == 2"
                                                :class="items.status == 1 ? 'active' : ''"
                                                @click="tocommittess(items.id)">
                                                <span>
                                                    {{ items.yingye_time }}
                                                </span>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text6" id="VenueInformation">
                        <span style="font-size: 26px;color: #005083;width: 100%;display: block;">{{}}
                        </span>
                        <div style="margin-top: 15px;" v-html="datas.venueinformation.content"></div>
                        <div class="content_boxs">
                            <div class="china">
                                <div class="titles">{{ datas.chinauniversity.title }}</div>
                                <div v-html="datas.chinauniversity.content"></div>
                            </div>
                            <div class="traffic">
                                <div class="titles">{{ datas.trafficroutes.title1 }}</div>
                                <div v-html="datas.trafficroutes.content1"></div>
                            </div>
                        </div>
                        <!-- <div class="blanks">{{ datas.str }} <a :href="datas.http" target="_blank">{{ datas.http }}</a></div> -->
                    </div>
                </div>
            </div>
        </div>
        <foots @toPath="toPaths"></foots>
        <el-dialog title="" :visible.sync="dialogVisible" width="95%" class="tanchuang" :show-close="false">
            <span slot="footer" class="dialog-footer">
                <div class="tc_content" v-loading="loading">
                    <div @click="dialogVisible = false"
                        style="color: #fff;font-size: 25px;margin:20px 20px 0 0;cursor: pointer;"><i
                            class="el-icon-close"></i></div>
                    <div class="tc_box" style="padding-top: 10px;">
                        <div class="anniuMenu">
                            <div class="titles">Abstract title</div>
                            <div class="inps_box">
                                <input type="text" v-model="title">
                            </div>
                            <div class="titles">Session selection</div>
                            <el-select v-model="topic" placeholder="" style="width: 300px;" class="seletc1">
                                <el-option v-for="item in downArr" :key="item.id" :label="item.title" :value="item.id">
                                </el-option>
                            </el-select>
                            <div>
                                <label for="fileInput" style="cursor: pointer;">
                                    <div class="anniu">Upload attachments</div>
                                </label>
                                <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                    @change="handleFileUpload" />
                            </div>
                        </div>
                        <div class="updata_box" v-for="(item, keys) in uploadArr" style="border-bottom: 1px solid #fff;padding: 10px;">
                            <div style="width: 100%;text-align: left;color: #fff;">
                                <span v-if="keys==0">
                                    Presenting author
                                </span>
                                <span v-else>
                                    co-Author {{ keys }}
                                </span>
                            </div>
                            <div v-for="(items) in item">
                                <div v-if="!items.type" class="items">
                                    <div>
                                        <div style="text-align: left;">{{ items.name }}</div>
                                        <input type="text" placeholder="Check Add" @blur="toQuxiaoxuanzhong"
                                            v-model="items.value" :disabled="items.disabled"
                                            class="add_input add_input1" style="padding: 8px 0;">
                                    </div>
                                    <!-- <img class="icons" style="width: 18px;height: 18px;cursor: pointer;"
                                        src="@/assets/committees/icon_add.png" alt="" @click="getUpdata(items)"> -->
                                </div>
                                <div v-else-if="items.type == 'select'" class="items">
                                    <div>
                                        <div style="text-align: left;">{{ items.name }}</div>
                                        <el-select class="add_input add_input1" :disabled="items.disabled"
                                            v-model="items.value" placeholder="Please select"
                                            @change="toQuxiaoxuanzhong">
                                            <el-option v-for="item in options" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <!-- <img class="icons" style="width: 18px;height: 18px;cursor: pointer;"
                                        src="@/assets/committees/icon_add.png" alt="" @click="getUpdata(items)"> -->
                                </div>
                                <div v-else-if="items.type == 'filterable'" class="items">
                                    <div>
                                        <div style="text-align: left;">{{ items.name }}</div>
                                        <el-select class="add_input add_input1" v-model="items.value" filterable remote
                                            reserve-keyword placeholder="Please select" @change="toQuxiaoxuanzhong"
                                            :remote-method="remoteMethod" :disabled="items.disabled">
                                            <el-option v-for="item in options1" :key="item.name_cn"
                                                :label="item.name_cn" :value="item.name_cn">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <!-- <img class="icons" style="width: 18px;height: 18px;cursor: pointer;"
                                        src="@/assets/committees/icon_add.png" alt="" @click="getUpdata(items)"> -->
                                </div>
                            </div>
                            <div style="width: 100%;display: flex;margin: 10px 0;justify-content: flex-end;">
                                <i class="el-icon-delete" style="font-size: 30px;color: #fff;cursor: pointer;"
                                    @click="toItemDeletes(item)"></i>
                            </div>
                        </div>
                        <div class="add_btnbox" @click="pushArr">
                            <img style="width: 27px;height: 27px;margin-right: 10px;cursor: pointer;"
                                src="@/assets/committees/icon_push.png" alt="">
                            <div style="cursor: pointer;">
                                Up to 5 people can be added
                            </div>
                        </div>
                        <div class="fuwenben" v-if="!isPhone">
                            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
                                :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 300px; overflow-y: hidden;" v-model="desc"
                                :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>



                        <div class="tc_heads" v-if="picname">
                            <div class="tc_lefts">
                                <div class="tc_text1" v-if="WorkingGroups">{{ WorkingGroups }}</div>
                                <div class="tc_text2">
                                    {{ picname }}
                                </div>
                            </div>
                            <div class="tc_rights">
                                <img src="@/assets/committees/pdf_2.png" style="width: 36px; margin-right: 20px;"
                                    alt="">
                                <a :href="photo" target="_blank">
                                    <div class="tc_head_btns">Preview/Modify</div>
                                </a>
                                <div class="tc_head_btns" @click="toDelete">Delete</div>
                                <div class="tc_head_btns">Confirm</div>
                            </div>
                        </div>
                        <div class="tijiao" @click="toSubmit">
                            Submit
                        </div>
                    </div>
                    <div class="phone_tc_box tc_box" style="display: none;">
                        <div class="updata_box" v-for="item in uploadArr">
                            <div class="items" v-for="items in item">
                                <div>
                                    <div style="text-align: left;">{{ items.name }}</div>
                                    <input type="text" placeholder="Check Add" @blur="items.disabled = true"
                                        v-model="items.value" :disabled="items.disabled" class="add_input add_input1">
                                </div>
                                <!-- <img class="icons" style="width: 18px;height: 18px;cursor: pointer;margin-left: 31px;"
                                    src="@/assets/committees/icon_add.png" alt="" @click="getUpdata(items)"> -->
                            </div>
                            <i class="el-icon-delete" style="font-size: 20px;color: #fff;cursor: pointer;"
                                @click="toItemDeletes(item)"></i>
                        </div>

                        <div class="add_btnbox" @click="pushArr">
                            <img style="width: 27px;height: 27px;margin-right: 10px;cursor: pointer;"
                                src="@/assets/committees/icon_push.png" alt="">
                            <div style="cursor: pointer;">
                                Up to 5 people can be added
                            </div>
                        </div>
                        <div class="anniuMenu">
                            <div>
                                <div class="titles">Session selection</div>
                                <el-select v-model="topic" placeholder="" style="flex: 1;" class="seletc1">
                                    <el-option v-for="item in downArr" :key="item.id" :label="item.title"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <div class="titles">Abstract title</div>
                                <div class="inps_box" style="flex: 1;">
                                    <input type="text" v-model="title">
                                </div>
                            </div>
                            <div>
                                <div style="flex: 1;text-align: center;margin-left: 10px;">
                                    <label for="fileInput" style="cursor: pointer;">
                                        <div class="anniu">Upload attachments</div>
                                    </label>
                                    <input type="file" style="display: none;" id="fileInput" ref="fileInput"
                                        @change="handleFileUpload" />
                                </div>
                            </div>

                        </div>
                        <div class="fuwenben" v-if="isPhone">
                            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"
                                :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 300px; overflow-y: hidden;" v-model="desc"
                                :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>
                        <div class="tijiao" @click="toSubmit">
                            Submit
                        </div>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import banner from '@/components/banner.vue';
import swiper from '@/components/swiper.vue'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        Editor,
        Toolbar,
        banner,
        swiper
    },
    data() {
        return {
            sponsorsKey: 0,
            exhibitorsKey: 0,
            leftMenuData: [
                {
                    name: 'Annual Meeting 2024',
                    isChildren: true,
                    children: [],
                },
            ],
            loading: false,
            datas: null,
            shijianData: [],
            dayTitle: '',
            anniuActive: 1,
            dialogVisible: false,
            downArr: [],
            uploadArr: [
                [
                    {
                        name: 'First Name',
                        value: null,
                        props: 'first_name',
                        disabled: false
                    },
                    {
                        name: 'Last Name',
                        value: null,
                        props: 'last_name',
                        disabled: false
                    },
                    {
                        name: 'Institution',
                        value: null,
                        props: 'unit',
                        disabled: false
                    },
                    {
                        name: 'E-mail',
                        value: null,
                        props: 'email',
                        disabled: false
                    },
                    {
                        name: 'Title',
                        value: null,
                        props: 'title',
                        type: 'select',
                        disabled: false
                    },
                    {
                        name: 'Region',
                        value: null,
                        props: 'region',
                        type: 'filterable',
                        disabled: false,
                        options: []
                    },
                ]
            ],
            fuwenbenShow: false,
            editor: null,
            toolbarConfig: {},
            editorConfig: { placeholder: 'Please copy your abstrct here！' },
            mode: 'simple', // or 'simple , default'
            seleval: '',
            topic: null,
            title: null,
            desc: null,
            photo: null,
            picname: null,
            WorkingGroups: null,
            activeIndex: 0,
            isPhone: false,
            zhankai: false,
            shipinurl: '',
            options: [],
            options1: [],
            titles1: null,
        }
    },
    mounted() {
        if (document.body.clientWidth < 1025) {
            this.isPhone = true
        }
        this.getData()
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.shipinurl = res.data.shipin
            this.options = res.data.position
            // this.titles1 = res.data.yeartitleone
            // this.leftMenuData[0].children[1].name = res.data.yeartitleone
        })
        request({
            url: 'topicdata',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.downArr = res.data
        })
        request({
            url: 'apimeeting',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            let arr = []
            res.data.meeting.forEach(item => {
                arr.push({
                    name: item.title,
                    id: item.id
                })
            })
            this.leftMenuData = [this.leftMenuData[0], ...arr]
        })
        // setInterval(() => {
        //     request({
        //         url: 'api_aboutdata',
        //         method: 'post',
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         },
        //     }).then(res => {
        //         this.datas = res.data
        //     })
        // }, 4000);
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        toQuxiaoxuanzhong() {
            // this.uploadArr.forEach(item => {
            //     item.forEach(items => {
            //         items.disabled = true
            //     })
            // })
        },
        remoteMethod(query) {
            if (query !== '') {
                request({
                    url: 'find_country_data',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        name_cn: query
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.options1 = res.data
                    }
                })
            } else {
                this.options1 = [];
            }
        },
        toComm3() {
            this.$router.push({
                path: '/Committees3',
            })
        },
        toComm5() {
            this.$router.push({
                path: '/Committees5',
            })
        },
        tocomm2(path) {
            this.$router.push({
                path: '/committees2',
                query: {
                    id: path.id
                }
            })
        },
        getleftMenu() {
            request({
                url: 'get_left_menu',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.leftMenuData = res.data
            })
        },
        tozhankai() {
            this.zhankai = !this.zhankai
        },
        clickImgToPath(item) {
            if (item.httpurl) {
                window.open(item.httpurl)
            }
        },
        // 定时器抡博图
        lunboshow() {
            if (this.getJsonToTherr(this.datas.sponsors).length > 1) {
                setInterval(() => {
                    this.sponsorsKey++
                    if (this.sponsorsKey == this.getJsonToTherr(this.datas.sponsors).length) {
                        this.sponsorsKey = 0
                    }
                }, 5000);
            }
            if (this.getJsonToTherr(this.datas.exhibitors).length > 1) {
                setInterval(() => {
                    this.exhibitorsKey++
                    if (this.exhibitorsKey == this.getJsonToTherr(this.datas.exhibitors).length) {
                        this.exhibitorsKey = 0
                    }
                }, 5000);
            }
        },
        getJsonToTherr(json) {
            // 把传入的数组分为二维数组 三个一组
            let arr = []
            for (let i = 0; i < json.length; i += 3) {
                arr.push(json.slice(i, i + 3))
            }
            return arr
        },
        toItemDeletes(item) {
            this.uploadArr.splice(this.uploadArr.indexOf(item), 1)
        },
        toDays(id) {
            this.getData(id)
            this.scrollToAnchor('PreliminaryProgramme')
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tostatus(item) {
            window.open(item.photo)
            this.$download()
            // if (item.status == 2) {
            //     this.$message({
            //         message: item.votemsg,
            //         type: 'warning'
            //     });
            // } else {
            //     window.open(item.photo)
            //     this.$download()
            // }
        },
        tocommittess(id) {
            this.$router.push({ path: '/committees', query: { id: id } })
        },
        toPaths(md) {
            this.scrollToAnchor(md)
        },
        scrollToAnchor(anchorId) {
            const anchorElement = document.getElementById(anchorId);
            if (anchorElement) {
                const rect = anchorElement.getBoundingClientRect();
                window.scrollTo({
                    top: rect.top + window.pageYOffset - 100, // 向上偏移100像素
                    behavior: 'smooth'
                });
            }
            this.leftMenuData.forEach((item, index) => {
                if (item.md) {
                    if (item.md == anchorId) {
                        this.activeIndex = index
                    }
                }
            })
        },
        // 获取数据
        getData(id) {
            request({
                url: 'api_aboutdata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: id || null
                }
            }).then(res => {
                this.datas = res.data
                if (this.datas.sponsorshiplast) {
                    this.datas.sponsorshiplast.content = this.datas.sponsorshiplast.content.replace(/<img/g, '<img title="" style="width: 100%"')
                }
                let arr = []
                this.datas.menudata.forEach((item, key) => {
                    if (item.mao != 'Time_box') {
                        arr.push({
                            name: item.name,
                            md: item.mao
                        })
                    }
                })
                console.log(arr)
                this.leftMenuData[0].children = arr
                console.log(this.leftMenuData[0].children)
                // this.lunboshow()
            })
            request({
                url: 'get_time_data',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: id || null
                }
            }).then(res => {
                this.shijianData = []
                if (res.data.shangwu) {
                    this.shijianData = [...res.data.shangwu]
                }
                if (res.data.xiawu) {
                    this.shijianData = [...this.shijianData, ...res.data.xiawu]
                }
                this.dayTitle = res.data.title
            })

        },
        toUpload() {
            if (this.datas.absteactsubmissiom.httpurl) {
                window.open(this.datas.absteactsubmissiom.httpurl)
                return
            } else {
                // 登录后才能上传
                if (!localStorage.getItem('userinfo')) {
                    this.$message.warning('Please log in first')
                    return
                }
                this.dialogVisible = true
            }
        },
        getUpdata(items) {
            this.toQuxiaoxuanzhong()
            if (items.disabled) {
                items.disabled = false
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        pushArr() {
            if (this.uploadArr.length < 5) {
                this.uploadArr.push([
                    {
                        name: 'First Name',
                        value: null,
                        props: 'first_name',
                        disabled: false
                    },
                    {
                        name: 'Last Name',
                        value: null,
                        props: 'last_name',
                        disabled: false
                    },
                    {
                        name: 'Institution',
                        value: null,
                        props: 'unit',
                        disabled: false
                    },
                    {
                        name: 'E-mail',
                        value: null,
                        props: 'email',
                        disabled: false
                    },
                    {
                        name: 'Title',
                        value: null,
                        props: 'title',
                        type: 'select',
                        disabled: false
                    },
                    {
                        name: 'Region',
                        value: null,
                        props: 'region',
                        type: 'filterable',
                        disabled: false
                    },
                ])
            }
        },
        handleFileUpload(event) {
            this.loading = true
            this.file = event.target.files[0]; // 获取用户选择的文件
            if (!this.file) {
                return
            }
            request({
                url: 'upload',
                method: 'post',
                data: {
                    file: this.file
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.photo = res.data.picurl
                    this.picname = res.data.picname
                    this.WorkingGroups = res.data.workinggroups
                } else {
                    this.$message.error(res.msg)
                }
                this.loading = false
            })
        },
        toSubmit() {
            let yeardata = []
            this.uploadArr.forEach(item => {
                let obj = {}
                item.forEach(items => {
                    obj[items.props] = items.value
                })
                yeardata.push(obj)
            })
            let datas = {
                topic: this.topic,
                title: this.title,
                photo: this.photo,
                desc: this.desc,
                yeardata: JSON.stringify({ items: yeardata }),
                user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
            }
            request({
                url: 'topicdataadd',
                method: 'post',
                data: datas,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        toDelete() {
            // 弹窗询问是否关闭
            this.$confirm('确认清除数据并关闭弹层吗', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.topic = null
                this.title = null
                this.desc = null
                this.photo = null
                this.uploadArr = [
                    [
                        {
                            name: 'First Name',
                            value: null,
                            props: 'first_name',
                            disabled: false
                        },
                        {
                            name: 'Last Name',
                            value: null,
                            props: 'last_name',
                            disabled: false
                        },
                        {
                            name: 'Institution',
                            value: null,
                            props: 'unit',
                            disabled: false
                        },
                        {
                            name: 'E-mail',
                            value: null,
                            props: 'email',
                            disabled: false
                        },
                        {
                            name: 'Title',
                            value: null,
                            props: 'title',
                            type: 'select',
                            disabled: false
                        },
                        {
                            name: 'Region',
                            value: null,
                            props: 'region',
                            type: 'filterable',
                            disabled: false
                        },
                    ]
                ]
                this.dialogVisible = false

            }).catch(() => {

            });
        }
    }
}
</script>

<style lang="scss" scoped>
.footlunbotu {
    display: flex;
    align-items: center;

    img {
        width: 240px;
        height: 120px;
        margin: 10px;
        border-radius: 10px;
    }
}

.updata_box {
    ::v-deep .el-input__inner:disabled {
        background-color: #002C48 !important;
        border: none !important;
        height: 30px !important;
        padding-left: 0;
    }

    ::v-deep .el-input__suffix {
        display: none !important;
    }
}



@media screen and (max-width: 1025px) {
    div {
        word-break: break-all;
    }

    .body_box {
        margin-top: 0 !important;
    }

    .right_box {
        display: none;
    }

    .phone_right_box {
        display: block !important;
        margin: 10px !important;
        padding: 0 !important;

        .text2 {
            font-size: 20px !important;
            margin-top: 10px !important;
        }

        .text3 {
            font-size: 16px !important;
            margin-top: 10px !important;
            padding-bottom: 10px !important;
        }

        .text4 {
            margin-top: 10px !important;
            padding: 10px !important;

            span {
                font-size: 20px !important;
            }

            .box {
                margin-top: 10px !important;

                div {
                    margin: 0 !important;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    div {
                        margin: 10px !important;
                        flex: 1;
                    }
                }
            }
        }

        .text5 {
            margin-top: 10px !important;
            align-items: normal !important;

            .right_boxs {
                height: auto !important;
                justify-content: space-around !important;
                flex: 2 !important;
            }

            .t1 {
                font-size: 20px !important;
            }

            .t2 {
                margin-top: 10px !important;
                font-size: 16px !important;
            }

            .t3 {
                font-size: 14px !important;
            }
        }

        .time_box {
            display: flex;
            flex-direction: column;

            .titlesa {
                margin-top: 10px !important;
                padding: 0 !important;
            }

            .left_time {
                .xiahuaxian {
                    min-height: 60px !important;
                }

                .icon_bos {
                    width: 20px !important;
                }

                .times {
                    width: 60px !important;
                    padding: 0 !important;
                    margin: 0 10px !important;
                    font-size: 16px !important;
                }

                .itemsa1 {
                    width: auto !important;
                    height: auto !important;
                    font-size: 12px !important;
                    background: linear-gradient(90deg, #a2bfd2 0%, rgba(255, 255, 255, 0.1) 100%);
                    border-radius: 15px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px !important;
                }
            }
        }

        .text6 {
            margin-top: 10px !important;
            display: flex;
            flex-direction: column;
            padding-bottom: 10px !important;

            .titles {
                font-size: 20px !important;
            }

            ::v-deep img {
                max-width: 100%;

                &:hover {
                    transform: scale(1) !important;
                }
            }

            ::v-deep iframe {
                width: 100%;
            }

            .content_boxs {
                margin-top: 10px !important;
                display: flex;
                flex-direction: column;

                .china {
                    width: 100% !important;
                }
            }
        }

        .text8 {
            padding-bottom: 10px !important;

            .anniu_box {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px !important;
                justify-content: space-between;

                div {
                    margin: 0 !important;
                    margin-bottom: 10px !important;
                    font-size: 14px !important;
                }
            }
        }

        .text9 {
            margin-top: 10px !important;

            .titles9 {
                font-size: 14px !important;
            }
        }

        .text10 {
            margin-top: 10px !important;
            font-size: 12px !important;
        }

        .text11 {
            margin-top: 10px !important;

            .items11 {
                padding: 10px !important;
                margin-top: 10px !important;

                .bq {
                    flex-shrink: 0;
                }

                .contens11 {
                    font-size: 14px !important;
                }

                .touxiang {
                    width: 30px !important;
                    height: 30px !important;
                }
            }
        }

        .text12 {
            ::v-deep img {
                max-width: 100%;

                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    ::v-deep .el-dialog {
        width: 100% !important;
        margin-top: 1vh !important;

        .tc_heads {
            width: auto !important;
            flex-wrap: wrap;
            padding: 10px !important;
            height: auto !important;

            .tc_lefts {
                margin: 0 !important;
                flex-direction: column;
                align-items: normal !important;
            }

            .tc_text1 {
                margin: 0 !important;
                text-align: left;
            }

            .tc_text2 {
                margin: 10px 0 !important;
                width: auto !important;
            }

            .tc_rights {
                display: flex;
                align-items: center;

                img {
                    display: none;
                }

                .tc_head_btns {
                    margin-right: 5px !important;
                    font-size: 12px !important;
                    margin-bottom: 5px !important;
                }
            }
        }

        .tc_heads>div {
            display: flex;
            flex-wrap: wrap;
        }

        .tc_box {
            display: none;
        }

        .phone_tc_box {
            padding: 10px !important;
            display: block !important;

            .anniuMenu {
                align-items: normal !important;
                flex-direction: column !important;
            }

            .anniuMenu>div {
                width: 98% !important;
                margin: auto !important;
                display: flex;
                align-items: center !important;
                justify-content: space-between !important;
                text-align: left !important;

                input {
                    padding: 10px 0 !important;
                }

                .titles {
                    width: 200px;
                }
            }
        }

        .updata_box {
            flex-wrap: wrap;

            .items {
                width: 45%;
                font-size: 14px !important;
                padding: 10px 0 !important;

                input {
                    font-size: 14px !important;
                    width: 100% !important;
                    padding: 0 !important;
                }

                img {
                    margin-left: 10px !important;
                }
            }

            .items>div {
                flex: 1;
            }
        }

        .add_btnbox {
            margin-top: 20px !important;
            font-size: 14px !important;

            img {
                width: 20px !important;
                height: 20px !important;
            }
        }

        .anniuMenu {
            margin-top: 10px !important;
            flex-wrap: wrap;
            font-size: 10px !important;
            justify-content: flex-start !important;

            .titles {
                font-size: 14px !important;
                margin-bottom: 10px !important;
            }

            .seletc1 {
                width: 150px !important;
                margin-bottom: 10px !important;
            }

            .inps_box {
                width: 150px !important;
                margin-bottom: 10px !important;
            }

            .anniu {
                font-size: 12px !important;
            }
        }

        .fuwenben {
            margin-top: 10px !important;
        }

        .tijiao {
            font-size: 16px !important;
            margin: auto;
        }
    }
}

div {
    font-weight: 100 !important;
}

.content {
    width: 100%;
    background: #fff;

    a {
        // 清除a标签的下划线
        text-decoration: none;
    }

    .tc_content {
        background: #002C48;
        border-radius: 20px;
        overflow: hidden;

        .tc_heads {
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;

            .tc_lefts {
                flex: 1;
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #FFFFFF;

                .tc_text1 {
                    margin-left: 47px;
                }

                .tc_text2 {
                    // margin-left: 92px;
                    text-align: left;
                    width: 400px;
                    // 超出省略号
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }
            }

            .tc_rights {
                display: flex;
                align-items: center;
                padding-right: 20px;

                .tc_head_btns {
                    margin-right: 15px;
                    padding: 4px 15px;
                    border: 1px solid rgba($color: #ffffff, $alpha: .5);
                    border-radius: 21px;
                    font-size: 16px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }

        .tc_box {
            padding: 50px;

            ::v-deep .w-e-bar {
                display: none !important;
            }

            .updata_box {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                flex-wrap: wrap;

                .items {
                    border-bottom: 1px solid rgba($color: #999, $alpha: 0.1);
                    display: flex;
                    align-items: flex-end;
                    color: #fff;
                    padding: 20px 0;
                    justify-content: space-between;

                    img {
                        margin-left: 35px;
                    }

                    .add_input {
                        outline: none;
                        color: #000000 !important;
                    }

                    .add_input1 {
                        flex: 1;
                        font-size: 18px;
                        margin-top: 5px;
                    }

                    input:disabled {
                        border: none !important;
                        background: none;
                        color: #C0C4CC !important;
                        height: 10px;
                    }

                    ::v-deep .el-input__inner {
                        padding: 18px 0 !important;
                        height: 30px !important;
                        border-radius: 0px !important;
                    }

                }

                .items>div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
            }

            .updata_box>div {
                width: 30%;
            }

            .add_btnbox {
                display: flex;
                align-items: center;
                margin-top: 47px;
                font-size: 16px;
                color: #FFFFFF;

            }

            .anniuMenu>div {
                margin-bottom: 20px;
                margin-right: 1%;
            }

            .anniuMenu {
                display: flex;
                margin-top: 37px;
                align-items: center;
                flex-wrap: wrap;

                .titles {
                    font-size: 20px;
                    color: #FFFFFF;
                }

                .inps_boxs {
                    display: block;
                    text-align: left;
                    box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                    border-radius: 20px;
                    background-color: #fff;
                    cursor: pointer;
                    height: 100%;
                }

                .inps_box {
                    display: flex;

                    input {
                        flex: 1;
                        padding: 10px 15px;
                        box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                        border-radius: 20px;
                        border: none;
                    }
                }

                .anniu {
                    padding: 10px 18px;

                    background: #005083;
                    box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                    border-radius: 20px;
                    font-size: 16px;
                    color: #FFFFFF;
                    cursor: pointer;
                }

                ::v-deep .el-input__inner {
                    flex: 1;
                    padding: 12px 15px;
                    box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                    border-radius: 20px;
                    border: none;
                    height: 100%;
                    line-height: normal;
                }

                ::v-deep .el-input__suffix {
                    display: none;

                }
            }

            .tijiao {
                font-size: 16px;
                color: #FFFFFF;
                width: 147px;
                height: 40px;
                background: #005083;
                box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-top: 15px;
            }

            .fuwenben {
                // border: 1px solid #ccc;
                margin-top: 15px;
                border-radius: 30px;
                overflow: hidden;
                text-align: left;
                // 去掉字体斜体

                ::v-deep div {
                    font-style: normal;
                    font-family: 'CustomFont1' !important;
                }
            }
        }
    }


    ::v-deep .el-dialog {
        background: none;
        box-shadow: none;
    }

    .btnss {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
    }

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 60px;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 15px;
            display: flex;
            align-items: flex-start;

            .left_box {
                border-radius: 0px 0px 20px 20px;
                box-shadow: 0px 0px 10px #E5E5E5;

                .left_head {
                    padding: 0 20px;
                    min-width: 317px;
                    height: 115px;
                    font-size: 25px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #E5E5E5;
                    cursor: pointer;
                    text-align: center;
                }

                .left_head_active {
                    background: #005083;
                    border-radius: 20px 20px 0px 0px;
                    color: #FFFFFF;
                }

                .left_item_box {
                    .item {
                        width: 100%;
                        min-height: 60px;
                        display: flex;
                        background-size: 100% 100%;
                        align-items: center;
                        justify-content: space-between;
                        color: #333;
                        cursor: pointer;
                        border-top: 1px solid #eee;

                        img {
                            width: 16px;
                            margin-right: 20px;
                        }

                        .zi_class {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                            height: 60px;
                            margin-bottom: 10px;
                        }
                    }

                    .active {
                        background-image: url("@/assets/news/left_bg.png");
                        color: #005083;
                    }
                }

            }

            .right_box>div {
                ::v-deep table {
                    width: 100% !important;
                }

                ::v-deep img {
                    max-width: 100% !important;
                }
            }

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;
                margin-left: 80px;

                .text1 {
                    font-size: 18px;
                    color: #333333;
                }

                .text2 {
                    font-size: 28px;
                    color: #005083;
                    // margin-top: 20px;
                }

                .text3 {
                    margin-top: 29px;
                    padding-bottom: 50px;
                    border-bottom: 1px solid #E5E5E5;
                }

                .text4 {
                    height: auto;
                    background: linear-gradient(180deg, #FFFFFF 0%, #C0D4E0 100%);
                    border-radius: 20px;
                    border: 1px solid #E5E5E5;
                    padding: 15px 30px;
                    margin-top: 20px;

                    // line-height: 30px;
                    .box>div {
                        font-size: 18px;
                        color: #333333;
                    }

                    .box {
                        display: flex;
                        height: auto;
                        width: 100%;
                        margin-top: 15px;
                    }
                }

                .text5 {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left_boxs {
                        flex: 2;
                        height: 200px;
                        background: #FFFFFF;
                        border: 1px solid #E8E8E8;
                        border-radius: 20px;
                        margin-right: 20px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 100%;
                            height: 100%;

                        }
                    }

                    .right_boxs {
                        flex: 4;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .t1 {
                            font-size: 30px;
                            color: #005083;
                        }

                        .t2 {
                            font-size: 25px;
                            color: #333333;
                            margin-top: 20px;
                        }

                        .t3 {
                            font-size: 18px;
                            color: #333333;
                        }
                    }

                }

                .time_box {
                    .titlesa {
                        margin-top: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-left: 30px;

                        div {
                            font-size: 18px;
                            color: #333333;
                            flex: 1;
                            text-align: center;
                        }
                    }

                    .left_time {
                        display: flex;

                        .icon_bos {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            img {
                                width: 12px;
                                height: 12px;
                            }

                            .xiahuaxian {
                                width: 1px;
                                border-right: 1px dashed #1E51A2;
                                height: auto;
                                flex: 1;
                                min-height: 120px;
                            }
                        }

                        .times {
                            font-size: 18px;
                            color: #005083;
                            margin-left: 20px;
                            margin-right: 90px;
                            margin-top: -6px;
                        }

                        .itemsa {
                            width: 206px;
                            height: 80px;
                            max-width: 100%;
                            background: linear-gradient(90deg, rgba($color: #A2BFD2, $alpha: 1) 0%, rgba($color: #ffffff, $alpha: .1) 100%);
                            border-radius: 15px;
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 18px;
                            color: #222222;
                            cursor: pointer;
                            padding: 0 10px;

                            span {
                                // 超出省略号
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                            }
                        }

                        .active {
                            background: linear-gradient(90deg, rgba($color: #005083, $alpha: 1) 0%, rgba($color: #ffffff, $alpha: .1) 100%);
                            color: #FFFFFF;
                        }
                    }

                }

                .text6 {
                    margin-top: 15px;
                    border-bottom: 1px solid #E5E5E5;

                    ::v-deep iframe {
                        width: 100% !important;
                    }


                    ::v-deep img {
                        max-width: 100%;

                        &:hover {
                            transform: scale(1);
                        }
                    }

                    .content_boxs {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0px;

                        // ::v-deep img {
                        //     width: 100% !important;
                        //     height: auto !important;

                        //     &:hover {
                        //         transform: scale(1);
                        //     }
                        // }

                        .china {
                            overflow: hidden;
                            flex: 1.5;
                            margin-right: 50px;

                            div {
                                // 英文换行
                                word-break: break-all !important;
                            }
                        }

                        .traffic {
                            overflow: hidden;
                            flex: 1;
                        }

                        .titles {
                            font-size: 24px;
                            color: #005083;
                        }
                    }

                    .blanks {
                        font-size: 16px;
                        color: #222222;

                        a {
                            color: #005083;
                            // 清除a标签的下划线
                            text-decoration: none;
                        }
                    }
                }

                .text7 {
                    .titles7 {
                        font-size: 25px;
                        color: #005083;
                        width: 100%;
                        display: block;
                    }

                    ::v-deep img {
                        max-width: 100% !important;

                        &:hover {
                            transform: scale(1);
                        }
                    }
                }

                .text8 {
                    margin-top: 15px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #E5E5E5;

                    .anniu_box {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;

                        div {
                            padding: 6px 13px;
                            background: #F2F2F2;
                            color: #333333;
                            border-radius: 22px;
                            margin-right: 20px;
                            cursor: pointer;
                            margin-bottom: 30px;
                        }

                        .active {
                            background: #005083;
                            color: #FFFFFF;
                        }
                    }
                }

                .text9 {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .titles9 {
                        font-size: 20px;
                        color: #005083;
                        margin-left: 20px;
                    }

                    div {
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 10px;
                        }
                    }
                }

                .text10 {
                    font-size: 18px;
                    color: #005083;
                    margin-top: 15px;
                }

                .text11 {
                    .items11 {
                        background: linear-gradient(180deg, #FFFFFF 0%, #F1F5F8 100%);
                        border-radius: 20px;
                        border: 1px solid #E5E5E5;
                        padding: 10px 30px;
                        margin-top: 20px;

                        .title11_box {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: #7bb3da;
                            font-size: 16px;

                            .bq {
                                width: 83px;
                                height: 23px;
                                background: rgba($color: #000000, $alpha: .1);
                                border-radius: 12px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 14px;
                                color: #333333;
                            }
                        }

                        .contens11 {
                            font-size: 20px;
                            color: #000000;
                            margin: 16px 0 10px 0;
                        }

                        .shuxian {
                            width: 1px;
                            height: 23px;
                            background: #E5E5E5;
                            margin: 0 20px;
                        }

                        .touxiang {
                            width: 47px;
                            height: 47px;
                            border-radius: 50%;
                            margin-right: 10px;
                            object-fit: cover;
                        }

                        .name {
                            color: #333333;
                            font-size: 14px;
                        }
                    }
                }

                .text12 {
                    ::v-deep img {
                        max-width: 100% !important;

                        &:hover {
                            transform: scale(1);
                        }
                    }
                }

                .text13 {
                    ::v-deep img {
                        max-width: none !important;

                        &:hover {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
</style>