<template>
    <div class="swiper_content">
        <div class="item_box"  :style="{ left: '-' + active * 100 + '%' }">
            <div v-for="item in datas" class="item fangda">
                <img class="images imgnone" :src="item" alt="">
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        datas: {
            type: Array,
            default: () => []
        },
 
        active: 0
    },
    data() {
        return {
        }
    },
    computed: {

    },
    mounted() {
        console.log(this.active)
    },
    methods: {

    },
    watch: {
        active() {
            console.log(this.active)
            // 每行展示三个 超过三个下一页
        }
    }
}
</script>
  
<style lang="scss" scoped>
.swiper_content {
    height: 167px;
    overflow: hidden;
    position: relative;
    display: flex;

    .item_box {
        transition: all 1.5s;
        position: absolute;
        left: 0;
        // 超出不换行
        white-space: nowrap;

        .item {
            display: inline-block;
            margin-right: 40px;
            width: 200px;
        }

        .item:nth-child(3n) {
            margin-right: 50px;
        }

        .images {
            width: 100%;
            height: 137px;
            border-radius: 20px;
        }
    }


}
</style>
  