<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <heads :isUpload="true"></heads>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <div style="display: flex;align-items: center;" v-if="datas.siyou == 1 || datas.siyou == 3">
                    <img class="right" src="@/assets/news/right_down.png" alt="">
                    <div @click="tofuPath" style="cursor: pointer;margin-right: 10px;">
                        Resources
                    </div>
                </div>
                <div style="display: flex;align-items: center;" v-else-if="datas.siyou == 2">
                    <img class="right" src="@/assets/news/right_down.png" alt="">
                    <div @click="toPath('/memberm')" style="cursor: pointer;margin-right: 10px;">
                        Personal homepage
                    </div>
                    <img class="right" src="@/assets/news/right_down.png" alt="">
                    <div @click="toPath('/memberm2')" style="cursor: pointer;margin-right: 10px;">
                        Material Library
                    </div>
                </div>
                <!-- <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    {{ fuClass }}
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ datas.title }}
                </div> -->
            </div>
            <div class="body_box" v-if="datas">
                <div class="left_box" v-if="datas.userinfo && isLeftShow">
                    <div class="info1">
                        <img class="touxiang" @click="topaths(datas.userinfo.id)" :src="datas.userinfo.photo" alt="">
                        <img class="huangguan" src="@/assets/membership/huangguan.png" v-if="datas.userinfo.star == 1"
                            alt="">
                    </div>
                    <div style="font-weight: bold;color: #005083;font-size: 20px;margin-top: 18px;">
                        {{ datas.userinfo.fullname }}
                    </div>
                    <div style="color: #333;font-size: 14px;margin-top: 10px;max-width: 250px;text-align: center;">
                        {{ datas.userinfo.institution }}
                    </div>
                    <div style="font-weight: bold;color: #333;font-size: 18px;margin-top: 20px;">
                        Resources
                    </div>
                    <div style="font-weight: bold;color: #005083;font-size: 32px;margin-top: 10px;">
                        {{ datas.userinfo.articlenum }}
                    </div>
                    <div style="width: 237px;height: 1px;background-color: #005083;opacity: .1;margin-top: 10px;"></div>
                    <div
                        style="margin-top: 20px;font-weight: bold;color: #005083;font-size: 20px;text-align: center;width: 263px;margin-bottom: 30px;">
                        {{ datas.userinfo.dibustr }}
                    </div>
                </div>
                <div class="phone_left_box left_box" v-if="datas.userinfo" style="display: none;">
                    <div class="phone_head1">
                        <div class="info1">
                            <img class="touxiang" @click="topaths(datas.userinfo.id)" :src="datas.userinfo.photo"
                                alt="">
                            <img class="huangguan" src="@/assets/membership/huangguan.png" alt="">
                        </div>
                        <div style="margin-left: 50px;flex: 1;" class="phone_infos">
                            <div style="font-weight: bold;color: #005083;font-size: 16px;margin-top: 10px;">
                                {{ datas.userinfo.last_name }}
                            </div>
                            <div style="font-weight: bold;color: #333;font-size: 14px;margin-top: 10px;">
                                {{ datas.userinfo.institution }}
                            </div>
                            <div style="font-weight: bold;color: #333;font-size: 14px;margin-top: 10px;">
                                Article
                            </div>
                            <div style="font-weight: bold;color: #005083;font-size: 20px;margin-top: 10px;">
                                {{ datas.userinfo.articlenum }}
                            </div>
                        </div>
                    </div>
                    <div
                        style="margin-top: 10px;font-weight: bold;color: #005083;font-size: 14px;text-align: center;margin-bottom: 10px;">
                        {{ datas.userinfo.dibustr }}
                    </div>
                </div>
                <div class="right_box">
                    <div class="head_1" style="align-items: flex-start;">
                        <div style="display: flex;" class="head_1box">
                            <div style="margin-right: 70px;" v-if="datas.writer"> {{ datas.writer }}
                            </div>
                            <div style="margin-right: 70px;" v-if="datas.views">
                                {{ datas.views }}
                            </div>
                            <div style="display: flex;align-items: center;color: #999999;">
                                <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png" alt="">
                                {{ datas.create_time | timestampToTime }}
                            </div>
                        </div>

                    </div>
                    <div class="head_1" style="margin-top: 30px;">
                        <div style="display: flex;align-items: center;flex-wrap: wrap;" class="tipsbox"
                            v-if="datas.biaoqian && datas.biaoqian.length != 0 && datas.filetype != 12">
                            <div class="tips" style="margin:0 10px 10px 0;" v-for=" item  in  datas.biaoqian "
                                v-if="item">{{ item }}</div>
                        </div>
                        <div style="display: flex;align-items: center;flex-wrap: wrap;" class="tipsbox"
                            v-if="datas.label && datas.label.length != 0 && datas.filetype == 12">
                            <div class="tips" style="margin:0 10px 10px 0;">{{ datas.label }}</div>
                        </div>
                    </div>
                    <div class="head_2">
                        {{ datas.title }}
                    </div>
                    <div style="margin-top: 10px;flex-wrap: wrap;">
                        <span v-html="datas.journal"></span>
                        <span v-if="datas.volume"> {{ datas.volume }},</span>
                        <span v-if="datas.lssue"> ({{ datas.lssue }}),</span>
                        <span v-if="datas.pages"> {{ datas.pages }},</span>
                        <a :href="datas.cooperate" v-if="datas.cooperate" target="_blank" rel="noopener noreferrer">
                            <span> {{ datas.cooperate }}</span>
                        </a>
                    </div>
                    <div style="margin-top: 10px;display: flex;flex-wrap: wrap;">
                        <div v-for="item in datas.partner1" @click="toMemberm4Detail(item)"
                            style="display: flex;align-items: center;margin-right: 20px;margin-bottom: 10px;">
                            <img style="width: 40px;height: 40px;border-radius: 50%;margin-right: 10px;"
                                :src="item.photo" alt="">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div class="head_3" style="margin-bottom: 20px;">
                        <div>Follow Us</div>
                        <el-popover placement="top" trigger="hover">
                            <img class="icon" style="width: 150px;" :src="datas1.footer_logo" alt="">
                            <img slot="reference" class="icon" style="margin-top: 10px;" src="@/assets/news/wx.png"
                                alt="">
                        </el-popover>
                        <a target="_blank" href="https://twitter.com/AERSS_office/status/1542597017603608576">
                            <img class="icon" src="@/assets/index/tuite.jpg" alt="">
                        </a>
                    </div>
                    <div class="head_banner_box" v-if="datas.content != '<p><br></p>'"
                        style="margin-top: 10px;border-top: 1px solid #ddd;padding: 20px 0;">
                        <div v-html="datas.content"></div>
                    </div>
                    <video v-if="datas.filetype == 12" :src="datas.videourl" style="width: 100%;" controls
                        :poster="datas.feng"></video>
                    <!-- <div class="text9" v-for="item in datas.downfile " v-if="datas.filetype!=12">
                        <div>
                            <img src="@/assets/committees/icon_pdf.png" style="width: 42px;" alt="">
                            <div class="titles9" style="">{{ item.picname }}</div>
                        </div>
                        <a :href="datas.zhuangtai != 2 ? item.picurl : null" @click="toMessage(item.picurl)"
                            target="_blank">
                            <img src="@/assets/committees/downs.png" style="width: 49px;cursor: pointer;" alt="">
                        </a>
                    </div> -->
                    <div class="head_5">
                        <div style="align-items: start; flex: 2;" @click="toDetail(datas.lastid)">
                            <div class="phone_texts" style="font-size: 20px;color: #666;">Previous</div>
                            <div class="phone_value" style="font-size: 16px;">{{ datas.lasttitle }}</div>
                        </div>
                        <div class="phone_heads5" style="align-items: center;" @click="toNewsList()">
                            <img src="@/assets/news/sige.png" style="width: 56px;" alt="">
                            <div style="margin-top: 17px;color: #005083;font-size: 16px;">Back to list</div>
                        </div>
                        <div style="align-items: flex-end;flex: 2;" @click="toDetail(datas.nextid)">
                            <div class="phone_texts" style="font-size: 20px;color: #666;">Next</div>
                            <div class="phone_value" style="font-size: 16px;">{{ datas.nexttitle }}</div>
                        </div>
                    </div>
                    <div v-if="datas.is_on == 1 && datas.filetype == 4 || datas.filetype == 11 || datas.filetype == 6">
                        <div style="margin-top: 30px;display: flex;align-items: center;flex-wrap: wrap;"
                            v-if="datas.filetype != 6">
                            <div v-for="item in yijioption"
                                style="margin-right: 40px; cursor: pointer;margin-bottom: 10px;"
                                :class="yijiactive == item.first ? 'muluactive' : 'mulu'"
                                @click="yijiactive = item.first">
                                <img style="width: 49px;" :src="item.wenimg" alt="">
                                <div>{{ item.firstname }}</div>
                            </div>
                        </div>
                        <div style="margin-top: 20px;display: flex;align-items: center;flex-wrap: wrap;"
                            v-if="datas.filetype != 6">
                            <div v-for="item in erjioption"
                                style="margin-right: 40px; cursor: pointer;margin-bottom: 10px;"
                                :class="erjiactive == item.id ? 'muluactive' : 'mulu'" @click="erjiactive = item.id">
                                <img style="width: 49px;" :src="item.wenimg" alt="">
                                <div>{{ item.secondname }}</div>
                            </div>
                        </div>
                        <div class="foot_list" v-if="wenjian && wenjian.length != 0">
                            <div class="list_item" v-for="item in wenjian">
                                <div class="times">{{ item.create_time }}</div>
                                <div class="neirong">
                                    <div class="title">{{ item.picname }}</div>
                                    <div style="display: flex;align-items: center;justify-content: center;"
                                        v-if="datas.filetype == 4 || datas.filetype == 11">
                                        <img :src="item.wenimg" alt="">
                                        <div style="margin: 0 20px;">{{ item.firstname }}</div>
                                        <div>{{ item.secondname }}</div>
                                    </div>
                                    <div style="display: flex;align-items: center;">
                                        {{ item.rongliang }}
                                        <a target="_blank" @click="toaclick(item.picurl), $download(item.id)">
                                            <img src="@/assets/news/down.png" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="foot_list" v-if="wenjian && wenjian.length != 0">
                            <div class="list_item" v-for="item in wenjian">
                                <div class="times">{{ item.create_time }}</div>
                                <div class="neirong">
                                    <div class="title" style="width: 500px;">{{ item.picname }}</div>
                                    <div style="display: flex;align-items: center;">
                                        {{ item.rongliang }}
                                        <a target="_blank" @click="toaclick(item.picurl), $download(item.id)">
                                            <img src="@/assets/news/down.png" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="list_1" v-if="datas.newspapers">
                        <div style="font-size: 35px;font-weight: bold;color: #005083;" class="phone_title">News，Paper
                        </div>
                        <div class="list_box">
                            <div class="list_item" v-for="(item, key) in datas.newspapers" :key="key">
                                <div style="font-size: 14px;">{{ datas.create_time | timestampToTime }}</div>
                                <div class="biaoti"
                                    style="display: flex;align-items: center;justify-content: space-between;font-size: 22px;line-height: 25px;margin-top: 2px;">
                                    <div>
                                        {{ item }}
                                    </div>
                                    <a :href="item" target="_blank" @click="$download(item.id)">
                                        <img style="width: 49px;margin-left: 180px;" src="@/assets/news/down.png"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="textarea_box">
                        <el-input type="textarea" placeholder="Please enter the content" v-model="textarea">
                        </el-input>
                        <div class="btn_box seeMore" @click="onPush()">
                            <span>Submit</span>
                            <img src="@/assets/index/right_icon.png" alt="">
                        </div>
                    </div>
                    <div class="liuyan_box">
                        <div class="list_boxs" v-for="(item, key) in datas.talklist" :key="key">
                            <div style="padding-right: 36px;border-right: 1px solid #eee;text-align: center;">
                                <img style="width: 83px;height: 83px;border-radius: 50%;object-fit: cover;"
                                    :src="item.photo" alt="" @click="toDetails(item)">
                                <div style="color: #005083;margin-top: 10px;font-weight: bold; white-space: nowrap;">
                                    {{
                item.name }}
                                </div>
                            </div>
                            <div style="width: 100%;margin-left: 30px;">
                                <div style="display: flex;align-items: center;color: #999;font-size: 14px;">
                                    <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png" alt="">
                                    {{ item.create_time | timestampToTime }}
                                </div>
                                <el-popover placement="top" title="" width="600" trigger="hover"
                                    :content="item.content">
                                    <div slot="reference"
                                        style="font-size: 18px;margin-top: 10px;color: #333333;line-height: 24px;padding-right: 30px;"
                                        class="pps">
                                        {{ item.content }}
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request'
export default {
    components: {
        heads,
        foots,
    },
    data() {
        return {
            textarea: null,
            datas: null,
            fuClass: '',
            fuClassType: '',
            datas1: null,
            yijioption: [],
            erjioption: [],
            wenjian: [],
            yijiactive: 0,
            erjiactive: 0,
            isLeftShow: true,
        }
    },
    methods: {
        toaclick(url) {
            if (this.datas.zhuangtai == 2) {
                this.$message.warning(this.datas.kongmsg)
            } else {
                window.open(url)
                this.$download(url, this.datas.id, 2)
            }
        },
        srcToarr(src) {
            if (src) {
                return src.split(',')
            } else {
                return []
            }
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            // 返回上一页
            this.$router.go(-1)
        },
        toPath(path) {
            this.$router.push({
                path: path
            })
        },
        toMemberm4Detail(item) {
            if (!item.id) {
                return
            }
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: item.id,
                }
            })
        },
        toDetails(item) {
            console.log(item)
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: item.talk_id,
                }
            })
        },
        getNewsList() {
            request({
                url: 'apituandetail',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id,
                    type: 2,
                    user_id1: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null,
                    user_id: this.$route.query.user_id,
                    str: JSON.parse(localStorage.getItem('userinfo')) ? '1' : null
                }
            }).then(res => {
                this.datas = res.data
                this.wenjian = res.data.downfile
                request({
                    url: 'get_detail_first',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        user_id: this.$route.query.user_id,
                        id: this.$route.query.id,
                    }
                }).then(res => {
                    if (res.data.length == 0) {
                        return
                    }
                    this.yijioption = res.data || []
                    this.yijiactive = res.data[0].first
                })
            })
        },
        toMessage(url) {
            if (this.datas.zhuangtai == 2) {
                this.$message.warning(this.datas.kongmsg)
            } else {
                this.$download(url, this.datas.id, 2)
            }
        },
        onPush() {
            // if (this.datas.zhuangtai == 2) {
            //     this.$message.warning(this.datas.back.msg)
            // }
            request({
                url: 'fujian_talk_add',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    news_id: this.$route.query.id,
                    talk_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                    content: this.textarea,
                    type: this.$route.query.types ? this.$route.query.types : 2,
                }
            }).then(res => {
                if (res.code == 200) {
                    this.textarea = null
                    this.$message.success(res.msg)
                    this.getNewsList()
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        topaths(id) {
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: id
                }
            })
        },
        toDetail(id) {
            if (id) {
                this.$router.push({
                    path: '/memberm4Detail',
                    query: {
                        id: id,
                        user_id: this.$route.query.user_id
                    }
                })
            } else {
                this.$message({
                    message: 'No more',
                    type: 'warning'
                });
            }
            this.getNewsList()
        },
        toNewsList() {
            // 返回上一页
            this.$router.go(-1)
        }
    },
    mounted() {
        this.getNewsList()
        this.fuClass = this.$route.query.fuClass
        this.fuClassType = this.$route.query.fuClassType
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.datas1 = res.data
        })
        if (this.$route.query.isLeft == 1) {
            this.isLeftShow = false
        } else {
            this.isLeftShow = true
        }
    },
    watch: {
        yijiactive(val) {
            request({
                url: 'get_memberinfo_second',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    user_id: this.$route.query.user_id,
                    cid: val,
                }
            }).then(res => {
                this.erjioption = res.data || []
                this.erjiactive = res.data[0].id
            })
        },
        erjiactive(val) {
            request({
                url: 'apituandetail',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id,
                    type: 2,
                    user_id1: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null,
                    user_id: this.$route.query.user_id,
                    str: JSON.parse(localStorage.getItem('userinfo')) ? '1' : null,
                    first: this.yijiactive,
                    second: val
                }
            }).then(res => {
                this.wenjian = res.data.downfile
                console.log(res)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.muluactive {
    color: #005083;
    font-weight: bold;
}

.mulu {
    color: #666666;
}

.foot_list {
    margin-top: 50px;

    .list_item {
        padding: 30px 0;
        border-bottom: 1px solid #E3E3E3;

        .times {
            font-size: 14px;
            font-weight: 400;
        }

        .neirong {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                width: 200px;
                word-wrap: break-word;
            }

            a {
                margin-left: 10px;
            }

            img {
                width: 49px;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .body_box {
        margin: 10px !important;
        flex-direction: column !important;
        align-items: normal !important;
    }

    .box1 {
        margin: 0 !important;
    }

    .left_box {
        display: none;
    }

    .phone_left_box {
        display: block !important;
        width: 100% !important;
        margin: 0 auto !important;
        border-radius: 10px !important;
        box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15) !important;

        .phone_head1 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px !important;
            border-bottom: 1px solid #eee !important;
            padding-bottom: 10px !important;

            .phone_infos {
                margin-right: 10px !important;

                div {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100% !important;
                }
            }
        }

        .info1 {
            margin-top: 20px !important;
            margin-left: 20px !important;
            flex-shrink: 0;

            .touxiang {
                width: 85px !important;
                height: 85px !important;
                border: 2px solid #005083 !important;
            }

            .huangguan {
                width: 42px !important;
                position: absolute !important;
                top: -17px !important;
                left: -7px !important;
            }
        }
    }

    .right_box {
        margin-left: 0px !important;

        .head_1 {
            flex-direction: column;
            align-items: flex-start;

            .head_1box {
                width: 100% !important;
                display: flex;
                justify-content: space-between !important;

                div {
                    margin-right: 0px !important;
                }
            }

            .tipsbox {
                width: 100% !important;
                margin-top: 15px !important;
                flex-wrap: wrap !important;

                .tips {
                    margin-right: 10px !important;
                    margin-bottom: 10px !important;
                    font-size: 12px !important;
                }
            }
        }

        .head_2 {
            font-size: 20px !important;
            margin-top: 0px !important;
        }

        .head_3 {
            margin-top: 10px !important;
            justify-content: flex-start !important;
            display: none !important;
        }

        .head_banner_box {
            margin-top: 10px !important;
            overflow: hidden;
            width: 100%;
            padding-bottom: 10px !important;


        }

        .head_5 {
            margin-top: 20px !important;

            .phone_texts {
                font-size: 18px !important;
            }

            .phone_value {
                font-size: 12px !important;
            }

            .phone_heads5 {
                div {
                    font-size: 16px !important;
                }
            }
        }

        .list_1 {
            margin-top: 40px !important;

            .phone_title {
                font-size: 24px !important;
                margin-bottom: 20px !important;
            }

            .list_item {
                padding: 10px 0 !important;

                .biaoti {
                    div {
                        font-size: 14px !important;
                        line-height: normal
                    }

                    img {
                        width: 39px !important;
                        margin-left: 0 !important;
                    }
                }
            }
        }

        .textarea_box {
            margin-top: 20px !important;
            padding: 10px !important;

            .btn_box {
                margin-top: 30px !important;
                font-size: 14px !important;
                width: 80px !important;
                display: flex;
                padding: 5px 10px !important;
                justify-content: space-between;

                img {
                    margin-left: 0 !important;
                }
            }
        }

        .liuyan_box {
            margin-top: 20px !important;
            margin-left: 0 !important;

            .list_boxs {
                background-image: none !important;
                padding: 10px !important;
                box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15) !important;
                width: auto !important;
                margin-bottom: 10px !important;
                border-radius: 10px !important;

                .lefts {
                    padding-right: 20px !important;

                    img {
                        background-color: #000000;
                        width: 50px !important;
                        height: 50px !important;
                    }

                    div {
                        margin-top: 5px !important;
                        font-size: 16px !important;
                    }
                }

                .rights {
                    width: auto !important;
                    flex: 1 !important;
                    margin-left: 20px !important;

                    .phone_content {
                        font-size: 15px !important;
                    }
                }
            }
        }
    }

}

.text9 {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titles9 {
        font-size: 20px;
        font-weight: bold;
        color: #005083;
        margin-left: 20px;
        width: 85%;
        // 超出换行文字
        word-break: break-all;
    }

    div {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }
}

.pps {
    // 超出三行省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.content {
    width: 100%;
    background: #F7FAFB;


    .box {
        position: relative;
        overflow: hidden;
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;
        margin-top: 110px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .left_box {
                width: 317px;
                background: #FEFFFF;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .info1 {
                    position: relative;
                    margin-top: 52px;

                    .touxiang {
                        width: 170px;
                        height: 170px;
                        border-radius: 50%;
                        border: 2px solid #005083;
                        object-fit: cover;
                        cursor: pointer;
                    }

                    .huangguan {
                        width: 84px;
                        position: absolute;
                        top: -35px;
                        left: -15px;
                    }
                }
            }


            .right_box {
                flex: 1;
                padding: 20px;
                margin-left: 80px;

                .head_1 {
                    font-size: 16px;
                    font-weight: 400;
                    color: #666666;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .tips {
                        padding: 4px 20px;
                        background: rgba($color: #000000, $alpha: 0.1);
                        border-radius: 20px;
                        color: #333333;
                        cursor: pointer;
                    }
                }

                .head_2 {
                    font-size: 36px;
                    font-weight: 500;
                    color: #000000;
                    margin-top: 30px;
                }

                .head_3 {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                    justify-content: flex-end;

                    img {
                        width: 24px;
                        margin-left: 29px;
                    }
                }

                .head_banner_box {
                    margin-top: 56px;
                    padding-bottom: 56px;
                    border-bottom: 1px solid rgba($color: #333333, $alpha: 0.1);

                    ::v-deep img {
                        max-width: 100% !important;

                        &:hover {
                            transform: scale(1);
                        }
                    }

                    .news_banner {
                        height: 443px;
                        width: 100%;
                    }

                    .texts {
                        margin-top: 60px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }

                .head_5 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                }

                .head_5>div {
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    color: #333333;
                    font-weight: bold;
                    cursor: pointer;

                }

                .list_1 {
                    margin-top: 77px;

                    .list_box {
                        .list_item {
                            padding: 24px 0;
                            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
                        }

                    }
                }

                .textarea_box {
                    margin-top: 70px;
                    display: flex;
                    flex-direction: column;
                    background: #F2F2F2;
                    border: 1px solid #CFCFCF;
                    border-radius: 10px;
                    padding: 30px;

                    ::v-deep .el-textarea__inner {
                        font-family: 'CustomFont1' !important;
                    }

                    .btn_box {
                        width: 110px;
                        margin-top: 95px;
                        padding: 8px 19px;
                        border: 2px solid #005083;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                        cursor: pointer;

                        img {
                            width: 23px;
                            margin-left: 30px;
                        }
                    }
                }

                .liuyan_box {
                    margin-top: 35px;
                    margin-left: -30px;

                    .list_boxs {
                        width: 97%;
                        padding: 50px 0 0 50px;
                        background-image: url('@/assets/news/listbg.png');
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        padding-bottom: 70px;
                    }
                }
            }
        }
    }
}
</style>