<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="5"></banner>
            <heads></heads>
            <div class="title">Events</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Events
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    <span v-for="item in leftMenuData " v-if="item.id == activeIndex">{{ item.name }}</span>
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head left_head_active">Events</div>
                    <div class="left_item_box">
                        <div class="item" style="border: none;" @click="toComm1">
                            <div style="margin-left: 20px;">
                                Annual Meeting
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div class="item" style="border: none;" @click="toComm3">
                            <div style="margin-left: 20px;">
                                大气环境遥感与协同分析会议
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div class="item" style="border: none;border-radius: 20px;">
                            <div style="margin-left: 20px;">
                                AERSS优博奖
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div v-for="(item, key) in leftMenuData">
                            <div class="item" style="border: none;" :class="item.id == activeIndex ? 'active' : ''"
                                @click="toComm6(item)">
                                <div style="margin-left: 30px;">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box">
                    <div class="text10" style="color: #7bb3da;">
                        <div style="color: #7bb3da;font-size: 30px;font-weight: 400 !important;margin-bottom: 10px;">{{
                datas.chineseexcellent.futitle }}</div>
                        {{ datas.chineseexcellent.msg }}
                    </div>
                    <div v-html="datas.chineseexcellent.content"></div>
                    <div class="text11" v-if="isVote">
                        <div class="items11" v-for="item in datas.votelist">
                            <div class="title11_box">
                                <div style="font-weight: bold;">{{ item.remark }} {{ item.writer }}</div>
                                <div style="display: flex;align-items: center;">
                                    <a :href="islogin ? item.photo : null" target="_blank"
                                        @click="$download(item.photo, item.id, 4), toLogin(item)">
                                        <img src="@/assets/committees/downs.png"
                                            style="width: 26px;margin-right: 10px;cursor: pointer;" alt="">
                                    </a>
                                    <div class="bq" @click="toVito(item)" style="cursor: pointer;">
                                        {{ item.vito }}
                                    </div>
                                </div>
                            </div>
                            <div class="contens11">
                                {{ item.title }}
                            </div>
                            <div style="display: flex;align-items: center;">
                                <!-- <div class="shuxian"></div> -->
                                <img class="touxiang" @click="toDetail(item.user_id)" :src="item.layimg" alt="">
                                <div class="name" @click="toDetail(item.user_id)">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="dialogVisible" width="40%" :show-close="false">
            <div style="color: red;margin: 10px">
                Please upload your PhD photo (format :JPG, PNG)
            </div>
            <div style="margin-top: 30px;">
                <label for="fileInput" style="cursor: pointer;">
                    <div class="addupdate">
                        <img src="@/assets/memberm/addUpdate.png" alt="">
                    </div>
                </label>
                <input type="file" style="display: none;" id="fileInput" ref="fileInput" @change="handleFileUpload" />
                <a v-if="imgData" :href="imgData.picurl" target="_blank" rel="noopener noreferrer">
                    <div style="margin: 30px 0;margin-bottom: 0;">{{ imgData.picname }}</div>
                </a>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="toDradd">Confirm</el-button>
            </span>
        </el-dialog>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
import swiper from '@/components/swiper.vue'
import { MessageBox } from 'element-ui'; // 引入 Element UI 的 Message 组件
import { set } from 'vue';
export default {
    components: {
        heads,
        foots,
        banner,
        swiper
    },
    data() {
        return {
            leftMenuData: [],
            datas: null,
            activeIndex: 0,
            dialogVisible: false,
            imgData: null,
            islogin: false,
            isVote: true,
        }
    },
    mounted() {
        this.getData()
        request({
            url: 'init_data',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            let arr = []
            res.data.secondvote.forEach((item, key) => {
                arr.push({
                    name: item.name,
                    id: item.id
                })
            })
            this.activeIndex = arr[0].id
            this.leftMenuData = arr
            if (res.data.is_vote == 1) {
                this.isVote = true
            } else {
                this.isVote = false
            }
        })
        if (localStorage.getItem('userinfo')) {
            this.islogin = true
        }
    },
    methods: {
        toDradd() {
            request({
                url: 'dr_add',
                method: 'post',
                data: {
                    photo: this.imgData.nohttp,
                    user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                },
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.dialogVisible = false
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        handleFileUpload(event) {
            this.file = event.target.files[0]; // 获取用户选择的文件
            if (!this.file) {
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            request({
                url: 'upload',
                method: 'post',
                data: {
                    file: this.file,
                    type: '1'
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.imgData = res.data
                }
                loading.close();
            }).catch(() => {
                loading.close();
            })
        },
        toDetail(id) {
            if (!id) return
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: id,
                    fuClass: this.leftMenuData[this.activeIndex].name
                }
            })
        },
        toComm1() {
            this.$router.push({
                path: '/AnnualMeeting2024',
            })
        },
        toComm3() {
            this.$router.push({
                path: '/Committees3',
            })
        },
        toComm4(id) {
            if (!id) return;
            this.$router.push({
                path: '/Committees4',
                query: {
                    id: id
                }
            })
        },
        toComm6(item) {
            if (!item.id) return;
            if (item.id == 99) return
            if (item.id != 99) {
                this.$router.push({
                    path: '/Committees6',
                    query:{
                        id: item.id
                    }
                })
            }
        },
        getData() {
            request({
                url: 'api_aboutdata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    user_id: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null
                }
            }).then(res => {
                this.datas = res.data
                if (this.datas.sponsorshiplast) {
                    this.datas.sponsorshiplast.content = this.datas.sponsorshiplast.content.replace(/<img/g, '<img title="" style="width: 100%"')
                }
            })
        },
        toLogin(item) {
            if (!localStorage.getItem('userinfo')) {
                MessageBox.confirm('Do you need to login to access the current page and continue', 'Tips', {
                    showCancelButton: true,
                    confirmButtonText: 'confirm',
                    cancelButtonText: 'cancel',
                    type: 'warning'
                }).then(() => {
                    this.$router.push('/login')
                }).catch(() => {

                });
            } else {
                if (item.votestatus == 1) {
                    this.$message.warning(item.votemsg)
                    this.islogin = false
                    return
                }
                request({
                    url: 'get_vote_status',
                    method: 'post',
                    data: {
                        vote_id: item.id,
                        user_id: JSON.parse(localStorage.getItem('userinfo')).data.id
                    },
                }).then(res => {
                    this.getData()
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        },
        toVito(item) {
            if (!localStorage.getItem('userinfo')) {
                MessageBox.confirm('Do you need to login to access the current page and continue', 'Tips', {
                    showCancelButton: true,
                    confirmButtonText: 'confirm',
                    cancelButtonText: 'cancel',
                    type: 'warning'
                }).then(() => {
                    this.$router.push('/login')
                }).catch(() => {

                });
                return
            }

            if (item.is_tou != 1) {
                this.$message.warning('Please download and read the dissertation before voting')
                return
            }
            request({
                url: 'voteact',
                method: 'post',
                data: {
                    id: item.id,
                    user_id: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null
                },
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.warning(res.msg)
                }
                if (res.data == 1) {
                    setTimeout(() => {
                        this.dialogVisible = true
                    }, 1000)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.addupdate {
    width: 100%;
    height: 160px;
    background: #FFFFFF;
    box-shadow: 5px 22px 21px 5px rgba(0, 66, 124, 0.1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 57px;
        height: 57px;
    }
}

@media screen and (max-width: 1025px) {
    div {
        word-break: break-all;
    }

    .body_box {
        margin-top: 0 !important;
    }


}

div {
    font-weight: 100 !important;
}

.content {
    width: 100%;
    background: #fff;

    a {
        // 清除a标签的下划线
        text-decoration: none;
    }

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 60px;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 30px;
            display: flex;
            align-items: flex-start;

            .left_box {
                border-radius: 0px 0px 20px 20px;
                box-shadow: 0px 0px 10px #E5E5E5;

                .left_head {
                    padding: 0 20px;
                    width: 317px;
                    height: 115px;
                    font-size: 25px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #E5E5E5;
                    cursor: pointer;
                    text-align: center;
                }

                .left_head_active {
                    background: #005083;
                    color: #FFFFFF;
                    border-radius: 20px 20px 0px 0px;
                }

                .left_item_box {
                    .item {
                        width: 100%;
                        min-height: 60px;
                        display: flex;
                        background-size: 100% 100%;
                        align-items: center;
                        justify-content: space-between;
                        color: #333;
                        cursor: pointer;
                        border-top: 1px solid #eee;

                        img {
                            width: 16px;
                            margin-right: 20px;
                        }

                        .zi_class {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                            height: 60px;
                        }
                    }

                    .active {
                        background-image: url("@/assets/news/left_bg.png");
                        color: #005083;
                    }
                }

            }

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;
                margin-left: 80px;

                ::v-deep img {
                    max-width: 100%;

                    &:hover {
                        transform: scale(1) !important;
                    }
                }

                .text10 {
                    font-size: 18px;
                    color: #005083;
                    margin-top: 30px;
                }

                .text11 {
                    .items11 {
                        background: linear-gradient(180deg, #FFFFFF 0%, #F1F5F8 100%);
                        border-radius: 20px;
                        border: 1px solid #E5E5E5;
                        padding: 10px 30px;
                        margin-top: 20px;

                        .title11_box {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: #7bb3da;
                            font-size: 16px;

                            .bq {
                                width: 83px;
                                height: 23px;
                                background: rgba($color: #000000, $alpha: .1);
                                border-radius: 12px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 14px;
                                color: #333333;
                            }
                        }

                        .contens11 {
                            font-size: 20px;
                            color: #000000;
                            margin: 16px 0 10px 0;
                        }

                        .shuxian {
                            width: 1px;
                            height: 23px;
                            background: #E5E5E5;
                            margin: 0 20px;
                        }

                        .touxiang {
                            width: 47px;
                            height: 47px;
                            border-radius: 50%;
                            margin-right: 10px;
                            object-fit: cover;
                        }

                        .name {
                            color: #333333;
                            font-size: 14px;
                        }
                    }
                }

            }
        }
    }
}
</style>