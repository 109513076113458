const pathArr = [
    {
        name: 'Home',
        url: '/',
    },
    {
        name: 'Organization',
        url: '/Constitution',
        children: [
            {
                name:'Constitution',
                url:'/Constitution'
            },
            {
                name: 'Structure',
                url: '/Introduction'
            },
            {
                name: 'AERSS Leadership',
                url: '/Leadership'
            },
            {
                name: 'Council',
                url: '/Council'
            },
            {
                name: 'Scientific Steering Committee',
                url: '/Scientific'
            },
           
            {
                name: 'Working Groups',
                url: '/WorkingGroups'
            },
            { 
                name: 'ECPC',
                url: '/Ecpc'
            },
            {
                name: 'Secretariat',
                url: '/Secretariat'
            },
            {
                name: 'Milestone',
                url: '/Milestone'
            },
            {
                name: 'Contact Us',
                url: '/Contact'
            },
        ]
    },
    {
        name: 'Membership',
        url: '/MemberType',
        children: [
            {
                name: 'Membership',
                md: 'Membership',
                url: '/MemberType',
            },
            {
                name: 'Member Type',
                md: 'MemberType',
                url: '/MemberType',
            },
            {
                name: 'Benefits',
                md: 'Benefits',
                url: '/MemberType',

            },
            // {
            //     name: 'Achievements',
            //     md: 'Achievements',
            //     url: '/MemberType',

            // },
            {
                name: 'Active Members',
                md: 'Election',
                url: '/MemberType',

            },
        ]
    },
    {
        name: 'News',
        url: '/news',
    },
    {
        name: 'Events',
        url: '/AnnualMeeting2024',
        children: [
            {
                name: 'Annual Meeting',
                url: '/AnnualMeeting2024'
            },
            {
                name: '大气环境遥感与协同分析会议',
                url: '/Committees3'
            },
            {
                name: 'AERSS优博奖',
                url: '/Committees5'
            },
        ]
    },
    {
        name: 'Projects',
        url: '/Projects',
    },
    {
        name: 'Resources',
        url: '/Publications',
        children: [{
            name: 'Publications',
            url: '/Publications'
        },
        {
            name: 'Presentations',
        },
        {
            name: 'Videos',
            url: '/Videos'
        },
        {
            name: 'Data',
            url: '/Data',
        },
        {
            name: 'Others',
        },
        ]
    },
]
module.exports = pathArr;
