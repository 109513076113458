<template>
    <div class="content">
        <div class="box">
            <heads :isUpload="true"></heads>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    Personal homepage
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Material Library
                </div>
            </div>
            <div class="body_box">
                <leftMenu :datas='leftMenuData' :title="'Personal homepage'"></leftMenu>
                <div class="right_box">
                    <div class="btn_box">
                        <div class="items" :class="biaoqianValue == null ? 'active' : ''"
                            @click="biaoqianValue = null, getDatas()">
                            <div>
                                All
                            </div>
                        </div>
                        <div class="items" v-for="item in shaixuan1Arr"
                            :class="biaoqianValue == item.id ? 'active' : ''"
                            @click="biaoqianValue = item.id, getDatas()">
                            <div>
                                {{ item.value }}
                            </div>
                        </div>
                        <img src="@/assets/memberm/dian.png" v-if="shaixuan1Arr && shaixuan1Arr.length != 0" alt="">
                    </div>
                    <div class="btn_box" style="border: none;margin-top: 30px;">
                        <div class="items" :class="biaoqian2Value == null ? 'active' : ''"
                            @click="biaoqian2Value = null, getDatas()">
                            <div>
                                All
                            </div>
                        </div>
                        <div class="items" v-for="item in shaixuan2arr" v-if="item.status == 1"
                            :class="biaoqian2Value == item.type ? 'active' : ''"
                            @click="biaoqian2Value = item.type, getDatas()">
                            <div>
                                {{ item.value }}
                            </div>
                        </div>
                    </div>
                    <div class="shaixuan">
                        <div style="display: flex;align-items: center;" class="phone_onebox">
                            <div style="margin-right: 30px;" class="onediv">
                                <el-select v-model="downvalue" @change="getDatas" placeholder="Download" clearable>
                                    <el-option v-for="item in DownloadArr" :key="item.id" :label="item.value"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-select v-model="filevalue" @change="getDatas" placeholder="Filter by time"
                                    clearable>
                                    <el-option v-for="item in FilterArr" :key="item.id" :label="item.value"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="sousuo">
                            <el-input v-model="searchvalue" placeholder="Search in material library..." clearable
                                @change="getDatas"></el-input>
                            <img src="@/assets/Resources/search.png" alt="" @click="getDatas">
                        </div>
                    </div>
                    <div class="tiaomu_box">
                        <div class="tiaomu_item" v-for="item in datas" @click="toDetail(item)">
                            <div class="tiaomu_right_box">
                                <div class="tiaomu_right_box_heads">
                                    <div style="display: flex;align-items: center;">
                                        <div style="color: #005083;font-size: 16px;margin-right: 40px;"
                                            v-if="item.vivo && item.vivo != ''">{{ item.vivo }}
                                        </div>
                                        <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                            <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                                alt="">
                                            {{ item.create_time | timestampToTime }}
                                        </div>
                                    </div>

                                    <div style="display: flex;align-items: center;justify-content: center;">
                                        <div style="" :style="{ color: item.color }">
                                            {{ item.zhuanmsg }}
                                        </div>
                                        <div style="margin: 0 5px;color: #005083;opacity: .6;">/</div>
                                        <div class="btns" @click.stop="todelete(item)">Delete</div>
                                        <div style="margin: 0 5px;color: #005083;opacity: .6;"
                                            v-if="item.update_status == 2">/
                                        </div>
                                        <div class="btns" @click.stop="toMemberm3(item)" v-if="item.update_status == 2">
                                            Modify
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style="margin-top: 24px;font-weight: 500;color: #000000;font-size: 20px;width: 760px;">
                                    {{ item.title }}
                                </div>
                                <video v-if="item.filetype == 3" v-for="items in srcToarr(item.videourl)" :src="items"
                                    controls style="width: 100%;margin-top: 10px;">
                                    <source src="movie.mp4" type="video/mp4">
                                </video>
                                <div v-if="item.remark"
                                    style="margin-top: 24px;font-weight: 400;color: #666666;font-size: 16px;">
                                    {{ item.remark }}
                                </div>
                                <div class="tips_box" v-if="item.labels">
                                    <div v-for="items in item.labels">{{ items }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="fenye">
                            <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                        </div>
                    </div>
                    <div class="phone_tiaomu_box tiaomu_box" style="display: none;" v-if="datas && datas.length != 0">
                        <div class="tiaomu_item" v-for="item in datas" @click="toDetail(item)">
                            <div class="tiaomu_right_box">
                                <div class="tiaomu_right_box_heads">
                                    <div style="display: flex;align-items: center;">
                                        <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                            <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                                alt="">
                                            {{ item.create_time | timestampToTime }}
                                        </div>
                                    </div>
                                    <div style="display: flex;align-items: center;justify-content: center;">
                                        <div :style="{ color: item.color }">
                                            {{ item.zhuanmsg }}
                                        </div>
                                        <div style="margin: 0 5px;color: #005083;opacity: .6;">/</div>
                                        <div class="btns" @click.stop="todelete(item)">Delete</div>
                                        <div style="margin: 0 5px;color: #005083;opacity: .6;"
                                            v-if="item.update_status == 2">/
                                        </div>
                                        <div class="btns" @click.stop="toMemberm3(item)" v-if="item.update_status == 2">
                                            Modify</div>
                                    </div>
                                </div>
                                <div style="margin-top: 10px;font-weight: 500;color: #000000;font-size: 20px;">
                                    {{ item.title }}
                                </div>
                                <video v-if="item.filetype == 3" v-for="items in srcToarr(item.videourl)" :src="items"
                                    controls style="width: 100%;margin-top: 10px;">
                                    <source src="movie.mp4" type="video/mp4">
                                </video>
                                <div style="margin-top: 10px;font-weight: 400;color: #666666;font-size: 16px;">
                                    {{ item.remark }}
                                </div>
                                <div class="tips_box" style="margin-top: 10px;">
                                    <div v-for="items in item.labels">{{ items }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="fenye">
                            <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
import pagination from '@/components/pagination.vue'
export default {
    components: {
        heads,
        foots,
        leftMenu,
        pagination
    },
    data() {
        return {
            leftMenuData: [{
                name: 'Profile',
                path: '/memberm'
            },
            {
                name: 'Upload',
                path: '/memberm3'
            },
            {
                name: 'Material Library',
                path: '/memberm2'
            },
            {
                name: 'File directory',
                path: '/contentsPage'
            },
            ],
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            datas: null,
            shaixuan1Arr: [],
            shaixuan2arr: [
            ],
            DownloadArr: [],
            FilterArr: [],
            downvalue: null,
            filevalue: null,
            searchvalue: null,
            biaoqianValue: null,
            biaoqian2Value: null
        }
    },
    mounted() {
        this.getDatas()
        request({
            url: 'api_type',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            console.log(res)
            this.shaixuan2arr = res.data
        })
    },
    methods: {
        toDetail(item) {
            this.$router.push({
                path: '/memberm4Detail',
                query: {
                    id: item.id,
                    user_id: item.user_id
                }
            })
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/memberm'
            })
        },
        getDatas() {
            this.datas = []
            request({
                url: 'apiselfmemberinfo',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                    page: this.pageData.current_page,
                    biaoqian: this.biaoqianValue,
                    filetype: this.biaoqian2Value,
                    keyword: this.searchvalue,
                    download: this.downvalue,
                    order: this.filevalue
                }
            }).then(res => {
                this.datas = res.data.data
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
                this.shaixuan1Arr = res.other.biaoqian
                this.FilterArr = res.other.paixu
                this.DownloadArr = res.other.download
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
        todelete(item) {
            // 是否确认删除
            this.$confirm('Are you sure to delete this data?', '', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                request({
                    url: 'del_memberinfodata',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        id: item.id
                    }
                }).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                        this.getDatas()
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                        this.getDatas()
                    }
                })
            })

        },
        toMemberm3(item) {
            // if (item.submissionlocation == 1 || item.submissionlocation == 2) {
            //     this.$message.error('The data has been submitted and cannot be modified')
            //     return
            // }
            this.$router.push({
                path: '/memberm3',
                query: {
                    id: item.id
                }
            })
        },
        srcToarr(src) {
            if (src) {
                return src.split(',')
            } else {
                return []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .box1 {
        margin-top: 0 !important;

        .body_box {
            margin: 0 !important;
        }

        .right_box {
            display: flex;
            flex-direction: column;
            margin: 0 15px !important;

            .btn_box {
                padding-bottom: 10px !important;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px !important;

                .items {
                    padding: 5px 15px !important;
                    height: auto !important;
                    font-size: 14px !important;
                    margin-bottom: 10px !important;
                    margin-right: 20px !important;
                }
            }

            .shaixuan {
                flex-wrap: wrap !important;
                margin-bottom: 0px !important;

                .phone_onebox {
                    width: 100%;
                    justify-content: space-between !important;
                    margin: 0 !important;
                }

                .onediv {
                    margin-right: 20px !important;
                }

                .sousuo {
                    margin-top: 20px !important;
                    width: 100% !important;
                    margin-right: 0 !important;
                }
            }

            .tiaomu_right_box {
                margin: 0 !important;
                padding: 10px 0 !important;
            }

            .tiaomu_item {
                margin: 10px 0 !important;
            }

            .tiaomu_box {
                display: none;
            }

            .phone_tiaomu_box {
                display: block !important;
                margin: 0 !important;
                padding: 0 !important;

                div {
                    font-size: 14px !important;
                }
            }
        }
    }
}

.content {
    width: 100%;
    background: #F7FAFB;

    .shaixuan {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        justify-content: space-between;

        ::v-deep .el-input__inner {
            border-radius: 20px;
            background: #FFFFFF;
            border: none;
            color: #005083;
            font-size: 16px;
        }

        .sousuo {
            display: flex;
            align-items: center;
            background-color: #FFFFFF;
            padding-right: 10px;
            border-radius: 20px;
            border: 1px solid #005083;

            img {
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    }

    .shaixuan>div {
        margin-right: 30px;
    }

    .box {
        position: relative;
        overflow: hidden;
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-top: 110px;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;


            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 50px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                margin-left: 80px;

                .btn_box {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #E5E5E5;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    img {
                        width: 53px;
                        cursor: pointer;
                    }

                    .items {
                        margin-bottom: 10px !important;
                        cursor: pointer;
                        padding: 0 35px;
                        height: 40px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        background: rgba(255, 255, 255, 0.85);
                        border: 2px solid #FFFFFF;
                        border-radius: 30px;
                        max-width: 142px;
                        margin-right: 10px;

                        div {
                            text-align: center;
                        }
                    }

                    .active {
                        background: #005083;
                        color: #FFFFFF;
                    }
                }

                .down_box {
                    cursor: pointer;
                    background: rgba(0, 80, 131, 0.1);
                    border: 1px solid #00518319;
                    border-radius: 28px;
                    min-width: 220px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 22px 0 30px;

                    img {
                        width: 12px;
                    }

                    .inp {
                        border: none;
                        background: none;
                        height: 100%;
                        padding: 0 20px;
                        // 清除默认样式
                        outline: none;
                        box-shadow: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        font-size: 16px;
                    }

                    .icon_boxa {
                        cursor: pointer;
                        width: 90px;
                        height: 100%;
                        background: #005083;
                        border-radius: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 18px;
                        }
                    }
                }

                .tiaomu_box {
                    margin-top: 60px;


                    .tiaomu_item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 40px;
                        cursor: pointer;

                        .tiapmu_item_img {
                            width: 342px;
                            height: 227px;
                            border-radius: 20px;
                        }

                        .tiaomu_right_box {
                            flex: 1;
                            padding-bottom: 40px;
                            border-bottom: 1px solid #E5E5E5;

                            .tiaomu_right_box_heads {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .btns {
                                    color: #005083;
                                    font-size: 16px;
                                    opacity: .6;
                                }

                                .btns:hover {
                                    text-decoration: underline;
                                    opacity: 1;
                                }
                            }

                            .tips_box {
                                display: flex;
                                align-items: center;
                                margin-top: 35px;

                                div {
                                    margin-right: 30px;
                                    padding: 4px 10px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #333333;
                                    background: rgba($color: #000000, $alpha: .1);
                                    border-radius: 12px;
                                }
                            }
                        }
                    }

                    .fenye {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        ::v-deep .el-pagination {
                            display: flex;
                        }

                        ::v-deep .el-pager li.active {
                            color: #fff;
                            background-color: #005083;
                        }

                        ::v-deep .el-pager li {
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid #E3E3E3;
                            line-height: 40px;
                            font-size: 16px;
                            margin-right: 10px;
                            font-weight: 100;
                            color: #999999;
                        }

                        ::v-deep .btn-prev {
                            width: 90px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            padding: 0;
                            margin: 0;
                            margin-right: 10px;
                        }

                        ::v-deep .btn-next {
                            width: 90px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            padding: 0;
                            margin: 0;
                        }

                    }
                }
            }
        }
    }

}
</style>