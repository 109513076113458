import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import login from '../views/login.vue'
import register from '../views/register.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import news from '../views/news.vue'
import newsDetail from '../views/newsDetail.vue'
import Introduction from '../views/Introduction.vue'
import Scientific from '../views/Scientific.vue'
import Council from '../views/Council.vue'
import Ecpc from '../views/Ecpc.vue'
import Secretariat from '../views/Secretariat.vue'
import Contact from '../views/Contact.vue'
import MemberType from '../views/MemberType.vue'
import WorkingGroups from '../views/WorkingGroups.vue'
import Milestone from '../views/Milestone.vue'
import Videos from '../views/Videos.vue'
import Data from '../views/Data.vue'
import memberm from '../views/memberm.vue'
import memberm2 from '../views/memberm2.vue'
import memberm3 from '../views/memberm3.vue'
import memberm4 from '../views/memberm4.vue'
import contentsPage from '../views/contentsPage.vue'
import memberm4Detail from '../views/memberm4Detail.vue'
import Committees from '../views/Committees.vue'
import Committees1 from '../views/Committees1.vue'
import Committees2 from '../views/Committees2.vue'
import Committees3 from '../views/Committees3.vue'
import Committees4 from '../views/Committees4.vue'
import Committees5 from '../views/Committees5.vue'
import Committees6 from '../views/Committees6.vue'

import Constitution from '../views/Constitution.vue'

import Publications from '../views/Publications.vue'
import AtmosphericEnvironment from '../views/AtmosphericEnvironment.vue'
import Projects from '../views/Projects.vue'
import tuanDetail from '../views/tuanDetail.vue'
import searchList from '../views/searchList.vue'
import disclaimer from '../views/disclaimer.vue'
import Leadership from '../views/Leadership.vue'
import request from '@/utils/request';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  }, {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/Introduction',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/Scientific',
    name: 'Scientific',
    component: Scientific
  },
  {
    path: '/Ecpc',
    name: 'Ecpc',
    component: Ecpc
  },
  {
    path: '/Council',
    name: 'Council',
    component: Council
  },
  {
    path: '/Secretariat',
    name: 'Secretariat',
    component: Secretariat
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/MemberType',
    name: 'MemberType',
    component: MemberType
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail
  },
  {
    path: '/WorkingGroups',
    name: 'WorkingGroups',
    component: WorkingGroups
  },
  {
    path: '/Milestone',
    name: 'Milestone',
    component: Milestone
  },
  {
    path: '/Videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/Data',
    name: 'Data',
    component: Data
  },
  {
    path: '/memberm',
    name: 'memberm',
    component: memberm
  },
  {
    path: '/memberm2',
    name: 'memberm2',
    component: memberm2
  },
  {
    path: '/memberm3',
    name: 'memberm3',
    component: memberm3
  },
  {
    path: '/memberm4',
    name: 'memberm4',
    component: memberm4
  },
  {
    path: '/contentsPage',
    name: 'contentsPage',
    component: contentsPage
  },
  {
    path: '/memberm4Detail',
    name: 'memberm4Detail',
    component: memberm4Detail
  },
  {
    path: '/Committees',
    name: 'Committees',
    component: Committees
  },
  {
    path: '/Committees1',
    name: 'Committees1',
    component: Committees1
  },
  {
    path: '/Committees2',
    name: 'Committees2',
    component: Committees2
  },
  {
    path: '/Committees3',
    name: 'Committees3',
    component: Committees3
  },
  {
    path: '/Committees4',
    name: 'Committees4',
    component: Committees4
  },
  {
    path: '/Committees5',
    name: 'Committees5',
    component: Committees5
  },
  {
    path: '/Committees6',
    name: 'Committees6',
    component: Committees6
  },

  {
    path: '/Constitution',
    name: 'Constitution',
    component: Constitution
  },
  {
    path: '/Publications',
    name: 'Publications',
    component: Publications
  },
  {
    path: '/AtmosphericEnvironment',
    name: 'AtmosphericEnvironment',
    component: AtmosphericEnvironment
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/tuanDetail',
    name: 'tuanDetail',
    component: tuanDetail
  },
  {
    path: '/searchList',
    name: 'searchList',
    component: searchList
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: disclaimer
  },
  {
    path: '/Leadership',
    name: 'Leadership',
    component: Leadership
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]



const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  console.log(to, from)
  if (localStorage.getItem('userinfo')) {
    if (to.path == '/login' || to.path == '/register' || to.path == '/forgetPassword') {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
  request({
    url: 'set_point',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
    }
  }).then(res => { })

});
export default router
