<template>
    <div class="content">
        <div class="box">
            <banner :types="7"></banner>
            <heads></heads>
            <div class="title">Resources</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Resources
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;" v-for="item in leftMenuData" v-if="item.active">
                    {{ item.name }}
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head left_head_active">Resources</div>
                    <div class="left_item_box">
                        <div v-for="(item, key) in leftMenuData">
                            <div @click="toPath(item.path)" class="item" :class="item.active ? 'active' : ''">
                                <div style="margin-left: 30px;">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box">
                    <div class="shaixuan">
                        <div v-if="type != 12">
                            <el-select @change="getDatas" v-model="downvalue" placeholder="Download" clearable>
                                <el-option v-for="item in DownloadArr" :key="item.id" :label="item.value"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <el-select @change="getDatas" v-model="filevalue" placeholder="Filter by time" clearable>
                                <el-option v-for="item in FilterArr" :key="item.id" :label="item.value"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="sousuo">
                            <el-input v-model="searchvalue" placeholder="Search for resources..." @change="getDatas"
                                clearable></el-input>
                            <img src="@/assets/Resources/search.png" alt="" @click="getDatas">
                        </div>
                    </div>
                    <div class="phone_shaixuan shaixuan" style="display: none;">
                        <div class="sousuo">
                            <el-input v-model="searchvalue" placeholder="Search for resources..." @change="getDatas"
                                clearable></el-input>
                            <img src="@/assets/Resources/search.png" alt="" @click="getDatas">
                        </div>
                        <div v-if="type != 12">
                            <el-select @change="getDatas" v-model="downvalue" placeholder="Download" clearable>
                                <el-option v-for="item in DownloadArr" :key="item.id" :label="item.value"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <el-select @change="getDatas" v-model="filevalue" placeholder="Filter by time" clearable>
                                <el-option v-for="item in FilterArr" :key="item.id" :label="item.value"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>

                    </div>
                    <div class="item_box" v-if="type != 12">
                        <div class="items" v-for="item in datas"
                            @click="toDetail(item.id, item.user_id, item.cooperate)">
                            <div class="items_content">
                                <div class="text1">
                                    <div style="color: #999;display: flex;align-items: center;">
                                        <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                            alt="">
                                        {{ item.create_time | timestampToTime }}
                                    </div>
                                </div>
                                <el-popover placement="top-start" title="" width="550" trigger="hover"
                                    :content="item.title">
                                    <div class="text2" slot="reference">
                                        {{ item.title }}
                                    </div>
                                </el-popover>
                                <div class="text3">
                                    {{ item.remark }}
                                </div>
                                <div class="foots4">
                                    <div class="tip_box">
                                        <div v-for="items in item.biaoqian">{{ item }}</div>
                                    </div>
                                    <a :href="item.jumptype == 1 ? item.photo : null" target="_blank"
                                        @click.stop="item.jumptype == 1 ? $download(item.photo, item.id, 2) : toDetail(item.id, item.user_id, item.cooperate)"
                                        rel="noopener noreferrer">
                                        <img class="down" src="@/assets/news/down.png" alt="">
                                    </a>
                                    <div class="shu"></div>
                                    <div class="user_box">
                                        <div class="user_item" v-if="key < 3" v-for="(items, key) in item.partner"
                                            @click.stop="toDetail1(items.id, items.is_user)">
                                            <img :src="items.photo" alt="">
                                            <div>{{ items.name }}</div>
                                        </div>
                                    </div>
                                    <img class="dian" v-if="item.partner.length > 3" @click.stop="toZhankai(item)"
                                        src="@/assets/memberm/dian.png" alt="">
                                </div>
                            </div>
                            <div class="items_content" v-if="item.zhankaiflag" style="margin-top: -20px;">
                                <div class="foots4" style="margin-top: 0;">
                                    <div class="user_box" style="width: 100%;flex-wrap: wrap;margin-left: 65px;">
                                        <div class="user_item" style="margin-bottom: 10px;"
                                            v-for="(items, key) in item.partner" v-if="key >= 3"
                                            @click.stop="toDetail1(items.id, items.is_user)">
                                            <img :src="items.photo" alt="">
                                            <div>{{ items.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item_box_video" v-else>
                        <div class="tiaomu_item" @click="toDetail(item.id)" v-for="item in datas">
                            <!-- <video :src="item.videourl" class="tiapmu_item_img" :controls="item.zhuangtai == 1"
                                :poster="item.feng" @click.stop="tomsg(item)"></video> -->
                            <img class="tiapmu_item_img" style="object-fit: cover;" :src="item.photo" alt="">
                            <div class="tiaomu_right_box">
                                <div class="tiaomu_right_box_heads">
                                    <div style="color: #005083;font-size: 16px;margin-right: 40px;" v-if="item.writer">
                                        {{ item.writer }}
                                    </div>
                                    <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                        <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                            alt="">
                                        {{ item.create_time | timestampToTime }}
                                    </div>
                                </div>
                                <el-popover placement="top" title="" width="400" trigger="hover" :content="item.title">
                                    <div slot="reference"
                                        style="margin-top: 16px;font-weight: 500;color: #000000;font-size: 20px;"
                                        class="content_title">
                                        {{ item.title }}
                                    </div>
                                </el-popover>
                                <el-popover placement="top" title="" width="400" trigger="hover" :content="item.remark">
                                    <div slot="reference"
                                        style="margin-top: 24px;font-weight: 400;color: #666666;font-size: 16px;"
                                        class="content_title">
                                        {{ item.remark }}
                                    </div>
                                </el-popover>
                                <div class="tips_box" v-if="item.label">
                                    <div>{{ item.label }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination style="margin: auto;margin-top: 20px;" :pageData="pageData" @currentChange="currentChange"></pagination>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import leftMenu from '@/components/leftMenu.vue'
import foots from '@/components/foots.vue'
import pagination from '@/components/pagination.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
export default {
    components: {
        heads,
        foots,
        leftMenu,
        pagination,
        banner
    },
    data() {
        return {
            leftMenuData: [
            ],
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            datas: null,
            DownloadArr: [],
            FilterArr: [],
            downvalue: null,
            filevalue: null,
            searchvalue: null,
            type: this.$route.query.type
        }
    },
    mounted() {
        this.getDatas()
        request({
            url: 'api_type',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            res.data.forEach(item => {
                let json = {
                    name: item.value,
                    id: item.type,
                    active: false,
                }
                if (item.value == "Data") {
                    json.path = '/Publications?s=data&type=' + item.type
                } else {
                    json.path = '/Publications?type=' + item.type
                }
                this.leftMenuData.push(json)
            })
            this.leftMenuData.forEach(item => {
                if (item.id == this.$route.query.type) {
                    item.active = true
                } else {
                    item.active = false
                }
            })
        })
    },
    watch: {
        $route() {
            this.getDatas()
            this.leftMenuData.forEach(item => {
                if (item.id == this.$route.query.type) {
                    item.active = true
                } else {
                    item.active = false
                }
            })
            this.type = this.$route.query.type
        }
    },
    methods: {
        tomsg(item) {
            if (item.zhuangtai != 1) {
                this.$message({
                    message: item.shipinmsg,
                    type: 'warning'
                });
            }
        },
        toZhankai(item) {
            item.zhankaiflag = !item.zhankaiflag
            this.$forceUpdate()
        },
        toPath(url) {
            if (!url) {
                return;
            }
            // 如果是当前页面 不跳转
            if (this.$route.path === url) {
                return;
            }
            this.pageData.current_page = 1
            this.searchvalue = null
            this.downvalue = null
            this.filevalue = null
            this.$router.push(url);
        },
        toHome() {
            this.$router.push('/')
        },
        getDatas() {
            request({
                url: 'tuan_detail_one',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    type: this.$route.query.type,
                    page: this.pageData.current_page,
                    keyword: this.searchvalue,
                    download: this.downvalue,
                    order: this.filevalue,
                    user_id: JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).data.id : null,
                }
            }).then(res => {
                this.datas = res.data.data
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
                this.DownloadArr = res.other.download
                this.FilterArr = res.other.paixu
                this.datas.forEach(item => {
                    item.zhankaiflag = false
                })
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
        toDetail(id, user_id, cooperate) {
            let fuClass = ''
            let fuClassType = ''
            this.leftMenuData.forEach(item => {
                if (item.active) {
                    fuClass = item.name
                    fuClassType = item.id
                }
            })
            this.$router.push({
                path: '/memberm4Detail',
                query: {
                    id,
                    user_id,
                    fuClass,
                    fuClassType,
                }
            })
        },
        toDetail1(id, is_user) {
            if (is_user != 1) {
                return
            }
            this.$router.push({
                path: '/memberm4',
                query: {
                    id
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .box1 {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 30px;

        .body_box {
            margin-top: 10px !important;
        }

        .icon_box {
            display: none !important;
        }

        .body_box {
            margin: 0 20px !important;
            display: flex;



            .right_box {
                flex: 1;
                margin-left: 0;

                .shaixuan {
                    display: none !important;
                }

                .phone_shaixuan>div {
                    margin: 0 !important;
                    width: 45% !important;
                }

                .phone_shaixuan {
                    margin-bottom: 30px !important;
                    display: flex !important;
                    justify-content: space-between !important;
                    flex-wrap: wrap !important;
                    align-items: flex-end !important;

                    .sousuo {
                        margin: 10px 0 !important;
                        width: 100% !important;
                    }
                }

                .items {
                    margin: 10px 0 !important;
                    padding: 0 !important;
                    background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%) !important;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    width: 100% !important;
                    height: auto !important;
                    min-height: auto !important;

                    .items_content {
                        margin: 10px !important;
                        font-size: 12px !important;

                        .titles {
                            font-size: 14px !important;
                        }

                        .text2 {
                            font-size: 14px !important;
                            margin-top: 10px !important;
                        }

                        .text3 {
                            font-size: 12px !important;
                            margin-top: 10px !important;
                        }

                        .foots4 {
                            margin-top: 10px !important;
                            flex-wrap: wrap !important;

                            .tip_box {
                                div {
                                    padding: 5px 10px !important;
                                    font-size: 12px !important;
                                    margin-right: 10px !important;
                                    border-radius: 10px !important;
                                }
                            }

                            .down {
                                width: 30px !important;
                                margin-right: 0px !important;
                            }

                            .shu {
                                display: none !important;
                            }

                            .user_item {
                                margin-left: 10px !important;

                                img {
                                    width: 30px !important;
                                    height: 30px !important;
                                }

                                div {
                                    font-size: 12px !important;
                                }
                            }

                            .dian {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.content {
    width: 100%;
    background: #F8F8F8;

    .shaixuan {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 50px;

        ::v-deep .el-input__inner {
            border-radius: 20px;
            background: #FFFFFF;
            border: none;
            color: #005083;
            font-size: 16px;
        }

        .sousuo {
            display: flex;
            align-items: center;
            background-color: #FFFFFF;
            padding-right: 10px;
            border-radius: 20px;
            border: 1px solid #005083;

            img {
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    }

    .shaixuan>div {
        margin-left: 30px;
    }

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .left_box {
                border-radius: 0px 0px 20px 20px;
                box-shadow: 0px 0px 10px #E5E5E5;

                .left_head {
                    padding: 0 20px;
                    width: 317px;
                    height: 115px;
                    font-size: 25px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #E5E5E5;
                    cursor: pointer;
                    text-align: center;
                }

                .left_head_active {
                    background: #005083;
                    border-radius: 20px 20px 0px 0px;
                    color: #FFFFFF;
                }

                .left_item_box {
                    .item {
                        width: 100%;
                        min-height: 60px;
                        display: flex;
                        background-size: 100% 100%;
                        align-items: center;
                        justify-content: space-between;
                        color: #333;
                        cursor: pointer;
                        border-top: 1px solid #eee;

                        img {
                            width: 16px;
                            margin-right: 20px;
                        }

                        .zi_class {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                            height: 60px;
                        }
                    }

                    .active {
                        background-image: url("@/assets/news/left_bg.png");
                        color: #005083;
                    }
                }

            }

            .right_box {
                flex: 1;
                margin-left: 80px;

                .item_box {
                    display: flex;
                    flex-direction: column;
                    margin-top: -30px;
                    cursor: pointer;

                    .items {
                        margin: 10px 0 !important;
                        padding: 0 !important;
                        background: linear-gradient(180deg, #fff 0%, rgba($color: #9CBBCF, $alpha: .5) 100%) !important;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;
                        width: 100% !important;
                        height: auto !important;
                        min-height: auto !important;


                        .items_content {
                            margin: 31px 41px 36px 41px;

                            .text1 {
                                display: flex;
                                align-items: center;

                                .titles {
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #005083;
                                    font-weight: bold;
                                }
                            }

                            .text2 {
                                font-weight: 500;
                                color: #000000;
                                font-size: 20px;
                                margin-top: 19px;
                                // 超出两行显示省略号
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;

                            }

                            .text3 {
                                font-size: 16px;
                                font-weight: 400;
                                color: #666666;
                                margin-top: 29px;
                            }

                            .foots4 {
                                margin-top: 30px;
                                display: flex;
                                align-items: center;

                                .tip_box {
                                    display: flex;
                                    align-items: center;

                                    div {
                                        padding: 5px 20px;
                                        background: rgba($color: #000000, $alpha: .1);
                                        border-radius: 12px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #333333;
                                        margin-right: 15px;
                                        flex-shrink: 0;
                                    }
                                }

                                .down {
                                    width: 46px;
                                    cursor: pointer;
                                    margin-right: 18px;
                                }

                                .shu {
                                    width: 1px;
                                    height: 23px;
                                    background: rgba($color: #000000, $alpha: .2);
                                }

                                .user_box {
                                    display: flex;
                                    align-items: center;

                                    .user_item {
                                        display: flex;
                                        align-items: center;
                                        margin-left: 20px;

                                        img {
                                            object-fit: cover;
                                            width: 47px;
                                            height: 47px;
                                            border-radius: 50%;
                                        }

                                        div {
                                            margin-left: 8px;
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: #333333;
                                        }
                                    }
                                }

                                .dian {
                                    margin-left: 20px;
                                    cursor: pointer;
                                    width: 53px;
                                }
                            }
                        }
                    }
                }

                .item_box_video {
                    display: flex;
                    flex-wrap: wrap;

                    .tiaomu_item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 40px;
                        cursor: pointer;

                        .tiapmu_item_img {
                            width: 322px;
                            height: 207px;
                            border-radius: 20px;
                        }

                        .tiaomu_right_box {
                            flex: 1;
                            margin-left: 39px;

                            .tiaomu_right_box_heads {
                                display: flex;
                                align-items: center;


                            }

                            .content_title {
                                // 超出两行省略号
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }

                            .tips_box {
                                display: flex;
                                align-items: center;
                                margin-top: 35px;
                                flex-wrap: wrap;

                                div {
                                    margin-right: 30px;
                                    margin-bottom: 10px;
                                    padding: 4px 10px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #333333;
                                    background: rgba($color: #000000, $alpha: .1);
                                    border-radius: 12px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
</style>