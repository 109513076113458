<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <img class="banner" src="@/assets/ForgetPassword/bg33.jpg" alt="">
            <div class="box_cntent">
                <div class="right">
                    <div class="title">Reset Password</div>
                    <div class="inp_content">
                        <div>Enter Verification Code</div>
                        <div class="inp_box">
                            <input type="text" autocomplete="off" v-model="loginData.code">
                        </div>
                        <div style="margin-top: 40px;">Enter A New Password</div>
                        <div class="inp_box">
                            <input type="password" autocomplete="off" v-model="loginData.password">
                        </div>
                        <div style="margin-top: 40px;">Confirm New Password</div>
                        <div class="inp_box">
                            <input type="password" autocomplete="off" v-model="loginData.newpassword">
                        </div>
                        <div class="btn_box" @click="toDatas()">Done, log in again</div>
                        <div class="foot_box1">
                            <div></div>
                            <div class="right1">
                                <div @click="tologin">
                                    Return to login
                                </div>
                                <div @click="toRegister" class="zhuce" style="margin-top: 10px;">
                                    Return to registration
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '@/utils/request';

export default {
    data() {
        return {
            loginData: {
                email: this.$route.query.email,
                password: null,
                newpassword: null,
                code: null
            }
        }
    },
    methods: {
        tologin() {
            this.$router.push('/login')
        },
        toRegister() {
            this.$router.push('/register')
        },
        toDatas() {
            if (!this.loginData.code) {
                this.$message.error('Please enter the verification code');
                return;
            }
            if (!this.loginData.password) {
                this.$message.error('Please enter a new password');
                return;
            }
            if (!this.loginData.newpassword) {
                this.$message.error('Please confirm the new password');
                return;
            }
            if (this.loginData.password !== this.loginData.newpassword) {
                this.$message.error('The two passwords are inconsistent');
                return;
            }
            request({
                url: 'check_email_password',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    email: this.loginData.email,
                    password: this.loginData.password,
                    code: this.loginData.code,
                    password1: this.loginData.newpassword
                },
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    this.$router.push('/login');
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .content>.box {
        margin-top: 0px !important;
        width: 100% !important;
        height: 100vh !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .banner {
        object-fit: cover;
        width: 100% !important;
        height: 100% !important;
    }

    .right {
        margin: 0px !important;
    }

    .box_cntent {
        padding: 4% 3% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100% !important;

        .right {
            width: 80% !important;
            padding: 0px !important;
            padding: 20px !important;

            .title {
                font-size: 20px !important;
                font-weight: bold;
                color: #005083;
                margin-top: 0px !important;
                text-align: center;
            }

            .inp_content {
                font-size: 16px !important;
                font-weight: 400 !important;
                margin-top: 0px !important;

                div {
                    margin-top: 20px !important;
                }

                .inp_box {
                    margin-top: 10px !important;
                    width: auto !important;
                    padding: 10px !important;
                    overflow: hidden !important;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;

                    input {
                        padding-left: 10px !important;
                        font-size: 14px !important;
                    }
                }

                .btn_box {
                    width: 100% !important;
                    height: 40px !important;
                    font-size: 14px !important;
                    margin-top: 30px !important;
                }
            }
        }
    }

}



.content {
    overflow: hidden;
}

.banner {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
}

.box_cntent {
    z-index: 9;
    padding: 4% 3% !important;
    display: flex;
    justify-content: flex-end;

    .right {
        background: rgba($color: #ffffff, $alpha: .98);
        border-radius: 20px;
        margin-top: 80px;
        padding: 30px 40px;

        .title {
            font-size: 30px;
            font-weight: bold;
            color: #005083;
        }

        .inp_content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin-top: 22px;

            .inp_box {
                margin-top: 10px;
                overflow: hidden;
                background: #FFFFFF;
                box-shadow: 0px 19px 35px 0px rgba(0, 47, 100, 0.04);
                border-radius: 20px;
                width: 400px;
                padding: 10px 0px;
                display: flex;
                justify-content: space-between;

                input {
                    border-radius: 20px;
                    border: none;
                    outline: none;
                    padding-left: 20px;
                    font-size: 18px;
                    color: #222222;
                    flex: 1;
                }
            }

            .btn_box {
                margin-top: 20px;
                width: 400px;
                height: 50px;
                background: #005083;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }

            .foot_box1 {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                .right1 {
                    font-size: 16px;
                    font-weight: 400;
                    color: #005ED2;
                    cursor: pointer;
                    text-align: right;
                }
            }
        }
    }
}
</style>