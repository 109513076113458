import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import request from '@/utils/request'
import VueAnimateNumber from 'vue-animate-number'
import { i18nAddResources, i18nChangeLanguage, t } from '@wangeditor/editor'
i18nAddResources('en', {
  // 标题
  header: {
  },
})
i18nChangeLanguage('en')
Vue.use(VueAnimateNumber)
const download = (picurl, dataid, type) => {
  if (!localStorage.getItem('userinfo')) {
    return
  }
  if (!picurl || !dataid) return
  return request({
    url: 'apiwenjian_download',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      picurl,
      id: dataid,
      type,
      user_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
    }
  });
};

// 将 download 方法设置为全局方法
Vue.prototype.$download = download;
import 'element-ui/lib/theme-chalk/index.css';
['success', 'warning', 'info', 'error'].forEach(type => {
  ElementUI.Message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
      options.duration = 800
      options.offset = 90
      // if (type == 'warning' || type == 'error') {
      //     options.showClose = true
      // }
    }
    options.type = type;
    return ElementUI.Message(options);
  };
});
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.filter('timestampToTime', function (timestamp) {
  if (!timestamp) {
    return ''
  }
  var date = new Date(timestamp * 1000);
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = date.getDate().toString().padStart(2, '0') + ' ';
  var h = date.getHours() + ':';
  var m = date.getMinutes() + ':';
  var s = date.getSeconds();
  return Y + M + D
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
