<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="id" :urls="'atmospheric'"></banner>
            <heads></heads>
            <div class="title">Projects</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    Projects
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ datas.title }}
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head"> Projects</div>
                    <!-- <div class="left_item_box" style="width: 287px;">
                        <div v-for="(item, key) in leftMenuData" @click="toPath(item.id)">
                            <div class="item" :class="item.id == id ? 'active' : ''">
                                <div style="margin:0 20px;display: flex;flex-wrap: wrap;">
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="right_box">
                    <div class="right_titles">{{ datas.title }}</div>
                    <div class="phine_contentstext shezhi"
                        style="font-size: 18px;color: #333;margin-top: 29px;padding-bottom: 77px;border-bottom: 1px solid #ddd;"
                        v-html="datas.remark">
                    </div>
                    <div class="right_titles" style="margin-top: 30px;">Team Introduction</div>
                    <div class="userinfo_box" v-if="datas.tuan_member && datas.tuan_member.length != 0">
                        <div class="items" v-for="(item, key) in datas.tuan_member" @click="tomemberm4(item)"
                            v-if="key >= infoActive * 5 && key < (infoActive + 1) * 5">
                            <div class="active">
                                <img class="touxiang" :src="item.photo" alt="">
                            </div>
                            <div class="name">{{ item.title }}</div>
                            <div class="jianjie">{{ item.remark }}</div>
                        </div>
                    </div>
                    <div class="btn_boxs" v-if="datas.tuan_member.length!=0">
                        <div @click="infoActive == 0 ? infoActive = 0 : infoActive = infoActive - 1"><i
                                class="el-icon-arrow-left"></i>
                        </div>
                        <div
                            @click="infoActive == numret(datas.tuan_member.length)-1 ? infoActive = 0 : infoActive = infoActive + 1">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="phone_userinfo_box" style="display: none;">
                        <div class="items" v-for="(item, key) in datas.tuan_member">
                            <div class="active">
                                <img class="touxiang" :src="item.photo" alt="">
                            </div>
                            <div class="name">{{ item.title }}</div>
                            <div class="jianjie">{{ item.remark }}</div>
                        </div>
                    </div>

                    <div class="right_titles" style="margin-top: 30px;font-size: 30px;">Resources</div>

                    <div class="dibu_box" style="margin-top: 30px;">
                        <div class="dibu_shaixuan">
                            <div v-for="item in dibu_arr" :class="item.value == dibu_active ? 'active' : ''"
                                @click="dibu_active = item.value">{{ item.value }}</div>
                        </div>
                        <div class="dibu_data">
                            <div class="Publications" v-for="items in dibu_arr" v-if="dibu_active == items.value">
                                <div v-if="items.type == '9999'">
                                    <div class="list_item" v-for="(item, key) in datas.tuandui_data"
                                        @click="toDetail(item)" :key="item.id">
                                        <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                            <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                                alt="">
                                            {{ item.create_time | timestampToTime }}
                                        </div>
                                        <div
                                            style="display: flex;align-items: center;justify-content: space-between;font-size: 20px;line-height: 25px;margin-top: 24px;">
                                            <div >
                                                {{ item.title }}
                                            </div>
                                            <a :href="item.jumptype == 1 ? item.photo : null" target="_blank"
                                                @click.stop="item.jumptype == 1 ? $download(item.photo, item.id, 2) : toDetail(item)">
                                                <img style="width: 49px;"
                                                    src="@/assets/news/down.png" alt="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="list_item" v-for="(item, key) in datas.tuandui_data"
                                        @click="toDetail(item)" :key="item.id" v-if="item.filetype == items.type">
                                        <div style="font-size: 14px;color: #333;">{{ item.create_time | timestampToTime
                                            }}
                                        </div>
                                        <div
                                            style="display: flex;align-items: center;justify-content: space-between;font-size: 22px;line-height: 25px;margin-top: 2px;">
                                            <div style="color: #666666;">
                                                {{ item.title }}
                                            </div>
                                            <a :href="item.jumptype == 1 ? item.photo : null" target="_blank"
                                                @click.stop="item.jumptype == 1 ? $download(item.photo, item.id, 2) : toDetail(item)">
                                                <img style="width: 49px;"
                                                    src="@/assets/news/down.png" alt="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
export default {
    components: {
        heads,
        foots,
        leftMenu,
        banner
    },
    data() {
        return {
            leftMenuData: [],
            dibu_arr: [],
            dibu_active: 'All',
            infoActive: 0,
            datas: {},
            id: null
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.getDatas()
        request({
            url: 'api_type',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            this.dibu_arr = [{
                value: 'All',
                type: '9999'
            }, ...res.data]
        })
    },
    methods: {
        tomemberm4(item){
            if(!item.uuser_id){
                return;
            }
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: item.uuser_id
                }
            })
        },
        numret(num) {
            // 传入数字返回/5的值 如果有小数点就+1
            return Math.ceil(num / 5)
        },
        setActive(index) {
            this.infoActive = index; // 设置当前激活的索引
        },
        getDatas() {
            request({
                url: 'tuandui_detail',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id
                }
            }).then(res => {
                console.log(res.data)
                this.datas = res.data
            })

            request({
                url: 'api_team_five',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id
                }
            }).then(res => {
                this.leftMenuData = res.data
            })
        },
        toDetail(item) {
            this.$router.push({
                path: '/memberm4Detail',
                query: {
                    id: item.id,
                    user_id: item.user_id,
                    types:3,
                    isLeft: 1
                }
            })
        },
        toPath(id) {
            this.id = id
            this.$router.push({
                path: '/AtmosphericEnvironment',
                query: {
                    id: id
                }
            })
            this.getDatas()
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/Projects'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
// .fade-enter-active,
// .fade-leave-active {
//     transition: opacity 1.5s;
// }

// .fade-enter,
// .fade-leave-to {
//     opacity: 0;
// }

@media screen and (max-width: 1025px) {
    .body_box {
        margin: 0 !important;

        .right_box {
            margin: 0 10px !important;
            padding: 0 !important;

            .right_titles {
                font-size: 20px !important;
                margin-top: 10px !important;
            }

            .phine_contentstext {
                font-size: 16px !important;
                margin-top: 10px !important;
                padding-bottom: 10px !important;
            }

            .phone_userinfo_box {
                display: flex !important;
                justify-content: space-between;
                margin-top: 10px;
                flex-wrap: wrap;

                .items {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    width: 42% !important;
                    margin-bottom: 10px !important;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    border-radius: 10px !important;

                    .active {
                        width: 100px;
                        height: 100px;
                        background-image: url("@/assets/projects/touxiangbg.png");
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .touxiang {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                        }
                    }

                    .name {
                        font-size: 14px;
                        margin-top: 10px;
                    }

                    .jianjie {
                        font-size: 12px;
                        margin-top: 5px;
                        text-align: center;
                        color: #888;
                    }
                }
            }

            .dibu_box {
                margin-top: 10px !important;

                .dibu_shaixuan {
                    margin-bottom: 10px !important;
                    display: flex;
                    flex-wrap: wrap;

                    // 第三个没有margin-right
                    div:nth-child(3n) {
                        margin-right: 0 !important;
                    }

                    div {
                        width: 31% !important;
                        padding: 4px 0 !important;
                        font-size: 14px !important;
                        margin-right: 3% !important;
                        margin-bottom: 10px !important;
                    }
                }

                .Publications {
                    .list_item {
                        padding: 10px 0 !important;
                        border-bottom: 1px solid #ddd !important;

                        div {
                            font-size: 18px !important;

                            img {
                                width: 40px !important;
                            }
                        }
                    }
                }

                .Videos {
                    .item_box {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .item {
                            width: 48% !important;
                            background-color: #FFFFFF;
                            padding: 0 !important;
                            margin-bottom: 10px !important;
                            border-radius: 12px;
                            margin-right: 0 !important;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 120px !important;
                                object-fit: cover;
                            }

                            div {
                                font-size: 18px !important;
                                font-weight: 400 !important;
                                color: #333333;
                                margin: 10px !important;
                            }
                        }
                    }
                }

                .Data {
                    .item_box {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .item {
                            width: 48% !important;
                            background-color: #FFFFFF;
                            padding: 0 !important;
                            margin-bottom: 10px !important;
                            border-radius: 12px;
                            margin-right: 0 !important;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 120px !important;
                                object-fit: cover;
                            }

                            div {
                                font-size: 18px !important;
                                font-weight: 400 !important;
                                color: #333333;
                                margin: 10px !important;
                            }
                        }
                    }
                }
            }

            .userinfo_box,
            .btn_boxs {
                display: none !important;
            }
        }
    }

}

.left_box {
    box-shadow: 0px 0px 10px #E5E5E5;
    border-radius: 20px;
    overflow: hidden;
    flex-shrink: 0 !important;

    .left_head {
        width: 287px;
        height: 115px;
        background: #005083;
        border-radius: 20px 20px 0px 0px;
        font-size: 30px;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left_item_box {
        .item {
            width: 100%;
            min-height: 60px;
            display: flex;
            background-size: 100% 100%;
            align-items: center;
            justify-content: space-between;
            color: #333;
            cursor: pointer;
            border-top: 1px solid #eee;

            img {
                width: 16px;
                margin-right: 20px;
            }

            .zi_class {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                height: 60px;
            }
        }

        .active {
            background-image: url("@/assets/news/left_bg.png");
            color: #005083;
        }
    }

}

.shezhi {
    ::v-deep img {
        max-width: 100%;

        &:hover {
            transform: scale(1);
        }
    }
}

.content {
    width: 100%;
    background: #F5F5F5;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;
                margin-left: 80px;


                .right_titles {
                    font-size: 35px;
                    font-weight: bold;
                    color: #005083;
                }

                .userinfo_box {
                    margin-top: 40px;
                    display: flex;
                    align-items: flex-start;

                    .items {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        width: 150px;

                        .touxiang {
                            width: 110px;
                            height: 110px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .name {
                            font-size: 18px;
                            font-weight: bold;
                            color: #005083;
                            margin-top: 17px;
                            text-align: center;
                            height: 40px;
                        }

                        .jianjie {
                            width: 100%;
                            font-size: 12px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 16px;
                            text-align: center;
                            // 中英文超出换行
                            word-break: break-all;
                        }
                    }
                }

                .btn_boxs {
                    margin-top: 31px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 50px;
                    border-bottom: 1px solid #ddd;

                    div {
                        margin-right: 32px;
                        width: 41px;
                        height: 41px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        cursor: pointer;
                        background-color: #fff;
                        color: #005083;
                    }

                    div:hover {
                        background-color: #005083;
                        color: #fff;
                    }
                }

                .dibu_box {
                    margin-top: 70px;

                    .dibu_shaixuan {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        div {
                            padding: 6px 20px;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 18px;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            text-shadow: 0px 22px 21px rgba(0, 66, 124, 0.04);
                            background: #FFFFFF;
                            box-shadow: 0px 22px 21px 0px rgba(0, 66, 124, 0.04);
                            border-radius: 18px;
                            margin-right: 20px;
                            cursor: pointer;
                        }

                        .active {
                            background: #005083;
                            color: #FFFFFF;
                        }
                    }

                    .dibu_data {
                        .Publications {
                            .list_item {
                                padding: 24px 0;
                                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
                                cursor: pointer;
                            }
                        }

                        .Videos {
                            .item_box {
                                display: flex;
                                flex-wrap: wrap;

                                // 每行第三个item右边距为0
                                .item:nth-child(3n) {
                                    margin-right: 0;
                                }

                                .item {
                                    width: 29%;
                                    padding: 10px;
                                    padding-bottom: 25px;
                                    background-color: #FFFFFF;
                                    margin-bottom: 40px;
                                    border-radius: 12px;
                                    margin-right: 2.5%;

                                    img {
                                        width: 100%;
                                        height: 160px;
                                        overflow: hidden;
                                        border-radius: 12px;
                                    }

                                    div {
                                        font-size: 20px;
                                        font-weight: bold;
                                        color: #333333;
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }

                        .Data {
                            .item_box {
                                display: flex;
                                flex-wrap: wrap;

                                // 每行第三个item右边距为0
                                .item:nth-child(3n) {
                                    margin-right: 0;
                                }

                                .item {
                                    width: 29%;
                                    padding: 10px;
                                    padding-bottom: 25px;
                                    background-color: #FFFFFF;
                                    margin-bottom: 40px;
                                    border-radius: 12px;
                                    margin-right: 2.5%;

                                    img {
                                        width: 100%;
                                        height: 160px;
                                        overflow: hidden;
                                        border-radius: 12px;
                                    }

                                    div {
                                        font-size: 20px;
                                        font-weight: bold;
                                        color: #333333;
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>