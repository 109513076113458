<!-- 登陆页 -->
<template>
    <div class="content">
        <heads></heads>
        <div class="box">
            <img class="banner" src="@/assets/ForgetPassword/bg33.jpg" alt="">
            <div class="box_cntent">
                <img class="banner1" src="" alt="">
                <div class="login_box">
                    <img class="banner1" src="@/assets/login/logos.png" alt="">
                    <div class="title">
                        Sign in
                    </div>
                    <div class="text">
                        Welcome back, you've been missed!
                    </div>
                    <div class="inp_content">
                        <div>E-mail</div>
                        <div class="inp_box">
                            <input type="text" autocomplete="off" v-model="loginData.email" placeholder="@ You Email">
                        </div>
                        <div style="margin-top: 30px;">Password:</div>
                        <div class="inp_box">
                            <input autocomplete="off" :type="passwordShow ? 'text' : 'password'"
                                v-model="loginData.password" placeholder="Password">
                            <img class="icon" @click="passwordShow = !passwordShow" v-if="!passwordShow"
                                src="@/assets/login/kejian.png" alt="">
                            <img class="icon" @click="passwordShow = !passwordShow" v-else
                                src="@/assets/login/biyan.png" alt="">
                        </div>
                    </div>
                    <div class="but_box" @click="toLogin">Login</div>
                    <div class="foot_box">
                        <el-checkbox v-model="checked">
                            <div style="font-size: 16px;">Keep me logged in</div>
                        </el-checkbox>
                        <div class="right">
                            <div @click="toForgetPassword">
                                Forget password?
                            </div>
                            <div @click="toRegister" class="zhuce" style="margin-top: 10px;">
                                Don't have an account?
                            </div>
                        </div>
                    </div>
                    <div @click="toRegister" class="phone_zhuce" style="margin-top: 10px;display: none;">
                        Don't have an account?
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="dialogVisible" width="780px" class="mengbans" :show-close="false">
            <div class="mock_box">
                <img class="bg imgnone" src="@/assets/login/bg2mack.png" alt="">
                <div class="box">
                    <div style="font-weight: bold;font-size: 48px;color: #222222;">{{ dialogVisibleData.one }}</div>
                    <div style="color: #222222;font-size: 16px;margin-top: 10px;">{{ dialogVisibleData.second }}</div>
                    <div style="font-size: 24px;line-height: 36px;margin-top: 30px;font-weight: bold;">
                        Please Log In To Your E-mail <span style="color: #005ED2;">{{ dialogVisibleData.email }}</span>
                        For
                        Verification
                    </div>
                    <div style="font-size: 18px;color: #777777;margin-top: 20px;">
                        {{ dialogVisibleData.three }}
                    </div>
                    <div style="font-size: 18px;color: #777777;margin-top: 20px;">
                        {{ dialogVisibleData.four }}
                    </div>
                    <div style="color: #005ED2;margin-top: 10px;text-align: right;cursor: pointer;" @click="toFasong">
                        Send verification code to your email</div>
                </div>
            </div>
        </el-dialog>
        <foots></foots>
    </div>
</template>

<script>
import request from '@/utils/request';
import heads from '@/components/heads.vue';
import foots from '@/components/foots.vue';

export default {
    components: {
        foots,
        heads,
    },
    data() {
        return {
            passwordShow: false,
            dialogVisible: false,
            dialogVisibleData: {},
            checked: false,
            loginData: {
                email: null,
                password: null
            }
        }
    },
    mounted() {
        this.loginData.email = null
        this.loginData.password = null
        window.scrollTo(0, 0)
    },
    methods: {
        toRegister() {
            this.$router.push('/register')
        },
        toForgetPassword() {
            if (!this.loginData.email) {
                this.$message.error('Please enter your email')
                return
            } else {
                request({
                    url: 'first_email',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        email: this.loginData.email
                    },
                }).then(res => {
                    if (res.code == 200) {
                        this.dialogVisibleData = res.data
                        this.dialogVisible = true
                        console.log(res)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        toLogin() {
            if (!this.loginData.email) {
                this.$message.error('Please enter your email')
                return
            }
            if (!this.loginData.password) {
                this.$message.error('Please enter your password')
                return
            }
            // if (!this.checked) {
            //     this.$message.error('Please check the box')
            //     return
            // }
            request({
                url: 'apilogin',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    email: this.loginData.email,
                    password: this.loginData.password
                }
            }).then(res => {
                if (res.code == 200) {
                    let userinfo = {
                        data: res.data,
                        other: res.other
                    }
                    localStorage.setItem('userinfo', JSON.stringify(userinfo))
                    this.$message.success(res.msg)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)
                } else {
                    this.$message.error(res.msg)
                }
                console.log(res)
            })
        },
        toFasong() {
            request({
                url: 'aress_email',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    email: this.dialogVisibleData.email
                },
            }).then(res => {
                this.dialogVisible = false
                this.$router.push({
                    path: '/forgetPassword',
                    query: {
                        email: this.dialogVisibleData.email
                    }
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .content>.box {
        margin-top: 0px !important;
        width: 100% !important;
    }

    .banner {
        object-fit: cover;
    }

    .box_cntent {
        padding: 4% 2%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .banner1 {
            display: none;
        }

        .login_box {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 20px;
        }
    }

    .phone_zhuce {
        display: block !important;
        color: #888;
        margin-top: 20px;
    }

    .foot_box {
        .zhuce {
            display: none;
        }

    }

}

.content {
    overflow: hidden;
}

.banner {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -1;
}

.box_cntent {
    z-index: 9;
    padding: 100px 40px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: calc(100vh - 180px) !important;

    .banner1 {
        width: 40%;
    }

    .login_box {
        margin-top: 30px;
        flex: 1;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
        background-color: #FFFFFF;
        padding: 20px 30px;
        border-radius: 10px;

        .logo {
            height: 100px;
        }

        .title {
            font-size: 36px;
            font-weight: bold;
            color: #005083;
            margin-top: 16px;
        }

        .text {
            font-size: 20px;
            font-weight: 400;
            color: #777777;
            margin-top: 8px;
        }

        .inp_content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin-top: 26px;

            .inp_box {
                margin-top: 10px;
                overflow: hidden;
                background: #FFFFFF;
                box-shadow: 0px 19px 35px 0px rgba(0, 47, 100, 0.04);
                border-radius: 20px;
                padding: 18px 20px;
                padding-left: 10px;
                display: flex;
                justify-content: space-between;

                input {
                    border-radius: 20px;
                    border: none;
                    outline: none;
                    padding-left: 20px;
                    font-size: 14px;
                    color: #222222;
                    flex: 1;
                }


                .icon {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }
        }

        .but_box {
            margin-top: 30px;
            background: #005083;
            border-radius: 20px;
            width: 100%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }

        .foot_box {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .right {
                font-size: 16px;
                font-weight: 400;
                color: #005ED2;
                cursor: pointer;
                text-align: right;
            }
        }
    }
}

::v-deep .el-dialog {
    background-color: transparent !important;
    box-shadow: none !important;
}

::v-deep .el-dialog__header {
    padding: 0 !important;
}

::v-deep .el-dialog__body {
    padding: 0 !important;
    border-radius: 20px !important;
    background-color: none;
}

.mock_box {
    width: 100%;
    position: relative;

    .bg {
        width: 100%;
        z-index: -1;
    }

    .box {
        position: absolute;
        top: 0;
        padding: 50px;
        z-index: 999;
        color: #222;
    }
}
</style>