<template>
    <div class="content">
        <div class="box">
            <banner :types="1"></banner>
            <heads></heads>
        </div>
        <div class="box1">
            <div class="body_box">
                <div class="right_box">
                    <div class="tiaomu_box">
                        <div class="tiaomu_item" v-for="item in datas" @click="toDetail(item)">
                            <div class="tiaomu_right_box">
                                <div class="tiaomu_right_box_heads">
                                    <div style="display: flex;align-items: center;">
                                        <!-- <div style="color: #005083;font-size: 16px;margin-right: 40px;">{{ item.vivo }}
                                        </div> -->
                                        <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                            <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                                alt="">
                                            {{ item.create_time | timestampToTime }}
                                        </div>
                                    </div>
                                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                                        <div class="btns">Delete</div>
                                        <div style="margin: 0 5px;color: #005083;opacity: .6;">/</div>
                                        <div class="btns">Modify</div>
                                    </div> -->
                                </div>
                                <div style="margin-top: 16px;font-weight: 500;color: #000000;font-size: 20px;width: 760px;">
                                    {{ item.title }}
                                </div>
                                <div style="margin-top: 24px;font-weight: 400;color: #666666;font-size: 16px;">
                                    {{ item.remark }}
                                </div>
                                <div class="tips_box">
                                    <div v-for="items in item.labels">{{ items }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="fenye">
                            <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                        </div>
                    </div>
                    <div class="phone_tiaomu_box tiaomu_box" style="display: none;" v-if="datas && datas.length != 0">
                        <div class="tiaomu_item" v-for="item in datas" @click="toDetail(item)">
                            <div class="tiaomu_right_box">
                                <div class="tiaomu_right_box_heads">
                                    <div style="display: flex;align-items: center;">
                                        <div style="color: #005083;font-size: 16px;margin-right: 40px;">{{ item.vivo }}
                                        </div>
                                        <div style="display: flex;align-items: center;color: #999999;font-size: 14px;">
                                            <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png"
                                                alt="">
                                            {{ item.create_time | timestampToTime }}
                                        </div>
                                    </div>
                                    <div style="display: flex;align-items: center;justify-content: center;">
                                        <div class="btns">Delete</div>
                                        <div style="margin: 0 5px;color: #005083;opacity: .6;">/</div>
                                        <div class="btns">Modify</div>
                                    </div>
                                </div>
                                <div style="margin-top: 10px;font-weight: 500;color: #000000;font-size: 20px;">
                                    {{ item.title }}
                                </div>
                                <div style="margin-top: 10px;font-weight: 400;color: #666666;font-size: 16px;">
                                    {{ item.remark }}
                                </div>
                                <div class="tips_box" style="margin-top: 10px;">
                                    <div v-for="items in item.labels">{{ items }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="fenye">
                            <pagination :pageData="pageData" @currentChange="currentChange"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
import pagination from '@/components/pagination.vue'
import banner from '@/components/banner.vue'

export default {
    components: {
        heads,
        foots,
        leftMenu,
        pagination,
        banner
    },
    data() {
        return {
            pageData: {
                current_page: 1,
                total: 0,
                per_page: 10
            },
            datas: null,
            DownloadArr: [],
            FilterArr: [],
            downvalue: null,
            filevalue: null,
            searchvalue: null,
            biaoqianValue: null,
            biaoqian2Value: null,
            userinfos: null,
            findtype: 1
        }
    },
    mounted() {
        this.searchvalue = this.$route.query.search || null
        this.findtype = this.$route.query.findtype || null
        this.getDatas()
    },
    methods: {
        toDetail(item) {
            if(this.findtype==1){
                this.$router.push({
                    path: '/memberm4',
                    query: {
                        id: item.id
                    }
                })
            }else if(this.findtype==2){
                this.$router.push({
                    path: '/memberm4Detail',
                    query: {
                        id: item.id,
                        user_id: item.user_id
                    }
                })
            }else if(this.findtype==3){
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        id: item.id
                    }
                })
            }
        },
        getDatas() {
            request({
                url: 'finddata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    keyword: this.searchvalue,
                    type: this.findtype,
                    page: this.pageData.current_page,
                }
            }).then(res => {
                this.datas = res.data.data
                this.pageData.total = res.data.total
                this.pageData.per_page = res.data.per_page
                this.pageData.current_page = res.data.current_page
                this.FilterArr = res.other.paixu
                this.DownloadArr = res.other.download
                this.userinfos = res.other.userinfo
            })
        },
        currentChange(e) {
            this.pageData.current_page = e
            this.getDatas()
        },
    },
    watch: {
         $route(to, from) {
            this.searchvalue = to.query.search || null
            this.findtype = to.query.findtype || null
            this.getDatas()
         }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .body_box {
        margin: 10px !important;
        flex-direction: column !important;
    }

    .box1 {
        margin-top: 0 !important;
    }

    .mengban {
        display: none;
    }

    .phone_mengban {
        display: block !important;

        .info_box {
            display: flex;
            flex-wrap: wrap;
            padding: 15px;
            background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
            border-radius: 10px;
            flex-direction: column;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

            .info1 {
                width: 100px !important;
                height: 100px !important;
                margin: auto !important;
                position: relative !important;
                margin-top: 20px !important;

                .touxiang {
                    width: 100px !important;
                    height: 100px !important;
                    border: 2px solid #005083 !important;
                    border-radius: 50%;
                }

                .huangguan {
                    width: 52px !important;
                    position: absolute !important;
                    top: -20px !important;
                    left: -10px !important;
                }
            }

            .phone_userinfo {
                margin-top: 10px !important;

                .info2 {
                    flex: 1;

                    .info2_box {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        .titles {
                            color: #005083 !important;
                            font-weight: bold;
                        }

                        .value {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }

    .right_box {
        display: flex;
        flex-direction: column;

        .btn_box {
            padding-bottom: 10px !important;
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px !important;

            .items {
                padding: 5px 15px !important;
                height: auto !important;
                font-size: 14px !important;
                margin-bottom: 10px !important;
                margin-right: 20px !important;
            }
        }

    

        .tiaomu_right_box {
            margin: 0 !important;
            padding: 10px 0 !important;
        }

        .tiaomu_item {
            margin: 10px 0 !important;
        }

        .tiaomu_box {
            display: none;
        }

        .phone_tiaomu_box {
            display: block !important;
            margin: 0 !important;
            padding: 0 !important;

            div {
                font-size: 14px !important;
            }
        }
    }
}

.content {
    width: 100%;
    background: #F7FAFB;

   


    .box {
        position: relative;
        overflow: hidden;


        .banner {
            width: 100%;
        }

        .mengban {
            width: 75%;
            height: 430px;
            background: rgba($color: #ffffff, $alpha: .9);
            border-radius: 20px;
            position: absolute;
            top: 56%;
            left: 50%;
            transform: translate(-50%, -50%);

            .info_box {
                display: flex;
                align-items: center;
                margin-top: 100px;
                margin-left: 60px;

                .info1 {
                    position: relative;

                    .touxiang {
                        width: 170px;
                        height: 170px;
                        border-radius: 50%;
                        border: 2px solid #005083;
                        object-fit: cover;
                    }

                    .huangguan {
                        width: 84px;
                        position: absolute;
                        top: -35px;
                        left: -15px;
                    }
                }

                .info2 {
                    margin-left: 50px;

                    .info2_box {
                        display: flex;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 20px;

                        .titles {
                            color: #005083;
                        }

                        .value {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        .icon_box {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;


            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .icons {
            cursor: pointer;
        }

        .body_box {
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;

                .btn_box {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #E5E5E5;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    img {
                        width: 53px;
                        cursor: pointer;
                    }

                    .items {
                        margin-bottom: 10px;
                        cursor: pointer;
                        padding: 0 35px;
                        height: 50px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        background: rgba(255, 255, 255, 0.85);
                        border: 2px solid #FFFFFF;
                        border-radius: 30px;
                        max-width: 142px;
                        margin-right: 10px;

                        div {
                            text-align: center;
                        }
                    }

                    .active {
                        background: #005083;
                        color: #FFFFFF;
                    }
                }

                .down_box {
                    cursor: pointer;
                    background: rgba(0, 80, 131, 0.1);
                    border: 1px solid #00518319;
                    border-radius: 28px;
                    min-width: 220px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 22px 0 30px;

                    img {
                        width: 12px;
                    }

                    .inp {
                        border: none;
                        background: none;
                        height: 100%;
                        padding: 0 20px;
                        // 清除默认样式
                        outline: none;
                        box-shadow: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        font-size: 16px;
                    }

                    .icon_boxa {
                        cursor: pointer;
                        width: 90px;
                        height: 100%;
                        background: #005083;
                        border-radius: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 18px;
                        }
                    }
                }

                .tiaomu_box {
                    margin-top: 60px;


                    .tiaomu_item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 40px;
                        cursor: pointer;

                        .tiapmu_item_img {
                            width: 342px;
                            height: 227px;
                            border-radius: 20px;
                        }

                        .tiaomu_right_box {
                            flex: 1;
                            padding-bottom: 40px;
                            border-bottom: 1px solid #E5E5E5;

                            .tiaomu_right_box_heads {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .btns {
                                    color: #005083;
                                    font-size: 16px;
                                    opacity: .6;
                                }

                                .btns:hover {
                                    text-decoration: underline;
                                    opacity: 1;
                                }
                            }

                            .tips_box {
                                display: flex;
                                align-items: center;
                                margin-top: 35px;

                                div {
                                    margin-right: 30px;
                                    padding: 4px 10px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #333333;
                                    background: rgba($color: #000000, $alpha: .1);
                                    border-radius: 12px;
                                }
                            }
                        }
                    }

                    .fenye {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        ::v-deep .el-pagination {
                            display: flex;
                        }

                        ::v-deep .el-pager li.active {
                            color: #fff;
                            background-color: #005083;
                        }

                        ::v-deep .el-pager li {
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid #E3E3E3;
                            line-height: 40px;
                            font-size: 16px;
                            margin-right: 10px;
                            font-weight: 100;
                            color: #999999;
                        }

                        ::v-deep .btn-prev {
                            width: 90px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            padding: 0;
                            margin: 0;
                            margin-right: 10px;
                        }

                        ::v-deep .btn-next {
                            width: 90px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E3E3E3;
                            border-radius: 20px;
                            padding: 0;
                            margin: 0;
                        }

                    }
                }
            }
        }
    }

}
</style>
  