<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <heads :isUpload="true"></heads>
        </div>
        <div class="box1">
            <div class="body_box">
                <div class="right_box">
                    <div class="head_1">
                        <div style="display: flex;">
                            <div style="margin-right: 70px;"> {{ datas.create_by }}
                            </div>
                            <div style="margin-right: 70px;">
                                {{ datas.views }}
                            </div>
                            <div style="display: flex;align-items: center;color: #999999;">
                                <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png" alt="">
                                {{ datas.create_time | timestampToTime }}
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;"
                            v-if="datas.biaoqian && datas.biaoqian.length != 0">
                            <div class="tips" style="margin-right: 20px;" v-for="item in datas.biaoqian">{{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="head_2">
                        {{ datas.title }}
                    </div>
                    <div class="head_3" v-if="false">
                        <div>Follow Us</div>
                        <img src="@/assets/news/wx.png" alt="">
                        <img src="@/assets/news/tt.png" alt="">
                    </div>
                    <div class="head_banner_box">
                        <div v-html="datas.content"></div>
                        <!-- <img class="news_banner" src="@/assets/news/banner.jpg" style="object-fit: cover;" alt="">
                        <div class="texts">
                            Being a newly founded international scientific organization, the Atmospheric Environmental
                            Remote Sensing Society (AERSS) is dedicated to building an interdisciplinary and cross-country
                            platform for linking remote sensing to modeling and in situ observations, and promoting the
                            application of atmospheric environmental remote sensing in climate, air quality, and other
                            sustainable development goals (SDGs), especially in the Global South. The Society was registered
                            in Hong Kong in July 2022. There are already hundreds of members from China, France, Italy,
                            Germany, USA, Canada, Netherlands, Greece, Russia, Singapore, Japan, Korea, Pakistan, Brazil,
                            Argentina, and other countries.

                            AERSS Annual Meeting 2023will be held September 16th-20th, 2023 in Wuhan, China, and will focus
                            on "global environmental change and remote sensing". It is anticipated that more than 800
                            attendees will participate in the meeting. We welcome active participation from a borad and
                            diverse community including but not limited to scientists, students, journalists, policymakers,
                            educators, and organizations.
                        </div> -->
                    </div>
                    <div class="head_5">
                        <div style="align-items: start; flex: 2;" @click="toDetail(datas.lastid)">
                            <div style="font-size: 24px;color: #666;">Previous</div>
                            <div style="font-size: 16px;">{{ datas.lasttitle }}</div>
                        </div>
                        <div style="align-items: center;" @click="toNewsList()">
                            <img src="@/assets/news/sige.png" style="width: 56px;" alt="">
                            <div style="margin-top: 17px;color: #005083;font-size: 16px;">Back to list</div>
                        </div>
                        <div style="align-items: flex-end;flex: 2;" @click="toDetail(datas.nextid)">
                            <div style="font-size: 24px;color: #666;">Next</div>
                            <div style="font-size: 16px;">{{ datas.nexttitle }}</div>
                        </div>
                    </div>
                    <div class="list_1">
                        <div style="font-size: 35px;font-weight: bold;color: #005083;">News，Paper</div>
                        <div class="list_box">
                            <div class="list_item" v-for="(item, key) in datas.newspapers" :key="key">
                                <div style="font-size: 14px;">{{ datas.create_time | timestampToTime }}</div>
                                <div
                                    style="display: flex;align-items: center;justify-content: space-between;font-size: 22px;line-height: 25px;margin-top: 2px;">
                                    <div>
                                        {{ item }}
                                    </div>
                                    <a :href="item" target="_blank" @click="$download(item.id)">
                                        <img style="width: 49px;margin-left: 180px;" src="@/assets/news/down.png"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="textarea_box">
                        <el-input type="textarea" placeholder="请输入内容" v-model="textarea">
                        </el-input>
                        <div class="btn_box" @click="onPush()">
                            Submit
                            <img src="@/assets/index/right_icon.png" alt="">
                        </div>
                    </div>
                    <div class="liuyan_box">
                        <div class="list_box" v-for="(item, key) in datas.talklist" :key="key">
                            <div style="padding-right: 36px;border-right: 1px solid #eee;">
                                <img style="width: 83px;height: 83px;border-radius: 50%;object-fit: cover;"
                                    :src="item.photo" alt="">
                                <div style="color: #005083;text-align: center;margin-top: 10px;font-weight: bold;">{{
                item.name }}
                                </div>
                            </div>
                            <div style="width: 700px;margin-left: 30px;">
                                <div style="display: flex;align-items: center;color: #999;font-size: 14px;">
                                    <img style="width: 16px;margin-right: 10px;" src="@/assets/news/time.png" alt="">
                                    {{ item.create_time | timestampToTime }}
                                </div>
                                <div style="font-size: 18px;margin-top: 10px;color: #333333;line-height: 24px;">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
export default {
    components: {
        heads,
        foots,
        leftMenu
    },
    data() {
        return {
            leftMenuData: [
                {
                    name: 'News',
                    path: '/news'
                }
            ],
            textarea: null,
            datas: {}
        }
    },
    methods: {
        getNewsList() {
            request({
                url: 'apituandetail',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    id: this.$route.query.id,
                    type: 2
                }
            }).then(res => {
                console.log(res.data)
                this.datas = res.data
            })
        },
        onPush() {
            request({
                url: 'fujian_talk_add',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    news_id: this.$route.query.id,
                    talk_id: JSON.parse(localStorage.getItem('userinfo')).data.id,
                    content: this.textarea,
                    type: 2
                }
            }).then(res => {
                console.log(res.data)
                if (res.code == 200) {
                    this.textarea = null
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getNewsList()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }
            })
        },
        toDetail(id) {
            if (id) {
                this.$router.push({
                    path: '/tuanDetail',
                    query: {
                        id: id
                    }
                })
            } else {
                this.$message({
                    message: 'No more',
                    type: 'warning'
                });
            }
            this.getNewsList()
        },
        toNewsList() {
            this.$router.push({
                path: '/Publications',
            })
        }
    },
    mounted() {
        this.getNewsList()
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    background: #fff;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                padding: 20px;
                margin-left: 110px;

                .head_1 {
                    font-size: 16px;
                    font-weight: 400;
                    color: #666666;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .tips {
                        padding: 4px 20px;
                        background: rgba($color: #000000, $alpha: 0.1);
                        border-radius: 20px;
                        color: #333333;
                        cursor: pointer;
                    }
                }

                .head_2 {
                    font-size: 36px;
                    font-weight: 500;
                    color: #000000;
                    margin-top: 30px;
                }

                .head_3 {
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333333;
                    justify-content: flex-end;

                    img {
                        width: 24px;
                        margin-left: 29px;
                    }
                }

                .head_banner_box {
                    margin-top: 56px;
                    padding-bottom: 56px;
                    border-bottom: 1px solid rgba($color: #333333, $alpha: 0.1);

                    ::v-deep img {
                        max-width: 100% !important;

                        &:hover {
                            transform: scale(1);
                        }
                    }

                    .news_banner {
                        height: 443px;
                        width: 100%;
                    }

                    .texts {
                        margin-top: 60px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }

                .head_5 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                }

                .head_5>div {
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    color: #333333;
                    font-weight: bold;
                    cursor: pointer;

                }

                .list_1 {
                    margin-top: 77px;

                    .list_box {
                        .list_item {
                            padding: 24px 0;
                            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
                        }

                    }
                }

                .textarea_box {
                    margin-top: 70px;
                    display: flex;
                    flex-direction: column;
                    background: #F2F2F2;
                    border: 1px solid #CFCFCF;
                    border-radius: 10px;
                    padding: 30px;

                    .btn_box {
                        width: 110px;
                        margin-top: 95px;
                        padding: 8px 19px;
                        border: 2px solid #005083;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                        cursor: pointer;

                        img {
                            width: 23px;
                            margin-left: 30px;
                        }
                    }
                }

                .liuyan_box {
                    margin-top: 35px;
                    margin-left: -30px;

                    .list_box {
                        width: 92%;
                        padding: 50px;
                        background-image: url('@/assets/news/listbg.png');
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        padding-bottom: 70px;
                    }
                }
            }
        }
    }
}
</style>