<template>
    <!-- <div style=" display: flex;align-items: center;justify-content: center;" v-if="pageData.total > pageData.per_page"> -->
    <div style=" display: flex;align-items: center;justify-content: center;" v-if="pageData.total > pageData.per_page">
        <el-pagination layout="prev, pager, next" :total="pageData.total" :page-size="pageData.per_page"
            :current-page="pageData.current_page" prev-text="Previous" next-text="Next" @current-change="currentChange">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        pageData: {
            type: Object,
            default: {
                total: 0,
                current_page: 1,
                per_page: 10,
            }
        }
    },
    data() {
        return {
        }
    },
    methods:{
        currentChange(event){
            this.$emit('currentChange',event)
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-pagination {
    display: flex;
}

::v-deep .el-pager li.active {
    color: #fff;
    background-color: #005083;
}

::v-deep .el-pager li {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #E3E3E3;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    font-weight: 100;
    color: #999999;
}

::v-deep .btn-prev {
    width: 90px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3E3E3;
    border-radius: 20px;
    padding: 0 !important;
    margin: 0;
    margin-right: 10px !important;
}


::v-deep .btn-next {
    width: 90px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3E3E3;
    border-radius: 20px;
    padding: 0 !important;
    margin: 0;
}
</style>
  