<template>
    <div class="left_box">
        <div class="left_head"> {{ title }}</div>
        <div class="left_item_box">
            <div v-for="(item, key) in datas" @click="toPath(item.path)">
                <div class="item" :class="key == activeIndex ? 'active' : ''" v-if="!item.isChildren">
                    <div style="margin-left: 20px;">
                        {{ item.name }}
                    </div>
                    <img src="@/assets/news/to_right.png" alt="">
                </div>
                <div class="item" style="flex-direction: column;" :class="key == activeIndex ? 'active' : ''" v-else>
                    <div class="zi_class" @click="tozhankai">
                        <div style="margin-left: 20px;">
                            {{ item.name }}
                        </div>
                        <img src="@/assets/news/to_right.png" alt="">
                    </div>
                    <div v-if="zhankai">
                        <div class="zi_class" style="height: 40px;color: #000;font-size: 14px;"
                            v-for="items in item.children" @click="toPaths(items.id)">
                            <div style="margin-left: 20px;display: inline-block;"
                                :style="items.active ? 'color:#005083;border-bottom:2px solid #005083' : ''">
                                {{ items.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datas: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            activeIndex: 0,
            zhankai: true
        }
    },
    methods: {
        tozhankai() {
            this.zhankai = !this.zhankai
        },
        toPath(url) {
            if (!url) {
                return;
            }
            // 如果是当前页面 不跳转
            if (this.$route.path === url) {
                return;
            }
            this.$router.push(url);
        },
        toPaths(id) {
            if (!id) {
                return;
            }
            // 给当前点击的id加上active
            this.datas.forEach(item => {
                if (item.isChildren) {
                    item.children.forEach(items => {
                        items.active = false
                        if (items.id == id) {
                            items.active = true
                        }
                    })
                }
            })
            this.$forceUpdate()
            // 如果是当前页面 不跳转
            if (this.$route.query.id == id) {
                return;
            }
            this.$router.push({ path: this.$route.path, query: { id: id } });
        }
    },
    mounted() {
        // 获取路由找到对应的index
        let index = this.datas.findIndex(item => item.path === this.$route.path)
        this.activeIndex = index
        this.toPaths(this.$route.query.id)
      
    },
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;

    .left_box {
        box-shadow: 0px 0px 10px #E5E5E5;
        border-radius: 20px;
        overflow: hidden;
        flex-shrink: 0 !important;

        .left_head {
            width: 287px;
            height: 115px;
            background: #005083;
            border-radius: 20px 20px 0px 0px;
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .left_item_box {
            width: 287px;

            .item {
                width: 100%;
                min-height: 60px;
                display: flex;
                background-size: 100% 100%;
                align-items: center;
                justify-content: space-between;
                color: #333;
                cursor: pointer;
                border-top: 1px solid #eee;

                img {
                    width: 16px;
                    margin-right: 20px;
                }

                .zi_class {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    height: 60px;
                }
            }

            .active {
                background-image: url("@/assets/news/left_bg.png");
                color: #005083;
            }
        }

    }
}

@media screen and (max-width: 1025px) {
    .content {
        .left_box {
            display: none !important;
        }
    }
}
</style>