<template>
    <div class="content">
        <div class="box">
            <banner v-if="types" :types="types" :ziding="true" :key="bannerKey"></banner>
            <heads></heads>
            <div class="title">Organization</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div @click="tofuPath" style="cursor: pointer;">
                    Organization
                </div>
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    WorkingGroups
                </div>
            </div>
            <div class="body_box">
                <leftMenu v-if="leftMenuData1" :datas='leftMenuData1' :title="'Organization'"></leftMenu>
                <div class="right_box" v-if="datas1">
                    <div class="right_title">
                        {{ datas1.title }}
                    </div>
                    <img v-if="datas1.layimg" :src="datas1.layimg" class="imgnone"
                        style="width: 100%;border-radius: 20px;margin-top: 10px;"></img>
                    <div class="right_box1" v-if="datas1.remark">
                        <div style="display: flex;align-items: center;margin: 20px;margin-bottom: 20px;">
                            <img style="width: 29px;height: 29px;" src="@/assets/organization/icon_nan.png" alt="">
                            <div style="color: #005083;font-size: 36px;margin-left: 10px;">Scope</div>
                        </div>
                        <div style="color: #333;font-size: 18px;padding:20px;padding-top: 0px ;">
                            {{ datas1.remark }}
                        </div>
                    </div>
                    <div class="right_box2">
                        <div class="box2_content" style="margin-right: 20px;">
                            <div class="phonetitle"
                                style="display: flex;align-items: center;margin: 20px;margin-bottom: 20px;">
                                <img style="width: 29px;height: 29px;" src="@/assets/organization/icon_nan.png" alt=""
                                    class="phone_img">
                                <div style="color: #005083;font-size: 36px;margin-left: 10px;" class="phonetext">
                                    Co-Chairs
                                </div>
                            </div>
                            <div class="item_box">
                                <div class="item" v-for="item in datas2" @click="toMember4(item)">
                                    <img :src="item.photo" alt="">
                                    <div class="name">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box2_content">
                            <div class="phonetitle"
                                style="display: flex;align-items: center;margin: 20px;margin-bottom: 20px;">
                                <img class="phone_img" style="width: 29px;height: 29px;"
                                    src="@/assets/organization/icon_nan.png" alt="">
                                <div class="phonetext" style="color: #005083;font-size: 36px;margin-left: 10px;">
                                    Secretary
                                </div>
                            </div>
                            <div class="item_box">
                                <div class="item" v-for="item in datas3" @click="toMember4(item)">
                                    <img :src="item.photo" alt="">
                                    <div class="name">
                                        <div style="padding-right: 10px;">
                                            {{ item.title }}
                                        </div>
                                        <div style="padding-right: 10px;" class="noneFont">
                                            {{ item.remark }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right_title phone_right_title" style="margin-top: 20px;">
                        Invited Experts,Alphabetical by Last Name
                    </div>
                    <div class="right_box3">
                        <div style="min-height: 370px;" class="content_box1">
                            <transition name="fade" mode="out-in">
                                <div v-for="(item, key) in paginatedData" :key="key" v-if="pageIndex === key"
                                    class="content_box">
                                    <div class="item_box fangda" v-for="items in item">
                                        <div class="title">
                                            {{ items.title }}
                                        </div>
                                        <div class="text">
                                            {{ items.remark }}
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="phone_content_box" style="display: none;" v-for="item in paginatedData">
                            <div class="item_box" v-for="items in item">
                                <div class="title">
                                    {{ items.title }}
                                </div>
                                <div class="text">
                                    {{ items.remark }}
                                </div>
                            </div>
                        </div>
                        <div class="dalunbo">
                            <div class="icon" style="margin-right: 32px;" @click="toShangyiye">
                                <i class="el-icon-arrow-left"></i>
                            </div>
                            <div class="icon" @click="toNext">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="right_title phone_right_title" style="margin-top: 30px;">
                        Latest research progress
                    </div>
                    <div class="right_box4" v-if="status == 1">
                        <div class="box4_item" v-for="(item, key) in datas5"
                            :style="key >= datas5.length - 1 ? 'border:none' : ''">
                            <div class="time">{{ item.create_time | timestampToTime }}</div>
                            <div class="title">
                                <a :href="item.httpurl || null" target="_blank" rel="noopener noreferrer">
                                    {{ item.title }}
                                </a>
                            </div>
                            <div class="lunbo">
                                <div class="icon" @click="toShangyiye1(key)">
                                    <i class="el-icon-arrow-left"></i>
                                </div>
                                <swiper :datas="item.photo" style="flex: 1;margin: 0 20px;" :active="item.activeKey">
                                </swiper>
                                <div class="icon" @click="toNext1(key)">
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                            <div class="phone_lunbo" style="display: none;">
                                <div class="img_box">
                                    <div v-for="items in item.photo">
                                        <img :src="items" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import leftMenu from '@/components/leftMenu.vue'
import foots from '@/components/foots.vue'
import swiper from '@/components/swiper.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
export default {
    components: {
        heads,
        foots,
        leftMenu,
        banner,
        swiper
    },
    data() {
        return {
            leftMenuData: [
                {
                    name: 'Constitution',
                    path: '/Constitution'
                },
                {
                    name: 'Structure',
                    path: '/Introduction'
                },
                {
                    name: 'AERSS Leadership',
                    path: '/Leadership'
                },
                {
                    name: 'Council',
                    path: '/Council'
                },
                {
                    name: 'Scientific Steering Committee',
                    path: '/Scientific'
                },

                {
                    name: 'Working Groups',
                    path: '/WorkingGroups',
                    isChildren: true
                },
                {
                    name: 'ECPC',
                    path: '/Ecpc'
                },
                {
                    name: 'Secretariat',
                    path: '/Secretariat'
                },
                {
                    name: 'Milestone',
                    path: '/Milestone'
                },
                {
                    name: 'Contact Us',
                    path: '/Contact'
                }],
            leftMenuData1: null,
            datas1: null,
            datas2: null,
            datas3: null,
            datas4: null,
            datas5: null,
            status: null,
            pageIndex: 0,
            types: null,
            bannerList: [],
            bannerKey: 0
        }
    },
    computed: {
        paginatedData() {
            const pageSize = 6; // 每页显示的项目数量
            const totalPage = Math.ceil(this.datas4.length / pageSize); // 总页数
            const paginatedData = [];
            for (let i = 0; i < totalPage; i++) {
                const start = i * pageSize;
                const end = start + pageSize;
                paginatedData.push(this.datas4.slice(start, end));
            }
            return paginatedData;
        }
    },
    mounted() {
        this.getGongzuozu()
        this.getWorkgroup()
    },
    methods: {
        toMember4(item) {
            if(!item.user_id){
                return;
            }
            this.$router.push({
                path: '/memberm4',
                query: {
                    id: item.user_id
                }
            })
        },
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/Introduction'
            })
        },
        getGongzuozu() {
            if (!this.$route.query.id) {
                return;
            }
            this.types = Number(this.$route.query.id)
            this.bannerKey++
            request({
                url: 'api_groupdata',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    belong: this.$route.query.id
                }
            }).then(res => {
                let json = res.data
                this.datas1 = json.data
                this.datas2 = json.gochairs
                this.datas3 = json.zhuxi
                this.datas4 = json.member
                this.datas5 = json.groupdata
                this.status = json.status
                // 把datas5里面的数组分为多个数组并且每个数组只有三个元素
                this.datas5.forEach(item => {
                    item.activeKey = 0
                })
            })
        },
        getWorkgroup() {
            request({
                url: 'api_workgroup',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                this.leftMenuData.forEach(item => {
                    if (item.isChildren) {
                        item.children = res.data
                        item.children[1].active = true
                        if (this.$route.query.id == item.children[1].id) {
                            return;
                        }
                        if (!this.$route.query.id) {
                            this.$router.push({
                                path: '/WorkingGroups',
                                query: {
                                    id: item.children[0].id
                                }
                            })
                        }
                    }
                })
                this.leftMenuData1 = this.leftMenuData
            })
        },
        toShangyiye() {
            if (this.pageIndex == 0) {
                this.pageIndex = this.paginatedData.length - 1
                return;
            }
            this.pageIndex--
        },
        toNext() {
            if (this.pageIndex == this.paginatedData.length - 1) {
                this.pageIndex = 0
                return;
            }
            this.pageIndex++
        },
        toShangyiye1(index) {
            if (this.datas5[index].activeKey == 0) {
                return;
            }
            this.datas5[index].activeKey--
            this.$forceUpdate()
        },
        toNext1(index) {
            const totalPages = Math.ceil(this.datas5[index].photo.length / 3);
            if (this.datas5[index].activeKey === totalPages - 1) {
                return;
            }
            this.datas5[index].activeKey++
            this.$forceUpdate()
            // 判断是否每行是三个

        },
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            this.getGongzuozu()
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .body_box {
        margin-top: 20px !important;

        .right_box {
            margin: 0 15px !important;
            margin-bottom: 20px !important;
        }

        .right_title {
            font-size: 22px !important;
            margin: 0 !important;
        }

        .right_box1 {
            margin-top: 20px !important;
            box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15) !important;
            background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%) !important;
            border: 1px solid #005083 !important;
            border-radius: 10px !important;
            display: flex;
            flex-direction: column;
            height: auto !important;

            div {
                margin: 0 !important;
                padding: 10px !important;
                font-size: 14px !important;

                img {
                    width: 16px !important;
                    height: 16px !important;
                }

                div {
                    font-size: 16px !important;
                    padding: 0 !important;
                }
            }
        }

        .right_box2 {
            margin-top: 20px !important;
            display: block !important;
            height: auto !important;
            align-items: center;
            padding-bottom: 0 !important;

            .box2_content {
                margin: 0 !important;
                background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%) !important;
                border: 1px solid #005083 !important;
                border-radius: 10px;
                display: flex;
                height: auto !important;
                flex-direction: column;
                margin-bottom: 20px !important;

                .item_box {
                    margin: 0 !important;
                    padding: 10px !important;

                    .item {
                        margin: 5px 0 !important;
                        padding: 0px !important;

                        img {
                            width: 30px !important;
                            height: 30px !important;
                        }

                        .name {
                            font-size: 18px !important;
                            padding: 0 !important;
                        }
                    }
                }

                .phonetitle {
                    margin: 0px !important;
                    padding: 10px !important;

                    .phone_img {
                        width: 16px !important;
                        height: 16px !important;
                    }

                    .phonetext {
                        font-size: 16px !important;
                        padding: 0 !important;
                    }
                }
            }

        }

        .phone_right_title {
            margin-top: 20px !important;
        }

        .right_box3 {
            margin-top: 20px !important;
            padding-bottom: 0 !important;

            .content_box1 {
                display: none !important;
            }

            .phone_content_box {
                display: flex !important;
                justify-content: space-between !important;
                flex-wrap: wrap !important;

                .item_box {
                    background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%) !important;
                    border: 1px solid #005083 !important;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px !important;
                    width: 40%;
                    padding: 10px !important;

                    .title {
                        font-size: 18px !important;
                        margin-bottom: 10px !important;
                        color: #005083 !important;
                    }

                    .text {
                        font-size: 14px !important;
                        // 超出4行隐藏
                        // overflow: hidden !important;
                        // text-overflow: ellipsis !important;
                        // display: -webkit-box !important;
                        // -webkit-line-clamp: 2 !important;
                        // -webkit-box-orient: vertical !important;
                    }
                }
            }

            .dalunbo {
                display: none !important;
            }
        }

        .right_box4 {
            .box4_item {
                padding: 0px !important;
                border-bottom: 1px solid rgba($color: #005083, $alpha: 0.2) !important;
                margin-top: 10px !important;

                .time {
                    font-size: 14px !important;
                    color: #222222 !important;
                }

                .title {
                    font-size: 18px !important;
                    color: #222222 !important;
                    margin-top: 10px !important;
                    margin-bottom: 10px !important;
                }

                .lunbo {
                    display: none !important;
                }

                .phone_lunbo {
                    display: flex !important;
                    flex-wrap: wrap !important;

                    .img_box {
                        margin: 0 !important;
                        border-radius: 10px;
                        display: flex;
                        align-items: center !important;
                        margin-bottom: 0px !important;
                        width: 100%;
                        padding: 0px !important;
                        flex-wrap: wrap !important;

                        div {
                            width: 30% !important;
                            margin-bottom: 5px !important;
                            margin-right: 10px !important;

                            img {
                                width: 100% !important;
                                height: 100% !important;
                                object-fit: cover !important;
                                border-radius: 5px !important;
                            }
                        }

                    }

                }
            }
        }
    }
}

.content {
    width: 100%;
    background: #F8F8F8;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #222222;

            img {
                margin: 0 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
                margin: 0;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                margin-left: 110px;

                .right_title {
                    font-size: 35px;
                    color: #005083;
                }

                .right_box1 {
                    box-shadow: 0px 3px 43px 0px rgba(0, 0, 0, 0.15);
                    background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
                    border: 1px solid #005083;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    margin-top: 10px;
                }

                .right_box2 {
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    padding-bottom: 27px;
                    border-bottom: 1px solid rgba($color: #005083, $alpha: 0.2);
                    margin-top: 10px;

                    .box2_content {
                        flex: 1;
                        background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
                        border: 1px solid #005083;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;

                        .item_box {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            margin-left: 20px;

                            .item {
                                display: flex;
                                align-items: center;
                                margin-bottom: 20px;

                                img {
                                    width: 80px;
                                    height: 80px;
                                    border-radius: 50%;
                                    margin-right: 16px;
                                    object-fit: cover;
                                }

                                .name {
                                    font-size: 16px;
                                    color: #333333;
                                }
                            }

                        }
                    }

                }

                .right_box3 {
                    padding-bottom: 20px;
                    border-bottom: 1px solid rgba($color: #005083, $alpha: 0.2);

                    .fade-enter-active,
                    .fade-leave-active {
                        transition: opacity .5s;
                    }

                    .fade-enter,
                    .fade-leave-to {
                        opacity: 0;
                    }

                    .dalunbo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;

                        .icon {
                            width: 41px;
                            height: 41px;
                            background-color: #fff;
                            color: #005083;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            cursor: pointer;

                            &:hover {
                                background-color: #005083;
                                color: #fff;
                            }
                        }


                    }

                    .content_box {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;

                        .item_box {
                            width: 30%;
                            min-height: 150px;
                            background: linear-gradient(180deg, #fff 0%, #9CBBCF 100%);
                            border: 1px solid #005083;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                            margin: 15px 0;
                            margin-right: 4%;

                            .title {
                                text-align: center;
                                font-size: 24px;
                                color: #005083;
                                margin-top: 15px;
                            }

                            .text {
                                text-align: center;
                                font-size: 16px;
                                color: #333333;
                                margin: 15px 20px;
                            }
                        }

                        // 第三个item_box右边距为0
                        .item_box:nth-child(3n) {
                            margin-right: 0;
                        }

                    }
                }

                .right_box4 {
                    .box4_item {
                        padding: 20px 0px;
                        border-bottom: 1px solid rgba($color: #005083, $alpha: 0.2);

                        .time {
                            font-size: 18px;
                            color: #222222;
                        }

                        .title {
                            font-size: 24px;
                            color: #222222;
                            margin-top: 15px;
                        }

                        .lunbo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 60px;

                            .icon {
                                width: 41px;
                                height: 41px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                background: #fff;
                                color: #005083;

                                &:hover {
                                    background: #005083;
                                    color: #fff;
                                }
                            }

                            .img_box {
                                flex: 1;
                                margin: 0 20px;
                                display: flex;

                                div {
                                    flex-shrink: 0;
                                    width: 31%;
                                    border-radius: 10px;
                                    margin-right: 20px;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>