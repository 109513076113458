<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="5"></banner>
            <heads></heads>
            <div class="title">Events</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Events
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    <span v-for="item in leftMenuData " v-if="item.id == activeIndex">{{ item.name }}</span>
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head left_head_active">Events</div>
                    <div class="left_item_box">
                        <div class="item" style="border: none;">
                            <div style="margin-left: 20px;">
                                Annual Meeting
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div v-for="(item, key) in leftMenuData">
                            <div class="item" style="border: none;" :class="item.id == activeIndex ? 'active' : ''"
                                v-if="!item.isChildren" @click="toComm2(item.id)">
                                <div style="margin-left: 30px;">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="item" style="flex-direction: column;border: none;" v-else>
                                <div class="zi_class" @click="toComm1">
                                    <div style="margin-left: 30px;">
                                        {{ item.name }}
                                    </div>
                                    <img src="@/assets/news/to_right.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="item" @click="toComm3">
                                <div style="margin-left: 20px;">
                                    大气环境遥感与协同分析会议
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                            <div class="item" @click="toComm5">
                                <div style="margin-left: 20px;">
                                    AERSS优博奖
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box" v-if="datas">
                    <div class="title1">{{ datas.title }}</div>
                    <div style="margin-bottom: 20px;" v-html="datas.content"></div>
                    <div>
                        <a :href="datas.layimg" target="_blank" rel="noopener noreferrer">
                            <div class="title1" style="font-size: 16px;margin-bottom: 10px;">{{
                initDatas.committees2down }}</div>
                            <div class="down_list">
                                <img style="width: 50px;" :src="datas.morenpic" alt="">
                            </div>
                        </a>
                    </div>
                    <div class="title1" style="font-size: 16px;margin-top: 20px;">{{ initDatas.committees2title }}</div>
                    <div class="img_box">
                        <div v-for="item in datas.photo">
                            <img :src="item.layimg" alt="">
                            <div style="font-size: 16px;text-align: center;font-family: 'CustomFont9' !important;"> {{
                                item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
import swiper from '@/components/swiper.vue'
export default {
    components: {
        heads,
        foots,
        banner,
        swiper
    },
    data() {
        return {
            leftMenuData: [
                {
                    name: 'Annual Meeting 2024',
                    isChildren: true,
                    children: [
                        {
                            name: 'Topic',
                            md: 'Topic'
                        },
                        {
                            name: 'Organizers',
                            md: 'Organizers',
                        },
                        {
                            name: 'Schedule',
                            md: 'ImportantDates'
                        },
                        {
                            name: 'Venue Information',
                            md: 'VenueInformation'
                        },
                        {
                            name: 'Abstract Submission',
                            md: 'AbstractSubmission'
                        },
                        {
                            name: 'Advisory Committee',
                            md: 'AbstractCommittee'
                        },
                        {
                            name: 'Organising Committees',
                            md: 'OCommittees'
                        },
                        {
                            name: 'Best presentation award',
                            md: 'Bestpresentationaward'
                        },
                        {
                            name: 'Registration',
                            md: 'Foots'
                        },
                        {
                            name: 'Student & Young Scholars Travel Grants',
                            md: 'StudentYoungScholarsTravelGrants'
                        },
                        {
                            name: 'Contacts and Quick Links',
                            md: 'ContactsandQuickLinks'
                        },
                        {
                            name: 'Sponsors and Exhibitors',
                            md: 'SponsorsandExhibitors'
                        },
                        // {
                        //     name: 'Sponsorship Opportunity',
                        //     md: 'SponsorshipOpportunity'
                        // },
                    ],
                },
            ],
            datas: null,
            activeIndex: 0,
            initDatas: null
        }
    },
    mounted() {
        this.getdata()
    },
    methods: {
        getdata() {
            this.activeIndex = this.$route.query.id || 0;
            request({
                url: 'apimeeting',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    belong: this.activeIndex
                }
            }).then(res => {
                let arr = []
                let data = {}
                data.t = res.data.t
                data.morenpic = res.data.morenpic
                data.down = res.data.down
                res.data.meeting.forEach(item => {
                    arr.push({
                        name: item.title,
                        id: item.id
                    })
                    if (item.id == this.$route.query.id) {
                        data.content = item.content
                        data.layimg = item.layimg
                        data.title = item.title
                    }
                })
                this.leftMenuData = [this.leftMenuData[0], ...arr]
                data.photo = res.data.meeting_detail
                this.datas = data
            })
            request({
                url: 'init_data',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(res => {
                console.log(res.data)
                this.initDatas = res.data
            })
        },
        toComm1() {
            this.$router.push({
                path: '/AnnualMeeting2024',
            })
        },
        toComm2(id) {
            if (this.$route.path == '/Committees2') {
                this.$router.replace({
                    path: '/Committees2',
                    query: {
                        id: id
                    }
                });
                this.getdata()
            } else {
                this.$router.push({
                    path: '/Committees2',
                    query: {
                        id: id
                    }
                })
            }
        },
        toComm3() {
            this.$router.push({
                path: '/Committees3',
            })
        },
        toComm5() {
            this.$router.push({
                path: '/Committees5',
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    div {
        word-break: break-all;
    }

    .body_box {
        margin-top: 0 !important;
    }


}

div {
    font-weight: 100 !important;
}

.content {
    width: 100%;
    background: #fff;

    a {
        // 清除a标签的下划线
        text-decoration: none;
    }

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 60px;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 30px;
            display: flex;
            align-items: flex-start;

            .left_box {
                border-radius: 0px 0px 20px 20px;
                box-shadow: 0px 0px 10px #E5E5E5;

                .left_head {
                    padding: 0 20px;
                    width: 317px;
                    height: 115px;
                    font-size: 25px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #E5E5E5;
                    cursor: pointer;
                    text-align: center;
                }

                .left_head_active {
                    background: #005083;
                    border-radius: 20px 20px 0px 0px;
                    color: #FFFFFF;
                }

                .left_item_box {
                    .item {
                        width: 100%;
                        min-height: 60px;
                        display: flex;
                        background-size: 100% 100%;
                        align-items: center;
                        justify-content: space-between;
                        color: #333;
                        cursor: pointer;
                        border-top: 1px solid #eee;

                        img {
                            width: 16px;
                            margin-right: 20px;
                        }

                        .zi_class {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                            height: 60px;
                        }
                    }

                    .active {
                        background-image: url("@/assets/news/left_bg.png");
                        color: #005083;
                    }
                }

            }

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;
                margin-left: 80px;

                ::v-deep img {
                    max-width: 100%;

                    &:hover {
                        transform: scale(1);
                    }
                }

                .title1 {
                    font-size: 25px;
                    color: #005083;
                    margin-bottom: 20px;
                    font-family: 'CustomFont2' !important;
                    font-weight: 700 !important;
                }

                .down_list {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;

                    img {
                        cursor: pointer;
                    }
                }

                .img_box {
                    display: flex;
                    flex-wrap: wrap;

                    img {
                        width: 320px;
                        height: 180px;
                        margin-right: 20px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}
</style>