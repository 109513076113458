<!-- 注册页 -->
<template>
    <div class="content">
        <div class="box">
            <banner :types="5"></banner>
            <heads></heads>
            <div class="title">Events</div>
        </div>
        <div class="box1">
            <div class="icon_box">
                <img class="home" @click="toHome" src="@/assets/news/home_icon.png" alt="">
                <img class="right" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    Events
                </div>
                <img class="right" style="margin-left: 10px;" src="@/assets/news/right_down.png" alt="">
                <div style="cursor: pointer;">
                    {{ leftMenuData[activeIndex].name }}
                </div>
            </div>
            <div class="body_box">
                <div class="left_box">
                    <div class="left_head left_head_active">Events</div>
                    <div class="left_item_box">
                        <div class="item" style="border: none;">
                            <div style="margin-left: 20px;">
                                Annual Meeting
                            </div>
                            <img src="@/assets/news/to_right.png" alt="">
                        </div>
                        <div v-for="(item, key) in leftMenuData">
                            <div @click="tocomm2(item)" class="item" style="border: none;"
                                :class="key == activeIndex ? 'active' : ''" v-if="!item.isChildren">
                                <div style="margin-left: 30px;">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="item" style="flex-direction: column;border: none;"
                                :class="key == activeIndex ? 'active' : ''" v-else>
                                <div class="zi_class">
                                    <div style="margin-left: 30px;">
                                        {{ item.name }}
                                    </div>
                                    <img src="@/assets/news/to_right.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="item" @click="toComm3">
                                <div style="margin-left: 20px;">
                                    大气环境遥感与协同分析会议
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                            <div class="item" @click="toComm5">
                                <div style="margin-left: 20px;">
                                    AERSS优博奖
                                </div>
                                <img src="@/assets/news/to_right.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box">
                    <div v-for="item in datas" style="margin-bottom: 20px;">
                        <div class="text1">{{ item.title }}</div>
                        <div style="margin-top: 10px;">{{ item.member }}</div>
                        <div class="text2 noneFont">{{ item.begin_time }} - {{ item.end_time }}</div>
                        <!-- <div class="text3" v-if="item.member">{{ item.member }}</div> -->
                        <div class="text3" style="font-size: 16px;color: #666;margin-top: 10px;">{{ item.remark }}</div>
                        <div class="zhankai" v-if="item.show" @click="item.show = !item.show">
                            Hide abstract
                        </div>
                        <div class="zhankai1" v-else @click="item.show = !item.show">
                            Show abstract
                        </div>
                        <div v-html="item.content" class="fuwenben" :class="item.show ? 'zhankaifuwenben' : ''"></div>
                    </div>
                </div>
            </div>
        </div>
        <foots></foots>
    </div>
</template>

<script>
import heads from '@/components/heads.vue'
import foots from '@/components/foots.vue'
import leftMenu from '@/components/leftMenu.vue'
import request from '@/utils/request'
import banner from '@/components/banner.vue';
export default {
    components: {
        heads,
        foots,
        banner,
        leftMenu
    },
    data() {
        return {
            leftMenuData: [
                {
                    name: 'Annual Meeting 2024',
                    isChildren: true,
                },
            ],
            datas: null,
            activeIndex: 0,

        }
    },
    mounted() {
        request({
            url: 'meeting_detail',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                id: 459
            }
        }).then(res => {
            this.datas = res.data
            this.datas.forEach(item => {
                this.$set(item, 'show', false)
            })
        })
        request({
            url: 'apimeeting',
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => {
            let arr = []
            res.data.meeting.forEach(item => {
                arr.push({
                    name: item.title,
                    id: item.id
                })
            })
            this.leftMenuData = [this.leftMenuData[0], ...arr]
        })
    },
    methods: {
        toHome() {
            this.$router.push({
                path: '/'
            })
        },
        tofuPath() {
            this.$router.push({
                path: '/AnnualMeeting2024'
            })
        },
        toComm3() {
            this.$router.push({
                path: '/Committees3',
            })
        },
        toComm5() {
            this.$router.push({
                path: '/Committees5',
            })
        },
        tocomm2(path) {
            this.$router.push({
                path: '/committees2',
                query: {
                    id: path.id
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
    .body_box {
        margin: 0 !important;
    }

    .right_box {
        padding: 0 !important;
        margin: 10px !important;

        .text1 {
            font-size: 20px !important;
        }

        .text2 {
            font-size: 12px !important;
        }

        .text3 {
            font-size: 18px !important;
            padding-bottom: 10px !important;
        }

        .fuwenben {
            margin-top: 10px !important;

            ::v-deep img {
                max-width: 100%;

                &:hover {
                    transform: scale(1);
                }
            }
        }

    }
}

.left_box {
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 0px 10px #E5E5E5;
    margin-right: 40px;
    .left_head {
        padding: 0 20px;
        width: 317px;
        height: 115px;
        font-size: 25px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        text-align: center;
    }

    .left_head_active {
        background: #005083;
        border-radius: 20px 20px 0px 0px;
        color: #FFFFFF;
    }

    .left_item_box {
        .item {
            width: 100%;
            min-height: 60px;
            display: flex;
            background-size: 100% 100%;
            align-items: center;
            justify-content: space-between;
            color: #333;
            cursor: pointer;
            border-top: 1px solid #eee;

            img {
                width: 16px;
                margin-right: 20px;
            }

            .zi_class {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                height: 60px;
            }
        }

        .active {
            background-image: url("@/assets/news/left_bg.png");
            color: #005083;
        }
    }

}

.content {
    width: 100%;
    background: #fff;

    .box {
        position: relative;
        overflow: hidden;

        .banner {
            width: 100%;
        }

        .title {
            font-size: 112px;
            font-weight: bold;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .box1 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;

        .icon_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #222222;

            img {
                margin-right: 10px;
                cursor: pointer;
            }

            .home {
                width: 24px;
            }

            .right {
                height: 15px;
            }
        }

        .body_box {
            margin-top: 80px;
            display: flex;
            align-items: flex-start;

            .right_box {
                flex: 1;
                padding: 20px;
                padding-top: 0;

                .text1 {
                    font-size: 35px;
                    font-weight: bold;
                    color: #005083;
                }

                .text2 {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 19px;
                }

                .text3 {
                    margin-top: 13px;
                    font-size: 35px;
                    font-weight: bold;
                    color: #333333;
                    padding-bottom: 23px;
                }

                .fuwenben {
                    margin-top: 20px;
                    border-bottom: 2px solid #E3E3E3;
                    padding-bottom: 10px;
                    height: 0px;
                    overflow: hidden;
                }

                .zhankaifuwenben {
                    height: auto !important;
                }

                .zhankai {
                    width: 113px;
                    height: 29px;
                    background-image: url('@/assets/committees/zhankai.png');
                    background-size: 100% 100%;
                    color: #fff;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    line-height: 20px;
                    cursor: pointer;
                }

                .zhankai1 {
                    width: 113px;
                    height: 23px;
                    background: #aaa;
                    border-radius: 12px;
                    font-size: 14px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                .neirong {
                    padding-bottom: 34px;
                    border-bottom: 1px solid #E3E3E3;

                    .neirong_title {
                        font-size: 24px;
                        font-weight: 500;
                        color: #000000;
                        margin-top: 19px;
                    }

                    .neirong_text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 29px;
                    }

                    .tip_box {
                        margin-top: 26px;

                        div {
                            padding: 7px;
                            background: rgba($color: #222, $alpha: .2);
                            border-radius: 12px;
                            color: #fff;
                            font-size: 14px;
                            display: inline-block;
                        }
                    }

                    .neirong_text2 {
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 19px;
                    }
                }

            }
        }
    }
}
</style>